/**
 * adminMapFile.js
 * Naoaki Suganuma
 * 2017/11/21
 * ver. 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  mapFiles: null,
  selection: null,
  processing: false,
  progressMessage: "",
  progress: 0,
};

/**
 * adminMapFile reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminMapFile(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_MAPFILE:
      return Object.assign({}, state, {
        mapFiles: null,
      });
    case types.RECEIVE_GET_ADMIN_MAPFILE:
      return Object.assign({}, state, {
        mapFiles: action.mapFiles,
      });
    case types.CREATING_ADMIN_MAPFILE:
      return Object.assign({}, state, {
        processing: true,
        progressMessage: "ファイルをアップロードしています...",
        progress: 0,
      });
    case types.SET_UPLOAD_ADMIN_MAPFILE_PROGRESS:
      return Object.assign({}, state, {
        progressMessage: action.filename + "をアップロードしています...",
        progress: 0 + 20 * (action.progress / 100),
      });
    case types.SET_UPLOAD_ADMIN_MAPFILE_COMPLETE:
      return Object.assign({}, state, {
        progressMessage: "図面の変換・登録処理を起動しています...",
        progress: 20,
      });
    case types.SET_PROCESS_ADMIN_MAPFILE_PROGRESS:
      return Object.assign({}, state, {
        progressMessage: "図面の変換・登録を行っています...",
        progress: 20 + ((100 - 20) * action.progress / 100),
      });
    case types.SET_PROCESS_ADMIN_MAPFILE_COMPLETE:
      return Object.assign({}, state, {
        processing: false,
        progressMessage: "",
        progress: 100,
      });
    case types.RECEIVE_CREATE_ADMIN_MAPFILE:
    case types.ERR_API:
    case types.ERR_FINISH:
      return Object.assign({}, state, {
        processing: false,
        progressMessage: "",
        progress: 0,
      });
    case types.RECEIVE_DELETE_ADMIN_MAPFILE: {
      var mapFiles = state.mapFiles.filter(function (mf) { return mf.id !== action.mfid; });
      return Object.assign({}, state, {
        mapFiles: mapFiles,
        selection: null
      });
    }
    case types.UPLOADING_ADMIN_MAPFILE: {
      return Object.assign({}, state, {
        uploadingFiles: action.uploadingFiles,
        isUploading: true,
        uploadingProgress: 0
      });
    }
    case types.SET_SELECT_ADMIN_MAPFILE:
      return Object.assign({}, state, {
        selection: action.mapFile,
      });
    case types.CLEAN_ADMIN_MAPFILE:
      return initialState;
    default:
      return state;
  }
}
