/**
 * ApiEndpoint.js
 * Naoaki Suganuma
 * 2017/10/21
 * ver. 1.0.0
 */

//------------------------------------
// API Endpoint URL
//------------------------------------
const API_ENDPOINT_PRODUCTION = 'https://api.glyphshot.com/v1';
const API_ENDPOINT_STAGING = 'https://gsc-webapiapp-staging.azurewebsites.net/v1';
const API_ENDPOINT_DEV = 'https://gsc-webapiapp-dev.azurewebsites.net/v1';
const API_ENDPOINT_SYSCLI = 'https://gsc-webapiapp-syscli.azurewebsites.net/v1';

///--------------------------------------------
/// API Endpointを取得する
///--------------------------------------------
/**
 * API Endpointを取得する
 * 現在取得中の場合は、Promiseを返す。
 * 取得中でなければデバイス取得APIを呼び出す。
 * @param {number} projectId プロジェクトID
 */
export function getApiEndpoint() {
  if (window.SLOT === "syscli") {
    return API_ENDPOINT_SYSCLI;
  } else if (window.SLOT === "dev") {
    return API_ENDPOINT_DEV;
  } else if (window.SLOT === "staging") {
      return API_ENDPOINT_STAGING;
  } else {
    return API_ENDPOINT_PRODUCTION;
  }
}
