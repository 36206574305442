/**
 * withTheme.js
 * Naoaki Suganuma
 * 2018/01/08
 * ver. 1.0.0
 */

import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';

/**
 * デフォルトテーマ
 */
const theme = createMuiTheme({
  palette: {
    primary: blue,
    type: 'light',
  },
  typography: {
    useNextVariants: true,
    fontFamily: "Meiryo, sans-serif"
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 600,
        color: grey[700]
      },
    },
    MuiIconButton: {
      root: {
        color: grey[700]
      },
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):before': {
          backgroundColor: grey[300],
          height: 1,
        },
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '0.95em',
      }
    },
    MuiTableRow: {
      root: {
        "&$selected": {
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.15)"
          },
          backgroundColor: "rgba(0, 0, 0, 0.15)"
        }
      }
    } 
  }
});

/**
 * コンポーネントにテーマを適用する。
 */
function withTheme(Component) {
  function withTheme(props) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }
  return withTheme;
}

/**
 * テーマ結合functionのエクスポート
 */
export default withTheme;
