/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: photo.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  downloads: null,
  criteria: null,
  count: 0,
  files: [],
  hasMoreFiles: false,
  progress: 0,
  processing: false,
  enableKuraemon: false,
};

/**
 * report reducer
 * @param {*} state 状態
 * @param {*} action アクション
 */
export default function photo(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_PHOTO_COUNT:
      return Object.assign({}, state, {
        files: [],
        hasMoreFiles: false
      });
    case types.RECEIVE_GET_PHOTO_COUNT:
      return Object.assign({}, state, {
        criteria: action.criteria,
        count: action.count,
        hasMoreFiles: action.count > 0
      });
    case types.RECEIVE_GET_PHOTO: {
      let files = state.files;
      action.files.forEach((f) => {
        files.push(f);
      });
      return Object.assign({}, state, {
        files: files,
        hasMoreFiles: files.length < state.count
      });
    }
    case types.GETTING_PHOTO_DOWNLOAD:
      return Object.assign({}, state, {
        downloads: null
      });
    case types.RECEIVE_GET_PHOTO_DOWNLOAD:
      return Object.assign({}, state, {
        downloads: action.downloads
      });
    case types.CREATING_PHOTO_DOWNLOAD: {
      return Object.assign({}, state, {
        processing: true,
        progress: 0
      });
    }
    case types.RECEIVE_CREATE_PHOTO_DOWNLOAD: {
      return Object.assign({}, state, {
        progress: 100,
        processing: false
      });
    }
    case types.SET_CREATE_PHOTO_DOWNLOAD_PROGRESS: {
      return Object.assign({}, state, {
        progress: action.progress
      });
    }
    case types.RECEIVE_DELETE_PHOTO_DOWNLOAD: {
      var downloads = state.downloads.filter(function (d) { return d.id !== action.downloadId; });
      return Object.assign({}, state, {
        downloads: downloads
      });
    }
    case types.SET_ENABLE_KURAEMON:
      return Object.assign({}, state, {
        enableKuraemon: action.enableKuraemon
      });
    case types.RECEIVE_LOGOUT:
    case types.INIT_AUTH:
    case types.CLEAN_PHOTO:
      return initialState;
    default:
      return state;
  }
}
