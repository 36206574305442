/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: progress.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';
import { PROGRESS_INSPECTION_ID } from '../constants/AppConstants';

/**
 * 初期状態
 */
const initialState = {
  treeId: null,
  nodeId: null,
  mapId: null,
  type: PROGRESS_INSPECTION_ID,
  icons: [],
  workgroups: null,
  works: null,
  organizations: null
};

/**
 * progress reducer
 * @param {*} state 状態
 * @param {*} action アクション
 */
export default function progress(state = initialState, action) {
  switch (action.type) {
    case types.SET_PROJECT:
      return Object.assign({}, state, {
        treeId: null,
        nodeId: null,
        mapId: null,
        type: PROGRESS_INSPECTION_ID,
        icons: [],
        workgroups: null,
        works: null,
        organizations: null
      });
    case types.RECEIVE_LOGOUT:
    case types.INIT_AUTH:
    case types.CLEAN_PROGRESS:
      return initialState;
    case types.SET_PROGRESS_TREE:
      return Object.assign({}, state, {
        treeId: action.treeId,
        nodeId: null,
        mapId: null
      });
    case types.SET_PROGRESS_NODE: {
      return Object.assign({}, state, {
          nodeId: action.nodeId,
          mapId: null
        });
      }
    case types.SET_PROGRESS_MAP: {
      return Object.assign({}, state, {
        mapId: action.mapId
      });
    }
    case types.SET_PROGRESS_TYPE: {
      return Object.assign({}, state, {
        type: action.progressType
      });
    }
    case types.GETTING_PROGRESS_ICON: {
      return Object.assign({}, state, {
        icons: []
      });
    }
    case types.RECEIVE_GET_PROGRESS_ICON: {
      return Object.assign({}, state, {
        icons: action.icons
      });
    }
    case types.RECEIVE_UPDATE_ICON: {
      if (state.icons.some(ic => ic.id === action.icon.id)) {
        let icons = Object.assign([], state.icons);
        icons.splice(icons.map(function (icon) { return icon.id; }).indexOf(action.icon.id), 1, action.icon);
        return Object.assign({}, state, {
          icons: icons
        });
      } else {
        return state;
      }
    }
    case types.CLEAR_PROGRESS_ICON: {
      return Object.assign({}, state, {
        icons: []
      });
    }

    case types.GETTING_PROGRESS_WORKGROUP: {
      return Object.assign({}, state, {
        workgroups: null
      });
    }
    case types.RECEIVE_GET_PROGRESS_WORKGROUP: {
      return Object.assign({}, state, {
        workgroups: action.workgroups
      });
    }
    case types.GETTING_PROGRESS_WORK: {
      return Object.assign({}, state, {
        works: null
      });
    }
    case types.RECEIVE_GET_PROGRESS_WORK: {
      return Object.assign({}, state, {
        works: action.works
      });
    }
    case types.GETTING_PROGRESS_ORGANIZATION: {
      return Object.assign({}, state, {
        organizations: null
      });
    }
    case types.RECEIVE_GET_PROGRESS_ORGANIZATION: {
      return Object.assign({}, state, {
        organizations: action.organizations
      });
    }
    default:
      return state;
  }
}
