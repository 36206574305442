/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: ValidationRules.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import moment from 'moment';

/**
 * 空でない判定
 */
const isExisty = function (value) {
  return value !== null && value !== undefined;
};

/**
 * 空判定
 */
const isEmpty = function (value) {
  return value === '' || value === undefined || value == null || value.length === 0;
};

/**
 * 前後の空白を除去して空判定
 */
const isEmptyTrimed = function (value) {
  if (typeof value === 'string') {
    return value.trim() === '';
  }
  return true;
};

/**
 * Validationルール
 */
const validations = {
  /**
   * 正規表現による一致
   */
  matchRegexp: (value, regexp) => {
    const validationRegexp = (regexp instanceof RegExp ? regexp : (new RegExp(regexp)));
    return (!isExisty(value) || isEmpty(value) || validationRegexp.test(value));
  },

  /**
   * emailアドレス
   */
  // eslint-disable-next-line
  isEmail: value => validations.matchRegexp(value, /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i),

  /**
   * URL
   */
  // eslint-disable-next-line
  isURL: value => validations.matchRegexp(value, /^(http|https):\/\/.+$/i),
  
  /**
   * 電話番号
   */
  // eslint-disable-next-line
  isPhoneNumber: value => validations.matchRegexp(value, /^[0-9]{2,4}-[0-9]{2,4}-[0-9]{3,4}$/i),

  /**
   * カタカナ・全角空白・半角空白
   */
  // eslint-disable-next-line
  isKana: value => validations.matchRegexp(value, /^[ァ-ヶー\s0-9０-９]*$/i),

  /**
   * 空判定
   */
  isEmpty: value => isEmpty(value),

  /**
   * 必須判定
   */
  required: value => !isEmpty(value),

  /**
   * 前後の空白を除去して空判定
   */
  trim: value => !isEmptyTrimed(value),

  /**
   * 整数値判定
   */
  isNumber: value => validations.matchRegexp(value, /^-?[0-9]\d*(\d+)?$/i),

  /**
   * 正数判定
   */
  isPositiveNumber: (value) => {
    if (isExisty(value)) {
      return validations.isNumber(value)  && value >= 0;
    }
    return false;
  },

  /**
   * 小数値判定
   */
  isFloat: value => validations.matchRegexp(value, /^-?(?:[1-9]\d*|0)(?:\.\d+)?$/i),

  /**
   * 正数判定
   */
  isPositiveFloat: (value) => {
    if (isExisty(value)) {
      return (validations.isNumber(value) || validations.isFloat(value)) && value >= 0;
    }
    return true;
  },

  /**
   * 最大値判定
   */
  maxNumber: (value, max) => !isExisty(value) || isEmpty(value) || parseInt(value, 10) <= parseInt(max, 10),

  /**
   * 最小値判定
   */
  minNumber: (value, min) => !isExisty(value) || isEmpty(value) || parseInt(value, 10) >= parseInt(min, 10),

  /**
   * 文字列判定
   */
  isString: value => !isEmpty(value) || typeof value === 'string' || value instanceof String,

  /**
   * 最小文字数
   */
  minStringLength: (value, length) => validations.isString(value) && (value.length === 0 || value.length >= parseInt(length, 10)),

  /**
   * 最大文字数
   */
  maxStringLength: (value, length) => validations.isString(value) && (value.length === 0 || value.length <= parseInt(length, 10)),

  /**
   * 最小日判定
   */
  minDate: (value, min) => !isExisty(value) || isEmpty(value) || moment(value, "YYYY-MM-DD").isAfter(min),

  /**
   * 最小日判定（等しくても良い）
   */
  minOrEqualDate: (value, min) => !isExisty(value) || isEmpty(value) || !moment(value, "YYYY-MM-DD").isBefore(min),

  /**
   * 最大日判定
   */
  maxDate: (value, max) => !isExisty(value) || isEmpty(value) || moment(value, "YYYY-MM-DD").isBefore(max),
  
  /**
   * 最大日判定（等しくても良い）
   */
  maxOrEqualDate: (value, max) => !isExisty(value) || isEmpty(value) || !moment(value, "YYYY-MM-DD").isAfter(max),
};

export default validations;