/**
 * errorAction.js
 * Kazuo Yoshida
 * 2017/09/12
 * ver. 1.0.0
 */
import * as types from '../constants/ActionTypes';

/**
 * errアクション
 */
export function errByResponse(res) {
  let statusCode = null;
  let message = null;
  if (res) {
    statusCode = res.statusCode;
    message = res.statusText + "[" + res.error.message + "]";
  }
  return processError(statusCode, message);
}

/**
 * errアクション
 */
export function err(err, dic) {
  let statusCode = null;
  let message = null;
  if (err) {
    statusCode = err.response.statusCode ? err.response.statusCode : null;
    if (typeof (err) === "string") {
      message = err;
    } else if (err.body) {
      message = err.body.Message;
    }
  }
  return processError(statusCode, message, dic);
}

function processError(statusCode, message, dic) {
  let appMessage = null;
  if (dic) {
    appMessage = dic[statusCode];
  }
  switch (statusCode) {
    case 400:
      return {
        type: types.ERR_API,
        statusCode: 400,
        message: concatMessage("[400]", appMessage, "不正なリクエストがサーバに送信されました。", message)
      };
    case 401:
      return {
        type: types.ERR_API,
        statusCode: 401,
        message: concatMessage("[401]", appMessage, "認証に失敗しました。前回ログインの有効期限切れの可能性があります。再度ログインしてください。", message)
      };
    case 403:
      return {
        type: types.ERR_API,
        statusCode: 403,
        message: concatMessage("[403]", appMessage, "アクセス権限がありません。禁止されているデータにアクセスしました。", message)
      };
    case 404:
      return {
        type: types.ERR_API,
        statusCode: 404,
        message: concatMessage("[404]", appMessage, "処理の対象となるデータが見つかりませんでした。", message)
      };
    case 405:
      return {
        type: types.ERR_API,
        statusCode: 405,
        message: concatMessage("[405]", appMessage, "許可されていないメソッドを使用しました。", message)
      };
    case 406:
      return {
        type: types.ERR_API,
        statusCode: 406,
        message: concatMessage("[406]", appMessage, "受理できない情報が含まれています。", message)
      };
    case 407:
      return {
        type: types.ERR_API,
        statusCode: 407,
        message: concatMessage("[407]", appMessage, "プロキシ認証が必要です。", message)
      };
    case 408:
      return {
        type: types.ERR_API,
        statusCode: 408,
        message: concatMessage("[408]", appMessage, "リクエストがタイムアウトしました。", message)
      };
    case 413:
      return {
        type: types.ERR_API,
        statusCode: 413,
        message: concatMessage("[413]", appMessage, "リクエストのサイズが大きすぎます。", message)
      };
    case 415:
      return {
        type: types.ERR_API,
        statusCode: 415,
        message: concatMessage("[415]", appMessage, "サポートしていないメディアタイプが指定されました。", message)
      };
    case 423:
      return {
        type: types.ERR_API,
        statusCode: 423,
        message: concatMessage("[423]", appMessage, "ロックされています。", message)
      };
    case 424:
      return {
        type: types.ERR_API,
        statusCode: 424,
        message: concatMessage("[424]", appMessage, "他のデータから参照されているため削除できません。", message)
      };
    case 500:
      return {
        type: types.ERR_API,
        statusCode: 500,
        message: concatMessage("[500]", appMessage, "サーバ内部でエラーが発生しました。", message)
      };
    case 501:
      return {
        type: types.ERR_API,
        statusCode: 501,
        message: concatMessage("[501]", appMessage, "実装されていない機能を使用しました。", message)
      };
    case 502:
      return {
        type: types.ERR_API,
        statusCode: 502,
        message: concatMessage("[502]", appMessage, "不正なゲートウェイを使用しました。", message)
      };
    case 503:
      return {
        type: types.ERR_API,
        statusCode: 503,
        message: concatMessage("[503]", appMessage, "サービスは利用できない状態です。", message)
      };
    case 504:
      return {
        type: types.ERR_API,
        statusCode: 504,
        message: concatMessage("[504]", appMessage, "ゲートウェイがタイムアウトになりました。", message)
      };
    case 505:
      return {
        type: types.ERR_API,
        statusCode: 505,
        message: concatMessage("[505]", appMessage, "サポートしていないHTTPバージョンのリクエストが送信されました。", message)
      };
    default:
      return {
        type: types.ERR_API,
        statusCode: statusCode,
        message: concatMessage("[" + statusCode + "]", appMessage, "その他のエラーが起きました。", message)
      };
  }
}

/**
 * err処理終了アクション
 */
export function errFinish() {
  return {
    type: types.ERR_FINISH
  };
}

/**
 * 2つのメッセージを連結する。
 * @param {*} mainMessage メインメッセージ
 * @param {*} subMessage サブメッセージ
 */
function concatMessage(prefix, appMessage, mainMessage, subMessage) {
  let message = prefix;
  if (appMessage) {
    message = message + " " + appMessage;
  }
  if (mainMessage) {
    if (appMessage) {
      message = message + " (" + mainMessage + ")";
    } else {
      message = message + " " + mainMessage;
    }
  }
  if (subMessage) {
    message = message + " [" + subMessage + "]";
  }
  return message;
}