/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: App.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import 'moment/locale/ja'
import moment from 'moment';
import queryString from 'querystring';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '../withTheme';
import Home from '../app/Home';
import Login from '../account/Login';
import SamlSSO from '../account/SamlSSO';
import Signup from '../account/Signup';
import SignupConfirm from '../account/SignupConfirm';
import Password from '../account/Password';
import SetPassword from '../account/SetPassword';
import Language from '../account/Language';
import Profile from '../account/Profile';
import News from '../account/News';
import About from '../help/About';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Loader from '../common/Loader';
import NoMatch from '../common/NoMatch';
import NoPrivilege from '../common/NoPrivilege';
import AsyncContainer from '../common/AsyncContainer';
import { 
  setFrom, 
  initAuth, 
  authenticated,
  loginByTokenIfNeeded
} from '../actions/auth';
import { getSystemStateIfNeeded } from '../actions/systemState';
import { errFinish } from '../actions/errorAction';
import { RequireLoginRoute, RequireNoLoginRoute, RequireSystemRoute, RootRoute } from './RestrictedRoute';

/**
 * dynamic loading
 */
const PublicHome = AsyncContainer(() => import('../public/PublicHome').then(module => module.default));
const Help = AsyncContainer(() => import('../help/Help').then(module => module.default));
const ProjectHome = AsyncContainer(() => import('../projects/ProjectHome').then(module => module.default));
const SystemHome = AsyncContainer(() => import('../system/SystemHome').then(module => module.default));

/**
 * スタイル 
 */
const styles = theme => ({
  app: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%"
  },
  loader: {
    zIndex: 100
  },
  main: {
    flex: "1 1 auto",
    display: "flex",
    position: "relative",
    overflow: "hidden",
    width: "100%",
    height: "100%",
    zIndex: 0
  },
  overlayLeft: {
    position: 'fixed',
    width: '18%',
    height: '100%',
    zIndex: 9,
    top: '10%',
  },
  overlayRight: {
    position: 'fixed',
    width: '12%',
    height: '100%',
    zIndex: 9,
    right: 12,
    top: '10%',
  },
  overlayHead: {
    position: 'fixed',
    width: '100%',
    height: '16%',
    zIndex: 9,
    top: '10%',
  }
});

/// サーバの状態をポーリングする時間間隔
const SERVER_STATE_POLLING_INTERVAL = 60 * 1000;

/**
 * Appコンポーネント
 */
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasOverlay: false
    };
    this.timer = null;
  }

  /**
   * コンポーネントがマウントする時に呼び出される。
   * 指定されたURLを記録し、local storageのトークンを検証する。
   */
  componentDidMount() {
    if (this.props.router.location.pathname) {
      if (this.props.router.location.pathname === '/login') {
        this.props.setFrom(null);
      } else {
        var pathname = this.props.router.location.pathname;
        if (!pathname.startsWith("/public") &&
            !pathname.startsWith("/help") &&
            !pathname.startsWith("/about") &&
            !pathname.startsWith("/signup") &&
            !pathname.startsWith("/confirm") &&
            !pathname.startsWith("/password") &&
            !pathname.startsWith("/setpassword") &&
            !pathname.startsWith("/language")) {
          this.props.setFrom(pathname);
        }
      }
    }
    this.checkToken();
    this.timer = setInterval(() => {
      this.props.getSystemStateIfNeeded();
    }, SERVER_STATE_POLLING_INTERVAL);
  }

  /**
   * コンポーネントがアンマウントする時に呼び出される。
   */
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  /**
   * Appコンポーネントの状態が更新される時に呼び出される。
   * local storageのトークンを検証する。
   * @param {*} nextProps 次の状態
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.checkToken();
    if(nextProps.map.overlay) {
      this.setState({hasOverlay: true})
    } else {
      this.setState({hasOverlay: false})
    }
  }

  /**
   * Appコンポーネントの状態が更新される時に呼び出される。
   * エラーが起こった場合はアラートを表示し、認証エラーの場合はログアウトした後にログイン画面に遷移する。
   * @param {*} nextProps 次の状態
   */
  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.error.error) {
      alert(nextProps.error.message)
      this.props.errFinish();
      if (nextProps.error.needsLogin) {
        this.props.initAuth();
      }
    } else if (nextProps.auth.needsLogin) {
      this.props.initAuth();
    }
  }

  /**
   * JWTトークンを検証する。
   */
  checkToken() {
    if (this.props.auth.isLoggedIn) {
      let token = this.props.auth.token;
      if (token) {
        let ary = token.trim().split(".");
        let json = JSON.parse(window.atob(ary[1]));
        let now = new Date();
        if (Math.floor(now.getTime() / 1000) > json.exp) {
          this.props.initAuth();
        }
      } else {
        this.props.initAuth();
      }
    } else {
      let token = window.localStorage.getItem("token");
      var valid = false;
      if (token) {
        let ary = token.trim().split(".");
        let json = JSON.parse(window.atob(ary[1]));
        let now = new Date();
        if (Math.floor(now.getTime() / 1000) <= json.exp) {
          this.props.authenticated(token);
          valid = true;
        }
      }
      if (!valid) {
        var param = queryString.parse(this.props.location.search);
        var t = param["sso"] == null ? param["?sso"] : param["sso"];
        if (t != null) {
          var now = moment();
          var today2am = moment().startOf('day').add(2, "hours");
          var date = today2am.isAfter(now) ? today2am : today2am.add(1, "days");
          this.props.loginByTokenIfNeeded(t, date);
        }
      }
    }
  }

  /**
   * Appコンポーネントのレンダリング
   */
  render() {
    moment.locale('ja');
    return (
      <div className={this.props.classes.app}>
        <Loader isActive={this.props.app.isConnectiongServer} className={this.props.classes.loader} />
        <Header/>
        <div className={this.state.hasOverlay === true ? this.props.classes.overlayHead : ''}></div>
        <div className={this.state.hasOverlay === true ? this.props.classes.overlayLeft : ''}></div>
        <div className={this.state.hasOverlay === true ? this.props.classes.overlayRight : ''}></div>
        <div className={this.props.classes.main}>
          <Switch>
            <Route path="/public" component={PublicHome} />
            <Route path="/help" component={Help} />
            <Route path="/about" component={About} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/confirm" component={SignupConfirm} />
            <Route exact path="/password" component={Password} />
            <Route exact path="/setpassword" component={SetPassword} />
            <Route exact path="/language" component={Language} />
            <Route exact path="/acs" component={SamlSSO} />
            <Route exact path="/sso/saml" component={SamlSSO} />
            <RequireNoLoginRoute exact path="/login" auth={this.props.auth} component={Login} />
            <RequireLoginRoute exact path="/profile" auth={this.props.auth} component={Profile} />
            <RequireLoginRoute exact path="/news" auth={this.props.auth} component={News} />
            <RequireLoginRoute path="/projects/:id" auth={this.props.auth} component={ProjectHome} />
            <RequireSystemRoute path="/system" auth={this.props.auth} component={SystemHome} />
            <RequireLoginRoute exact path="/projects" auth={this.props.auth} component={ProjectHome} />
            <RequireLoginRoute exact path="/noprivilege" auth={this.props.auth} component={NoPrivilege} />
            <RootRoute exact path="/" auth={this.props.auth} component={Home} />
            <Route component={NoMatch} />
          </Switch>
        </div>
        <Footer/>
      </div>
    );
  }
}

/**
 * StoreをPropsにマップする。
 * @param {*} state 
 */
function mapStateToProps(state) {
  return state;
}

/**
 * Dispatcherをマップする。
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch) {
  return {
    getSystemStateIfNeeded: () => {
      dispatch(getSystemStateIfNeeded())
    },
    setFrom: (from) => {
      dispatch(setFrom(from))
    },
    errFinish: () => {
      dispatch(errFinish())
    },
    initAuth: () => {
      dispatch(initAuth())
    },
    authenticated: (token) => {
      dispatch(authenticated(token))
    },
    loginByTokenIfNeeded: (token, exipre) => {
      dispatch(loginByTokenIfNeeded(token, exipre))
    },
	}
}

/**
 * reduxとの接続
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(App)))

