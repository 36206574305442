/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: notifications.js
 * Author: Naoaki Suganuma
 * Update: 2020/10/01
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';
import { err } from './errorAction';
import { ProjectsApi } from '../api/api'
import { getApiEndpoint } from '../constants/ApiEndpoint';

///--------------------------------------------
/// 通知Popperアイコンの表示・非表示
///--------------------------------------------
/**
 * 通知Popperアイコンの表示・非表示を変更する。
 * @param {boolean} open trueの場合は表示、falseの場合は非表示
 */
export function closeNotification() {
  return {
    type: types.CLOSE_NOTIFICATION
  };
}

///--------------------------------------------
/// 進行状況取得
///--------------------------------------------
/**
 * 必要に応じて進行状況を取得する。
 * 現在取得中の場合は、Promiseを返す。
 * 取得中でなければ進行状況取得APIを呼び出す。
 * @param {number} projectId プロジェクトID
 */
export function openOrRefreshNotification(projectId) {
  return (dispatch, getState) => {
    if (getState().app.isConnectiongServer) {
      return Promise.resolve();
    } else {
      return dispatch(getProgress(projectId));
    }
  };
}

/**
 * 進行状況取得APIを呼び出し、進行状況を取得する。
 * @param {number} projectId プロジェクトID
 */
function getProgress(projectId) {
  return dispatch => {
    dispatch(gettingProgress());
    let token = window.localStorage.getItem("token");
    var api = new ProjectsApi(getApiEndpoint());
    api.authentications.Bearer.apiKey = "Bearer " + token;
    api.getProgress(projectId)
      .then(function (val) {
        dispatch(receiveGetProgress(val.body));
        return null;
      })
      .catch(function (val) {
        dispatch(err(val));
        return null;
      })
  };
}

/**
 * 進行状況取得中に移行する。
 */
function gettingProgress() {
  return {
    type: types.GETTING_PROGRESS
  };
}

/**
 * 取得済み進行状況を設定する。
 * @param {*} progress 進行状況
 */
function receiveGetProgress(progress) {
  return {
    type: types.RECEIVE_GET_PROGRESS,
    progress: progress
  };
}

///--------------------------------------------
/// 進捗記録削除
///--------------------------------------------
/**
 * 必要に応じて進捗記録を削除する。
 * 現在削除中の場合は、Promiseを返す。
 * 削除中でなければ進捗記録削除APIを呼び出す。
 * @param {number} projectId プロジェクトID
 * @param {string} progressKey 進捗記録key
 */
 export function deleteProgressIfNeeded(projectId, progressKey) {
  return (dispatch, getState) => {
    if (getState().app.isConnectiongServer) {
      return Promise.resolve();
    } else {
      return dispatch(deleteProgress(projectId, progressKey));
    }
  };
}

/**
 * 進捗記録削除APIを呼び出し、進捗記録を削除する。
 * @param {number} projectId プロジェクトID
 * @param {string} progressKey 進捗記録key
 */
function deleteProgress(projectId, progressKey) {
  return dispatch => {
    dispatch(deletingProgress());
    let token = window.localStorage.getItem("token");
    var api = new ProjectsApi(getApiEndpoint());
    api.authentications.Bearer.apiKey = "Bearer " + token;
    api.deleteProgress(projectId, progressKey)
      .then(function (val) {
        dispatch(receiveDeleteProgress(progressKey));
        return null;
      })
      .catch(function (val) {
        dispatch(err(val));
        return null;
      })
  };
}

/**
 * 進捗記録削除中に移行する。
 */
function deletingProgress() {
  return {
    type: types.DELETING_PROGRESS
  };
}

/**
 * 削除済み進捗記録を通知する。
 * @param {string} progressKey 進捗記録ID
 */
function receiveDeleteProgress(progressKey) {
  return {
    type: types.RECEIVE_DELETE_PROGRESS,
    progressKey: progressKey
  };
}

///--------------------------------------------
/// 進捗記録キャンセル
///--------------------------------------------
/**
 * 必要に応じて進捗記録をキャンセルする。
 * 現在削除中の場合は、Promiseを返す。
 * 削除中でなければ進捗記録キャンセルAPIを呼び出す。
 * @param {number} projectId プロジェクトID
 * @param {string} progressKey 進捗記録key
 */
 export function cancelProgressIfNeeded(projectId, progressKey) {
  return (dispatch, getState) => {
    if (getState().app.isConnectiongServer) {
      return Promise.resolve();
    } else {
      return dispatch(cancelProgress(projectId, progressKey));
    }
  };
}

/**
 * 進捗記録キャンセルAPIを呼び出し、進捗記録をキャンセルする。
 * @param {number} projectId プロジェクトID
 * @param {string} progressKey 進捗記録key
 */
function cancelProgress(projectId, progressKey) {
  return dispatch => {
    dispatch(cancelingProgress());
    let token = window.localStorage.getItem("token");
    var api = new ProjectsApi(getApiEndpoint());
    api.authentications.Bearer.apiKey = "Bearer " + token;
    api.cancelProgress(projectId, progressKey)
      .then(function (val) {
        dispatch(receiveCancelProgress(progressKey));
        return null;
      })
      .catch(function (val) {
        dispatch(err(val));
        return null;
      })
  };
}

/**
 * 進捗記録キャンセル中に移行する。
 */
function cancelingProgress() {
  return {
    type: types.CANCELING_PROGRESS
  };
}

/**
 * 進捗記録キャンセルを通知する。
 * @param {string} progressKey 進捗記録ID
 */
function receiveCancelProgress(progressKey) {
  return {
    type: types.RECEIVE_CANCEL_PROGRESS,
    progressKey: progressKey
  };
}
