/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminTree.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';
import {callBackDataInSpecTable} from "../utils/mapUtils";

/**
 * 初期状態
 */
const initialState = {
  trees: [],
  workGroups: [],
  maps: [],
  locations: [],
  floorDetail: null,
  treeSelection: null,
  nodeSelection: null,
  treeDirty: false,
  nodeDirty: false,
  treeSelectBoxDirty: false,
};

/**
 * tree reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminTree(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_TREE: {
      return Object.assign({}, state, {
        trees: [],
        workGroups: [],
        maps: [],
        locations: [],
        floorDetail: null,
        treeSelection: null,
        nodeSelection: null,
        treeDirty: false,
        nodeDirty: false
      });
    }
    case types.RECEIVE_GET_ADMIN_TREE:
      return Object.assign({}, state, {
        trees: action.trees
      });
    case types.RECEIVE_GET_ADMIN_WORKGROUP:
      return Object.assign({}, state, {
        workGroups: action.workGroups
      });
    case types.RECEIVE_GET_ADMIN_MAP:
      return Object.assign({}, state, {
        maps: action.maps
      });
    case types.RECEIVE_GET_ADMIN_LOCATION:
      return Object.assign({}, state, {
        locations: action.locations
      });
    case types.RECEIVE_GET_ADMIN_FLOOR:
      return Object.assign({}, state, {
        floorDetail: action.floorDetail
      });
    case types.RECEIVE_CREATE_ADMIN_TREE: {
      let trees = Object.assign([], state.trees);
      trees.push(action.tree);
      return Object.assign({}, state, {
        trees: trees,
        treeSelection: null,
        nodeSelection: null,
        treeDirty: false,
        nodeDirty: false
      });
    }
    case types.RECEIVE_UPDATING_ADMIN_TREE_SELECT_BOX: {
      // action.tree[0].id
      let {treeSelect, treeRemain} = state.trees.reduce((preVal, nextVal) => {
        if (nextVal.id === action.tree[0].id) {
          preVal.treeSelect = nextVal
        }else  {
          preVal.treeRemain = preVal.treeRemain.concat(nextVal)
        }
        return preVal
      }, {
        treeSelect: null,
        treeRemain: [],
      });
      for (let keyTree in treeSelect) {
        if (keyTree === "id") {
          const treeIndex = action.tree.find(
              itemIndex => itemIndex.id === treeSelect[keyTree]
          )
          if (treeIndex) {
            treeSelect.inspectable = treeIndex.inspectable
          }
        }
        if (keyTree === "nodes" && treeSelect[keyTree].length > 0) {
          treeSelect[keyTree] = callBackDataInSpecTable(treeSelect[keyTree], action.tree)
        }
      }

      return Object.assign({}, state, {
        trees: treeRemain.concat(treeSelect),
        treeSelection: treeSelect,
        treeSelectBoxDirty: false,
      });
    }
    case types.RECEIVE_DELETE_ADMIN_TREE: {
      var trees = state.trees.filter(function (t) { return t.id !== action.treeId; });
      return Object.assign({}, state, {
        trees: trees,
        treeSelection: null,
        nodeSelection: null,
        treeDirty: false,
        nodeDirty: false,
        // treeSelectBoxDirty: false,
      });
    }
    case types.RECEIVE_CREATE_ADMIN_TREE_NODE: {
      let trees = Object.assign([], state.trees);
      let tree = trees.find((t) => { return t.id === state.treeSelection.id; });
      let parentNode = getNodeById(tree, action.node.parentId);
      parentNode.nodes.push(action.node);
      return Object.assign({}, state, {
        trees: trees,
        treeSelection: tree,
        treeDirty: false,
        nodeDirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_TREE_NODE: {
      let trees = Object.assign([], state.trees);
      let tree = trees.find((t) => { return t.id === state.treeSelection.id; });
      let existingNode = getNodeById(tree, action.node.id);
      if (existingNode.parentId) {
        let parentNode = getNodeById(tree, existingNode.parentId);
        let index = parentNode.nodes.findIndex((n) => { return n.id === action.node.id; });
        parentNode.nodes.splice(index, 1);
        let newParentNode = getNodeById(tree, action.node.parentId);
        existingNode.parentId = action.node.parentId;
        existingNode.displayOrder = action.node.displayOrder;
        existingNode.name = action.node.name;
        existingNode.zmin = action.node.zmin;
        existingNode.zmax = action.node.zmax;
        existingNode.arbitraryZ = action.node.arbitraryZ;
        existingNode.followFloorProp = action.node.followFloorProp;
        existingNode.workGroups = action.node.workGroups;
        existingNode.maps = action.node.maps;
        existingNode.locations = action.node.locations;
        newParentNode.nodes.push(existingNode);
      } else {
        existingNode.displayOrder = action.node.displayOrder;
        existingNode.name = action.node.name;
        existingNode.zmin = action.node.zmin;
        existingNode.zmax = action.node.zmax;
        existingNode.workGroups = action.node.workGroups;
        existingNode.maps = action.node.maps;
        existingNode.locations = action.node.locations;
      }
      return Object.assign({}, state, {
        trees: trees,
        treeSelection: tree
      });
    }
    case types.COMPLETE_UPDATE_ADMIN_TREE_NODE: 
    //ES6 syntax with react ver16+
      return {
        ...state,
        treeDirty: false,
        nodeDirty: false
      }
    case types.RECEIVE_DELETE_ADMIN_TREE_NODE: {
      let trees = Object.assign([], state.trees);
      let tree = trees.find((t) => { return t.id === state.treeSelection.id; });
      let node = getNodeById(tree, action.nodeId);
      let parentNode = getNodeById(tree, node.parentId);
      let index = parentNode.nodes.findIndex((n) => { return n.id === action.nodeId; });
      parentNode.nodes.splice(index, 1);
      return Object.assign({}, state, {
        trees: trees,
        treeSelection: tree,
        nodeSelection: null,
        treeDirty: false,
        nodeDirty: false,
        treeSelectBoxDirty: false,
      });
    }
    case types.SET_SELECT_ADMIN_TREE: {
      return Object.assign({}, state, {
        treeSelection: action.treeSelection,
        nodeSelection: null,
        treeDirty: false,
        nodeDirty: false,
        treeSelectBoxDirty: false,
      });
    }
    case types.SET_SELECT_ADMIN_TREE_NODE: {
      return Object.assign({}, state, {
        nodeSelection: action.nodeSelection,
        nodeDirty: false
      });
    }
    case types.SET_ADMIN_TREE_DIRTY:
      return Object.assign({}, state, {
        treeDirty: true
      });
    case types.SET_ADMIN_TREE_SELECT_BOX_DIRTY:
      return Object.assign({}, state, {
        treeSelectBoxDirty: true
      });
    case types.SET_ADMIN_TREE_NODE_DIRTY:
      return Object.assign({}, state, {
        treeDirty: true,
        nodeDirty: true
      });
    case types.CLEAN_ADMIN_TREE:
      return initialState;
    default:
      return state;
  }
}

/**
 * 指定したノード以下から指定IDを持つノードを探す
 * @param {object} node ノード
 * @param {number} id ID
 */
function getNodeById(node, id) {
  if (node.id === id) {
    return node;
  } else {
    for (let n of node.nodes) {
      let found = getNodeById(n, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
}