/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminInspectionRole.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  inspectionRoles: null,
  selection: null,
  dirty: false
};

/**
 * adminInspectionRole reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminInspectionRole(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_INSPECTION_ROLE:
      return Object.assign({}, state, {
        inspectionRoles: null,
        selection: null,
        dirty: false
      });
    case types.RECEIVE_GET_ADMIN_INSPECTION_ROLE:
      return Object.assign({}, state, {
        inspectionRoles: action.inspectionRoles,
        selection: null,
        dirty: false
      });
    case types.RECEIVE_CREATE_ADMIN_INSPECTION_ROLE: {
      let inspectionRoles = Object.assign([], state.inspectionRoles);
      inspectionRoles.push(action.inspectionRole);
      return Object.assign({}, state, {
        inspectionRoles: inspectionRoles,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_INSPECTION_ROLE: {
      let inspectionRoles = Object.assign([], state.inspectionRoles);
      inspectionRoles.splice(inspectionRoles.map(function (r) { return r.id; }).indexOf(action.inspectionRole.id), 1, action.inspectionRole);
      return Object.assign({}, state, {
        inspectionRoles: inspectionRoles,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_DELETE_ADMIN_INSPECTION_ROLE: {
      var inspectionRoles = state.inspectionRoles.filter(function (r) { return r.id !== action.rid; });
      return Object.assign({}, state, {
        inspectionRoles: inspectionRoles,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_ADMIN_INSPECTION_ROLE:
      return Object.assign({}, state, {
        selection: action.inspectionRole,
        dirty: false
      });
    case types.SET_ADMIN_INSPECTION_ROLE_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_ADMIN_INSPECTION_ROLE:
      return initialState;
    default:
      return state;
  }
}
