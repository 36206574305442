/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: NoMatch.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '../withTheme';

/**
 * スタイル情報 
 */
const styles = theme => ({
  base: {
    width: "100%",
    height: "100%"
  },
  div: {
    minWidth: 320,
    maxWidth: 400,
    height: 'auto',
    position: 'absolute',
    top: '20%',
    left: 0,
    right: 0,
    margin: 'auto',
  },
  paper: {
    padding: 20,
    overflow: 'auto',
  },
});

/**
 * NoMatchコンポーネント
 */
class NoMatch extends Component {
  /**
   * NoMatchコンポーネントのレンダリング
   */
  render() {
    return (
      <div className={this.props.classes.base}>
        <div className={this.props.classes.div}>
          <Paper className={this.props.classes.paper}>
            お探しのページが存在することを確認できませんでした。以下のいずれかのリンクからもう一度お探しください。<br />
            ログイン<br />
            ヘルプ<br />
            お問い合わせ<br />
          </Paper>
        </div>
      </div>
    );
  }
}

/**
 * Themeの適用
 */
export default withTheme(withStyles(styles)(NoMatch))