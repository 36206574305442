/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminLocation.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  locations: null,
  selection: null,
  dirty: false,
  importResult: null
};

/**
 * adminLocation reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminLocation(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_LOCATION:
      return Object.assign({}, state, {
        locations: null,
        selection: null,
        dirty: false,
        importResult: null
      });
    case types.RECEIVE_GET_ADMIN_LOCATION:
      return Object.assign({}, state, {
        locations: action.locations,
        selection: null,
        dirty: false,
        importResult: null
      });
    case types.RECEIVE_CREATE_ADMIN_LOCATION: {
      let locations = Object.assign([], state.locations);
      locations.push(action.location);
      return Object.assign({}, state, {
        locations: locations,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_LOCATION: {
      let locations = Object.assign([], state.locations);
      locations.splice(locations.map(function (l) { return l.id; }).indexOf(action.location.id), 1, action.location);
      return Object.assign({}, state, {
        locations: locations,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_DELETE_ADMIN_LOCATION: {
      var locations = state.locations.filter(function (l) { return l.id !== action.lid; });
      return Object.assign({}, state, {
        locations: locations,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_ADMIN_LOCATION:
      return Object.assign({}, state, {
        selection: action.location,
        dirty: false
      });
    case types.SET_ADMIN_LOCATION_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_ADMIN_LOCATION:
      return initialState;
    default:
      return state;
  }
}
