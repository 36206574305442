/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: SetPasword.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import ValidatorGroup from '../validation/ValidatorGroup';
import TextFieldValidator from '../validation/TextFieldValidator';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '../withTheme';
import { setPasswordIfNeeded } from '../actions/auth';

/**
 * スタイル情報 
 */
const styles = theme => ({
  base: {
    flex: "1 1 auto",
    display: "flex",
    position: "relative",
    overflow: "hidden",
  },
  frame: {
    minWidth: 480,
    maxWidth: 540,
    height: 'auto',
    position: 'absolute',
    top: '20%',
    left: 0,
    right: 0,
    margin: 'auto',
  },
  paper: {
    padding: 30,
    overflow: 'auto',
  },
  section100: {
    width: "100%",
    marginTop: 20
  },
  sectionTitle: {
    fontSize: "0.75em",
    color: grey[700],
  },
  sectionData: {
    height: 28,
    margin: "4px 0px 0px 0px",
    fontSize: "1.0em",
    color: grey[700],
    borderBottom: "solid 1px #ddd"
  },
  textField: {
    marginLeft: 0,
    width: "100%",
  },  
  register: {
    float: 'right',
    marginTop: 30,
    width: 200,
  },
  aux: {
    paddingTop: 50,
    textAlign: "center"
  },
  error: {
    width: "100%",
    textAlign: "center",
    margin: "15px 0px",
    padding: "5px 0",
    border: "solid 3px",
    borderColor: theme.palette.error.light,
    borderRadius: "3px"
  },
  errorText: {
    width: "100%",
    textAlign: "center",
    marginTop: 3
  },});

/**
 * SetPaswordコンポーネント
 */
class SetPasword extends Component {
  /**
    * コンストラクタ
    */
  constructor(props) {
    super(props);
    var token = this.getToken(props.history.location.search);
    var payload = this.decodeToken(token);
    this.state = {
      token: token,
      email: payload != null ? payload.email : null,
      password: "",
      passwordConfirm: "",
      passwordEmpty: false,
      passwordsNotMatch: false,
      groupValid: false
    };
  }

  /**
   * クエリ文字列からトークンを取得する。
   * @param {*} search  クエリ文字列
   */
  getToken(search) {
    try {
      var hash = search.slice(1).split('&');
      if (hash.length > 0) {
        var keyValue = hash[0].split('=');
        if (keyValue.length === 2) {
          return keyValue[1];
        }
      }
    } catch (e) {
    }
    return null;
  }

  /**
   * JWTトークンを解析する。
   * @param {*} token 
   */
  decodeToken(token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
    }
    return null;
  }

  /**
   * パスワードが変更された時に呼び出されるハンドラ
   */
  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value,
      passwordEmpty: false,
      passwordsNotMatch: false
    });
  }

  /**
   * 確認用パスワードが変更された時に呼び出されるハンドラ
   */
  handlePasswordConfirmChange = (event) => {
    this.setState({
      passwordConfirm: event.target.value,
      passwordEmpty: false,
      passwordsNotMatch: false
    });
  }

  /**
   * p1yが変更された時に呼び出されるハンドラ
   * @param {object} event 
   */
  handleGroupValidation = (validationResult) => {
    let valid = true;
    for (let result of validationResult) {
      valid &= result.valid;
    }
    this.setState({ groupValid: valid });
  }

  /**
   * パスワード設定ボタンがクリックされた時に呼び出されるハンドラ
   */
  handleSetPasswordButtonClick = (event) => {
    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({ passwordsNotMatch: true })
    } else if (this.isEmpty(this.state.password)) {
      this.setState({ passwordEmpty: true })
    } else {
      this.props.setPasswordIfNeeded(this.state.token, this.state.password);
    }
  }

  /*
   * 空文字かどうかの判定
   */
  isEmpty(value) {
    if (value === undefined || value == null) {
      return true;
    }
    if (typeof value === 'string') {
      return value.trim() === '' || value.trim().length === 0;
    }
    return false;
  }

  /**
   * エラーメッセージを取得する。
   * @param {エラー}} error 
   */
  getErrorMessage(error) {
    if (error === "notfound") {
      return "ユーザが見つかりません。";
    } else if (error === "expired") {
      return "有効期限が切れています。再度パスワード設定をやり直してください。";
    } else {
      return "エラーが発生しました。再度パスワード設定を行ってください。";
    }
  }

  /**
   * 登録用のコンポーネントを返す
   */
  getRegisterComponents() {
    return (
      this.state.token == null || this.state.email == null ?
        <Paper className={this.props.classes.paper}>
          <Typography variant="subtitle1">
            指定したアドレスは有効な情報ではありません。メールで送られてきたアドレスを間違いがないようにコピーし、再度ブラウザで開いてください。
          </Typography>
        </Paper>
        :
        <Paper className={this.props.classes.paper}>
            {
              this.state.passwordEmpty ?
              <div className={this.props.classes.error}>
                <Typography variant="subtitle1" color="error" className={this.props.classes.errorText}>パスワードを入力してください</Typography>
              </div>
              : null
            }
            {
              this.state.passwordsNotMatch ?
              <div className={this.props.classes.error}>
                <Typography variant="subtitle1" color="error" className={this.props.classes.errorText}>確認用パスワードが一致しません</Typography>
              </div>
              : null
            }
            {
              this.props.registerationError != null ?
              <div className={this.props.classes.error}>
                <Typography variant="subtitle1" color="error" className={this.props.classes.errorText}>{this.getErrorMessage(this.props.registerationError)}</Typography>
              </div>
              : null
            }
            <Typography variant="subtitle1">
              パスワードを再設定します。確認用パスワードにはパスワードと同じものを入力してください。
            </Typography>
            <div className={this.props.classes.section100} >
              <div className={this.props.classes.sectionTitle}>メールアドレス</div>
              <div className={this.props.classes.sectionData}>{this.state.email}</div>
            </div>
            <ValidatorGroup
              validatorGroupListener={this.handleGroupValidation}
            >
              <TextFieldValidator
                name="password"
                type="password"
                value={this.state.password}
                label="パスワード"
                onChange={this.handlePasswordChange}
                fullWidth
                margin="normal"
                validators={['minStringLength:8']}
                errorMessages={['8文字以上のパスワードが必要です']}
              />
              <TextFieldValidator
                name="passwordConfirm"
                type="password"
                value={this.state.passwordConfirm}
                label="パスワード（確認）"
                onChange={this.handlePasswordConfirmChange}
                fullWidth
                margin="normal"
                validators={['minStringLength:8']}
                errorMessages={['8文字以上のパスワードが必要です']}
              />
            </ValidatorGroup>
            <Button
            variant="contained"
              color="primary"
              disabled={!this.state.groupValid}
              className={this.props.classes.register}
              onClick={this.handleSetPasswordButtonClick}
            >
              再設定
            </Button>
          </Paper>
      );
  }

  /**
   * 登録完了後のコンポーネントを返す
   */
  getCompleteComponents() {
    return (
      <Paper className={this.props.classes.paper}>
        <Typography variant="subtitle1">
         パスワードを変更しました。ログイン画面からログインしてください。
        </Typography>
      </Paper>
    );
  }

  /**
   * Signupコンポーネントのレンダリング
   */
  render() {
    return (
      <div className={this.props.classes.base}>
        <div className={this.props.classes.frame}>
          { 
            this.props.setPasswordComplete ? this.getCompleteComponents() : this.getRegisterComponents()
          }
          <div className={this.props.classes.aux}>
            <Button
              component={Link} to="/"
              color="primary"
            >
              ログイン画面に戻る
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * StoreをPropsにマップする。
 * @param {*} state 
 */
function mapStateToProps(state) {
  return state.auth;
}

/**
 * Dispatcherをマップする。
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch) {
  return {
    setPasswordIfNeeded: (token, password) => {
      dispatch(setPasswordIfNeeded(token, password))
    }
  }
}

/**
 * reduxとの接続
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(SetPasword)))