/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: docViewer.js
 * Author: Naoaki Suganuma
 * Update: 2018/2/9
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  tocs: null,
  toc: null,
  doc: null,
  sasToken: null
};

/**
 * docviewer reducer
 * @param {*} state 状態
 * @param {*} action アクション
 */
export default function docViewer(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVE_DOCINDICES: {
      var toc = null;
      if (action.tocs && action.tocs.length > 0) {
        toc = action.tocs[0];
      }
      return Object.assign({}, state, {
        tocs: action.tocs,
        toc: toc,
      });
    }
    case types.SET_TOC:
      return Object.assign({}, state, {
        toc: action.toc
      });
    case types.RECEIVE_DOCUMENT:
    case types.RECEIVE_APPROVE_DOCUMENT:
      return Object.assign({}, state, {
        doc: action.doc
      });
    case types.RECEIVE_GET_DOC_SAS_TOKEN:
      return Object.assign({}, state, {
        sasToken: action.sasToken
      });
    case types.RECEIVE_LOGOUT:
    case types.INIT_AUTH:
      return initialState;
    default:
      return state;
  }
}
