/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminMap.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import moment from 'moment';
import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  maps: null,
  mapFiles: null,
  guideDetail: null,
  progress: 0,
  processing: false,
  selection: null,
  imageSize: null,
  clipRect: null,
  controlPoint: null,
  rotation: 0,
  guide: [-1, -1, -1, -1],
  dirty: false,
  generateDZI: false,
  startTime: null,
};

/**
 * map reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminMap(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_MAP: {
      return Object.assign({}, state, {
        maps: null,
        mapFiles: null,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_GET_ADMIN_MAP:
      return Object.assign({}, state, {
        maps: action.maps
      });
    case types.RECEIVE_GET_ADMIN_MAPFILE:
      return Object.assign({}, state, {
        mapFiles: action.mapFiles
      });
    case types.RECEIVE_GET_ADMIN_GUIDE:
      return Object.assign({}, state, {
        guideDetail: action.guideDetail
      });
    case types.CREATING_ADMIN_MAP_ASYNC: {
      return Object.assign({}, state, {
        processing: true,
        progress: 0,
        startTime: moment(),
        dirty: false
      });
    }
    case types.RECEIVE_CREATE_ADMIN_MAP_ASYNC: {
      return Object.assign({}, state, {
        progress: 100,
        processing: false,
        selection: null,
      });
    }
    case types.RECEIVE_COPY_ADMIN_MAP: {
      let maps = Object.assign([], state.maps);
      maps.push(action.map);
      return Object.assign({}, state, {
        maps: maps,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_MAP: {
      let maps = Object.assign([], state.maps);
      maps.splice(maps.map(function (m) { return m.id; }).indexOf(action.map.id), 1, action.map);
      return Object.assign({}, state, {
        maps: maps,
        selection: null,
        dirty: false
      });
    }
    case types.UPDATING_ADMIN_MAP_ASYNC: {
      return Object.assign({}, state, {
        processing: true,
        progress: 0,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_MAP_ASYNC: {
      return Object.assign({}, state, {
        progress: 100,
        processing: false,
        selection: null,
      });
    }
    case types.IMPORTING_ADMIN_MAP_ASYNC: {
      return Object.assign({}, state, {
        processing: true,
        progress: 0
      });
    }
    case types.RECEIVE_IMPORT_ADMIN_MAP_ASYNC: {
      return Object.assign({}, state, {
        progress: 100,
        processing: false
      });
    }
    case types.SET_ADMIN_MAP_PROGRESS: {
      return Object.assign({}, state, {
        progress: action.progress
      });
    }
    case types.RECEIVE_DELETE_ADMIN_MAP: {
      var maps = state.maps.filter(function (m) { return m.id !== action.mapId; });
      return Object.assign({}, state, {
        maps: maps,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_ADMIN_MAP: {
      let generateDZI = false
      if (state.selection != null && state.selection.id != null && state.selection.id === action.selection.id && state.selection.mapFileId !== action.selection.mapFileId) {
        generateDZI = true
      }
      let selection = Object.assign({}, action.selection);
      let mapFile = getMapFile(selection, state.mapFiles);
      let guide = getGuides(selection);
      return Object.assign({}, state, {
        selection: selection,
        imageSize: { x: mapFile.width, y: mapFile.height },
        clipRect: { 
          left: selection.clipX0, 
          right: selection.clipX1, 
          top: selection.clipY0,
          bottom: selection.clipY1
        },
        controlPoint: [
          { x: selection.logX0, y: selection.logY0 },
          { x: selection.logX1, y: selection.logY1 }
        ],
        rotation: selection.rotation,
        guide: guide,
        dirty: false,
        generateDZI: generateDZI
      });
    }
    case types.SET_CLIP_ADMIN_MAP: {
      let selection = Object.assign({}, state.selection);
      selection.clipX0 = Math.round(action.clipRect.left);
      selection.clipX1 = Math.round(action.clipRect.right);
      selection.clipY0 = Math.round(action.clipRect.top);
      selection.clipY1 = Math.round(action.clipRect.bottom);
      selection.centerX = Math.round(state.selection.centerX + state.selection.clipX0 - action.clipRect.left);
      selection.centerY = Math.round(state.selection.centerY + state.selection.clipY0 - action.clipRect.top);
      return Object.assign({}, state, {
        clipRect: action.clipRect,
        selection: selection,
        dirty: true,
        generateDZI: true
      });
    }
    case types.SET_CP0_ADMIN_MAP: {
      let selection = Object.assign({}, state.selection);
      selection.logX0 = action.cp0.x;
      selection.logY0 = action.cp0.y;
      return Object.assign({}, state, {
        controlPoint: [action.cp0, state.controlPoint[1]],
        selection: selection,
        dirty: true
      });      
    }
    case types.SET_CP1_ADMIN_MAP: {
      let selection = Object.assign({}, state.selection);
      selection.logX1 = action.cp1.x;
      selection.logY1 = action.cp1.y;
      return Object.assign({}, state, {
        controlPoint: [state.controlPoint[0], action.cp1],
        selection: selection,
        dirty: true
      });      
    }
    case types.SET_ROTATION_ADMIN_MAP: {
      let selection = Object.assign({}, state.selection);
      selection.rotation = action.rotation;
      return Object.assign({}, state, {
        rotation: action.rotation,
        selection: selection,
        dirty: true
      });      
    }
    case types.SET_GUIDE_ADMIN_MAP: {
      let selection = Object.assign({}, state.selection);
      if (action.guide[0] === -1 || action.guide[1] === -1) {
        selection.cp0Setting = ""
      } else {
        selection.cp0Setting = action.guide[0] + "," + action.guide[1];
      }
      if (action.guide[2] === -1 || action.guide[3] === -1) {
        selection.cp1Setting = ""
      } else {
        selection.cp1Setting = action.guide[2] + "," + action.guide[3];
      }
      return Object.assign({}, state, {
        guide: action.guide,
        selection: selection,
        dirty: true
      });      
    }
    case types.SET_ADMIN_MAP_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_ADMIN_MAP:
      return initialState;
    case types.ERR_API:
    case types.ERR_FINISH:
      return Object.assign({}, state, {
        processing: false,
        progress: 0,
      });
    default:
      return state;
  }
}

/**
 * 指定されたMapの元図面を取得する。
 * @param {object} map Map
 * @param {object} mapFiles 図面配列
 */
function getMapFile(map, mapFiles) {
  return mapFiles.find(function (mf) { return mf.id === map.mapFileId });
}

/**
 * 指定されたMapの制御点として指定されている通り芯を取得する。
 * @param {object} map Map
 */
function getGuides(map) {
  let guides = [-1, -1, -1, -1];
  if (map.cp0Setting) {
    let names = map.cp0Setting.split(',');
    if (names.length === 2) {
      guides[0] = parseInt(names[0], 10);
      guides[1] = parseInt(names[1], 10);
    }
  }
  if (map.cp1Setting) {
    let names = map.cp1Setting.split(',');
    if (names.length === 2) {
      guides[2] = parseInt(names[0], 10);
      guides[3] = parseInt(names[1], 10);
    }
  }
  return guides;
}

