/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: auth.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import { push } from 'connected-react-router';
import moment from 'moment';
import * as types from '../constants/ActionTypes';
import { err } from './errorAction';
import { AuthApi } from '../api/api';
import { SystemApi } from '../api/api';
import { getApiEndpoint } from '../constants/ApiEndpoint';
import { CONTENTS_CONTAINER_ID } from '../constants/AppConstants';

///--------------------------------------------
/// 最初に要求されたURLを設定する
///--------------------------------------------
/**
 * 最初に要求されたURLを設定する
 */
export function setFrom(from) {
  return {
    type: types.SET_FROM,
    from: from
  };
}

///--------------------------------------------
/// ログイン
///--------------------------------------------
/**
 * 必要に応じてログインを実行する。
 * ログイン実行中の場合は、Promiseを返す。
 * 実行中でなければログインAPIを呼び出す。
 * @param {string} username ユーザ名
 * @param {string} password パスワード
 * @param {string} expire JWT有効期限
 */
export function loginIfNeeded(username, password, expire) {
  return (dispatch, getState) => {
    if (getState().app.isConnectiongServer) {
      return Promise.resolve();
    } else {
      return dispatch(login(username, password, expire));
    }
  };
}

/**
 * ログインAPIを呼び出す。
 * @param {string} username ユーザ名
 * @param {string} password パスワード
 * @param {string} expire JWT有効期限
 */
function login(username, password, exipre) {
  return dispatch => {
    dispatch(loggingin());
    var api = new AuthApi(getApiEndpoint());
    api.login({ 'username': username, 'password': password, 'desiredExpire': exipre })
      .then(function (val) {
        dispatch(getUserByToken(username, val.body.token));
        return null;
      })
      .catch(function (val) {
        if (val && val.response) {
          let statusCode = val.response.statusCode;
          if (statusCode === 401) {
            dispatch(loginError());
            return null;
          }
        }
        dispatch(err(val));
        return null;
      })
  };
}

/**
 * ログイン実行中に移行する。
 */
function loggingin() {
  return {
    type: types.LOGGING_IN
  };
}

/**
 * システムACL受信結果を設定する。
 * @param {string} username ユーザ名
 * @param {object} token 認証トークン
 * @param {object} acl システムACL
 */
function loginError() {
  return {
    type: types.RECEIVE_LOGIN_ERROR
  };
}

///--------------------------------------------
/// Tokenによるログイン
///--------------------------------------------
/**
 * 必要に応じてログインを実行する。
 * ログイン実行中の場合は、Promiseを返す。
 * 実行中でなければログインAPIを呼び出す。
 * @param {string} token トークン
 * @param {string} expire JWT有効期限
 */
export function loginByTokenIfNeeded(token, expire) {
  return (dispatch, getState) => {
    if (getState().app.isConnectiongServer) {
      return Promise.resolve();
    } else {
      return dispatch(loginByToken(token, expire));
    }
  };
}

/**
 * ログインAPIを呼び出す。
 * @param {string} token トークン
 * @param {string} expire JWT有効期限
 */
function loginByToken(token, exipre) {
  return dispatch => {
    dispatch(loggingin());
    var api = new AuthApi(getApiEndpoint());
    api.loginByToken({ 'token': token, 'desiredExpire': exipre })
      .then(function (val) {
        dispatch(getUserByToken(val.body.username, val.body.token));
        return null;
      })
      .catch(function (val) {
        if (val && val.response) {
          let statusCode = val.response.statusCode;
          if (statusCode === 401) {
            dispatch(loginError());
            return null;
          }
        }
        dispatch(err(val));
        return null;
      })
  };
}


///--------------------------------------------
/// ユーザ情報取得
///--------------------------------------------
/**
 * ユーザ情報取得APIを呼び出す。
 * @param {string} username ユーザ名
 * @param {object} token 認証トークン
 */
function getUserByToken(username, token) {
  return dispatch => {
    var api = new AuthApi(getApiEndpoint());
    api.authentications.Bearer.apiKey = "Bearer " + token;
    api.userByToken()
      .then(function (val) {
        dispatch(getSystemAcl(username, token, val.body));
        return null;
      })
      .catch(function (val) {
        dispatch(err(val));
        return null;
      })
  };
}
///--------------------------------------------
/// システムACL
///--------------------------------------------
/**
 * システムACL取得APIを呼び出す。
 * @param {string} username ユーザ名
 * @param {object} token 認証トークン
 * @param {object} user ユーザ
 */
function getSystemAcl(username, token, user) {
  return dispatch => {
    var api = new SystemApi(getApiEndpoint());
    api.authentications.Bearer.apiKey = "Bearer " + token;
    api.systemAcl()
      .then(function (val) {
        dispatch(getSasToken(username, token, user, val.body));
        return null;
      })
      .catch(function (val) {
        dispatch(err(val));
        return null;
      })
  };
}

///--------------------------------------------
/// contensコンテナSASトークン取得
///--------------------------------------------
/**
 * SASトークン取得APIを呼び出し、SASトークンを取得する。
 * @param {string} username ユーザ名
 * @param {object} token 認証トークン
 * @param {object} user ユーザ
 * @param {object} acl システムACL
 */
function getSasToken(username, token, user, systemAcl) {
  return dispatch => {
    var api = new SystemApi(getApiEndpoint());
    api.authentications.Bearer.apiKey = "Bearer " + token;
    var now = moment();
    var today2am = moment().startOf('day').add(2, "hours");
    var expire = today2am.isAfter(now) ? today2am : today2am.add(1, "days");
    api.sasToken(
      {
        'projectId': CONTENTS_CONTAINER_ID,
        'expire': expire.toISOString(),
        'permission': {
          'add': false,
          'create': false,
          'list': false,
          'read': true,
          'write': false
        }
      }
    ).then(function (val) {
      dispatch(getHelp(username, token, user, systemAcl, val.body));
      return null;
    }).catch(function (val) {
        dispatch(err(val));
        return null;
      })
  };
}

///--------------------------------------------
/// ヘルプ一覧取得
///--------------------------------------------
/**
 * ヘルプ取得APIを呼び出し、ヘルプ一覧を取得する。
 */
function getHelp(username, token, user, systemAcl, sasToken) {
  return dispatch => {
    var api = new SystemApi(getApiEndpoint());
    api.authentications.Bearer.apiKey = "Bearer " + token;
    api.getHelps(null)
      .then(function (val) {
        dispatch(receiveGetHelp(username, token, user, systemAcl, sasToken, val.body));
        return null;
      })
      .catch(function (val) {
        dispatch(err(val));
        return null;
      })
  };
}

/**
 * 取得済みヘルプ一覧を設定する。
 * @param {*} helps ヘルプ一覧
 */
function receiveGetHelp(username, token, user, systemAcl, sasToken, helps) {
  return {
    type: types.RECEIVE_LOGIN,
    username: username,
    user: user,
    token: token,
    systemAcl: systemAcl,
    sasToken: sasToken.sasToken,
    helps: helps
  };
}

///--------------------------------------------
/// ログアウト
///--------------------------------------------
/**
 * 必要に応じてログアウトを実行する。
 * ログアウト実行中の場合は、Promiseを返す。
 * 実行中でなければログアウトAPIを呼び出す。
 */
export function logoutIfNeeded() {
  return (dispatch, getState) => {
    if (getState().app.isConnectiongServer) {
      return Promise.resolve();
    } else {
      return dispatch(logout());
    }
  };
}

/**
 * ログアウトAPIを呼び出す。
 */
function logout() {
  return dispatch => {
    dispatch(loggingout());
    let token = window.localStorage.getItem("token");
    var api = new AuthApi(getApiEndpoint());
    api.authentications.Bearer.apiKey = "Bearer " + token;
    api.logout()
      .then(function (val) {
        dispatch(receiveLogout());
        dispatch(push('/'));
        return null;
      })
      .catch(function (val) {
        dispatch(err(val));
        return null;
      })
  };
}

/**
 * ログアウト実行中に移行する。
 */
function loggingout() {
  return {
    type: types.LOGGING_OUT
  };
}

/**
 * ログアウト結果を設定する。
 */
function receiveLogout() {
  return {
    type: types.RECEIVE_LOGOUT
  };
}

/**
 * 認証状態を初期化する。
 */
export function initAuth() {
  return {
    type: types.INIT_AUTH
  };
}

/**
 * トークン情報がある場合のアクション
 */
export function authenticated(token) {  
  return (dispatch, getState) => {
    if (getState().app.isConnectiongServer) {
      return Promise.resolve();
    } else {
      var ary = token.trim().split(".");
      var json = JSON.parse(window.atob(ary[1]));
      dispatch(authenticatedLoggingIn());
      return dispatch(getUserByToken(json.username, token));
    }
  }
}

/**
 * 認証トークン情報でログイン開始中に移行する。
 */
function authenticatedLoggingIn() {
  return {
    type: types.AUTHENTICATED_LOGGING_IN
  };
}

///--------------------------------------------
/// サインアップ
///--------------------------------------------
/**
 * 必要に応じてサインアップを実行する。
 * サインアップ実行中の場合は、Promiseを返す。
 * 実行中でなければサインアップAPIを呼び出す。
 * @param {string} email メールアドレス
 */
export function signupIfNeeded(email) {
  return (dispatch, getState) => {
    if (getState().app.isConnectiongServer) {
      return Promise.resolve();
    } else {
      return dispatch(signup(email));
    }
  };
}

/**
 * サインアップAPIを呼び出す。
 * @param {string} email メールアドレス
 */
function signup(email) {
  return dispatch => {
    dispatch(signingUp());
    var api = new AuthApi(getApiEndpoint());
    api.provisionalRegistration(email)
      .then(function (val) {
        dispatch(receiveSignup());
        return null;
      })
      .catch(function (val) {
        if (val && val.response) {
          let statusCode = val.response.statusCode;
          if (statusCode === 409) {
            dispatch(receiveSignupError("exists"));
            return null;
          }
        }
        dispatch(err(val));
        return null;
      })
  };
}

/**
 * サインアップ実行中に移行する。
 */
function signingUp() {
  return {
    type: types.SIGNING_UP
  };
}

/**
 * ログアウト結果を設定する。
 */
function receiveSignup() {
  return {
    type: types.RECEIVE_SIGNUP
  };
}

/**
 * 登録済みエラーを設定する。
 */
function receiveSignupError(error) {
  return {
    type: types.RECEIVE_SIGNUP_ERROR,
    signupError: error
  };
}

///--------------------------------------------
/// ユーザ登録の完了
///--------------------------------------------
/**
 * サインアップを完了する。
 */
export function clearSignup() {
  return {
    type: types.CLEAR_SIGN_UP
  };
}

///--------------------------------------------
/// ユーザ登録
///--------------------------------------------
/**
 * 必要に応じてユーザ登録を実行する。
 * ユーザ登録実行中の場合は、Promiseを返す。
 * 実行中でなければユーザ登録APIを呼び出す。
 * @param {string} token トークン
 * @param {string} password パスワード
 * @param {string} name 氏名
 */
export function registerIfNeeded(token, email, password, name) {
  return (dispatch, getState) => {
    if (getState().app.isConnectiongServer) {
      return Promise.resolve();
    } else {
      return dispatch(register(token, email, password, name));
    }
  };
}

/**
 * ユーザ登録APIを呼び出す。
 * @param {string} token トークン
 * @param {string} password パスワード
 * @param {string} name 氏名
 */
function register(token, email, password, name) {
  return dispatch => {
    dispatch(registering());
    var api = new AuthApi(getApiEndpoint());
    api.userRegistration(token, { 'name': name, 'email': email, 'password': password })
      .then(function (val) {
        dispatch(receiveRegister());
        return null;
      })
      .catch(function (val) {
        if (val && val.response) {
          let statusCode = val.response.statusCode;
          if (statusCode === 409) {
            dispatch(receiveRegisterError("exists"));
            return null;
          } else if (statusCode === 410) {
            dispatch(receiveRegisterError("expired"));
            return null;
          }
        }
        dispatch(err(val));
        return null;
      })
  };
}

/**
 * ユーザ登録実行中に移行する。
 */
function registering() {
  return {
    type: types.REGISTERING
  };
}

/**
 * ユーザ登録結果を設定する。
 */
function receiveRegister() {
  return {
    type: types.RECEIVE_REGISTER
  };
}


/**
 * ユーザ登録結果を設定する。
 */
function receiveRegisterError(error) {
  return {
    type: types.RECEIVE_REGISTER_ERROR,
    registerationError: error
  };
}

///--------------------------------------------
/// パスワード再設定
///--------------------------------------------
/**
 * 必要に応じてパスワード再設定を実行する。
 * パスワード再設定実行中の場合は、Promiseを返す。
 * 実行中でなければパスワード再設定APIを呼び出す。
 * @param {string} email メールアドレス
 */
export function passwordIfNeeded(email) {
  return (dispatch, getState) => {
    if (getState().app.isConnectiongServer) {
      return Promise.resolve();
    } else {
      return dispatch(password(email));
    }
  };
}

/**
 * パスワード再設定APIを呼び出す。
 * @param {string} email メールアドレス
 */
function password(email) {
  return dispatch => {
    dispatch(passwordResetting());
    var api = new AuthApi(getApiEndpoint());
    api.passwordResetMailSend(email)
      .then(function (val) {
        dispatch(receivePassword());
        return null;
      })
      .catch(function (val) {
        if (val && val.response) {
          let statusCode = val.response.statusCode;
          if (statusCode === 404) {
            dispatch(receivePasswordError("notfound"));
            return null;
          }
        }
        dispatch(err(val));
        return null;
      })
  };
}

/**
 * パスワード再設定実行中に移行する。
 */
function passwordResetting() {
  return {
    type: types.PASSWORD_RESETTING
  };
}

/**
 * パスワード再設定結果を設定する。
 */
function receivePassword() {
  return {
    type: types.RECEIVE_PASSWORD
  };
}


/**
 * エラーを設定する。
 */
function receivePasswordError(error) {
  return {
    type: types.RECEIVE_PASSWORD_ERROR,
    passwordError: error
  };
}

///--------------------------------------------
/// パスワード設定
///--------------------------------------------
/**
 * 必要に応じてパスワード設定を実行する。
 * パスワード設定実行中の場合は、Promiseを返す。
 * 実行中でなければパスワード設定APIを呼び出す。
 * @param {string} token トークン
 * @param {string} password パスワード
 */
export function setPasswordIfNeeded(token, password) {
  return (dispatch, getState) => {
    if (getState().app.isConnectiongServer) {
      return Promise.resolve();
    } else {
      return dispatch(setPassword(token, password));
    }
  };
}

/**
 * パスワード設定APIを呼び出す。
 * @param {string} token トークン
 * @param {string} password パスワード
 */
function setPassword(token, password) {
  return dispatch => {
    dispatch(settingPassword());
    var api = new AuthApi(getApiEndpoint());
    api.resetPassword(token, { 'password': password })
      .then(function (val) {
        dispatch(receiveSetPassword());
        return null;
      })
      .catch(function (val) {
        if (val && val.response) {
          let statusCode = val.response.statusCode;
          if (statusCode === 404) {
            dispatch(receiveSetPasswordError("notfound"));
            return null;
          } else if (statusCode === 410) {
            dispatch(receiveRegisterError("expired"));
            return null;
          }
        }
        dispatch(err(val));
        return null;
      })
  };
}

/**
 * パスワード設定実行中に移行する。
 */
function settingPassword() {
  return {
    type: types.SETTING_PASSWORD
  };
}

/**
 * パスワード設定結果を設定する。
 */
function receiveSetPassword() {
  return {
    type: types.RECEIVE_SET_PASSWORD
  };
}

/**
 * パスワード設定結果を設定する。
 */
function receiveSetPasswordError(error) {
  return {
    type: types.RECEIVE_SET_PASSWORD_ERROR,
    setPasswordError: error
  };
}
