/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: systemInspection.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  inspectionModels: null,
  masters: null,
  selection: null,
  selectionType: null,
  dirty: false
};

/**
 * inspection reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function systemInspection(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_SYSTEM_INSPECTION_MODEL: {
      return Object.assign({}, state, {
        inspectionModels: null,
        masters: null,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_GET_SYSTEM_INSPECTION_MODEL:
      return Object.assign({}, state, {
        inspectionModels: action.inspectionModels
      });
    case types.GETTING_SYSTEM_INSPECTION_MASTER: {
      return Object.assign({}, state, {
        masters: null,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_GET_SYSTEM_INSPECTION_MASTER:
      return Object.assign({}, state, {
        masters: action.masters
      });
    case types.RECEIVE_UPDATE_SYSTEM_INSPECTION_MODEL: {
      let inspectionModels = Object.assign([], state.inspectionModels);
      inspectionModels.splice(inspectionModels.map(function (m) { return m.id; }).indexOf(action.inspectionModel.id), 1, action.inspectionModel);
      return Object.assign({}, state, {
        inspectionModels: inspectionModels,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_CREATE_SYSTEM_INSPECTION_MASTER: {
      let masters = Object.assign([], state.masters);
      masters.push(action.master);
      return Object.assign({}, state, {
        masters: masters,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_SYSTEM_INSPECTION_MASTER: {
      let masters = Object.assign([], state.masters);
      masters.splice(masters.map(function (m) { return m.id; }).indexOf(action.master.id), 1, action.master);
      return Object.assign({}, state, {
        masters: masters,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_DELETE_SYSTEM_INSPECTION_MASTER: {
      var masters = state.masters.filter(function (m) { return m.id !== action.masterId; });
      return Object.assign({}, state, {
        masters: masters,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_SYSTEM_INSPECTION_MODEL:
      return Object.assign({}, state, {
        selection: action.selection,
        selectionType: action.selectionType,
        dirty: false
      });
    case types.SET_SYSTEM_INSPECTION_MODEL_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_SYSTEM_INSPECTION_MODEL:
      return initialState;
    default:
      return state;
  }
}
