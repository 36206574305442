/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: mapUtils.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import {AREA_LAYER_ID, GUIDE_LAYER_ID, PIN_STATE} from "../constants/AppConstants";
import {hasInspectionAcl} from "./acl";

/**
 * マップの論理矩形を求める。
 * @param {object} projectDetail プロジェクト詳細情報
 * @param {number} mapId マップID
 */

import moment from 'moment';

export function getMapLogRect(projectDetail, nodeId, mapId) {
  let map = getMapById(projectDetail, nodeId, mapId);
  let guideId = getGuideId(projectDetail);
  let guide = projectDetail.guides.find(function (g) { return g.id === guideId; });
  let cp0Setting = map.cp0Setting ? map.cp0Setting : "";
  let cp1Setting = map.cp1Setting ? map.cp1Setting : "";

  if (cp0Setting.length > 0 && cp1Setting.length > 0) {
    let intersection0 = getControlPoint(cp0Setting, guide);
    let intersection1 = getControlPoint(cp1Setting, guide);
    if (intersection0 && intersection1) {
      let left = intersection1.x - (map.logX1 - map.clipX0) * (intersection1.x - intersection0.x) / (map.logX1 - map.logX0);
      let right = intersection1.x + (map.clipX1 - map.logX1) * (intersection1.x - intersection0.x) / (map.logX1 - map.logX0);
      let top = intersection1.y - (map.logY1 - map.clipY0) * (intersection1.y - intersection0.y) / (map.logY1 - map.logY0);
      let bottom = intersection1.y + (map.clipY1 - map.logY1) * (intersection1.y - intersection0.y) / (map.logY1 - map.logY0);
      return {
        left: left, 
        top: top, 
        right: right, 
        bottom: bottom
      };
    }
  }
  return {
    left: 0,
    top: 0,
    right: Math.abs(map.clipX1 - map.clipX0),
    bottom: Math.abs(map.clipY1 - map.clipY0)
  };
}

/**
 * 制御点の座標を求める。
 * @param {string} cpSetting 
 * @param {object} guide 
 */
function getControlPoint(cpSetting, guide) {
  let cpList = cpSetting.split(',');
  if (cpList.length !== 2) {
    return null;
  }
  let v0 = getVectorByGuideId(Number(cpList[0]), guide);
  let v1 = getVectorByGuideId(Number(cpList[1]), guide);
  if (v0 && v1) {
    return getIntersection(v0, v1);
  }
  return null;
}

/**
 * 指定したIDを持つ通り芯のベクトルを求める。
 * @param {number} guideId 
 * @param {object} guide 
 */
function getVectorByGuideId(guideId, guide) {
  let hGuide = guide.horizontal.find(function (h) { return h.id === guideId; });
  if (hGuide) {
    return getHorizontalVector(hGuide);
  }
  let vGuide = guide.vertical.find(function (v) { return v.id === guideId; });
  if (vGuide) {
    return getVerticalVector(vGuide);
  }
  let dGuide = guide.diagonal.find(function (d) { return d.id === guideId; });
  if (dGuide) {
    return getDiagonalVector(dGuide);
  }
  return null;
}

/**
 * 水平通り芯のベクトルを求める。
 * @param {object} guide 
 */
function getHorizontalVector(guide) {
  return {
    p0: { x: -10000, y: guide.y },
    p1: { x: 10000, y: guide.y }
  }
}

/**
 * 垂直通り芯のベクトルを求める。
 * @param {object} guide 
 */
function getVerticalVector(guide) {
  return {
    p0: { x: guide.x, y: -10000 },
    p1: { x: guide.x, y: 10000 }
  }
}

/**
 * ２点指定通り芯のベクトルを求める。
 * @param {object} guide 
 */
function getDiagonalVector(guide) {
  return {
    p0: { x: guide.p0.x, y: guide.p0.y },
    p1: { x: guide.p1.x, y: guide.p1.y }
  }
}

/**
  * 2つのベクトルの交点を求める。
  * @param {*} v0 ベクトル0
  * @param {*} v1 ベクトル1
  */
function getIntersection(v0, v1) {
  let p0 = { x: v0.p1.x - v0.p0.x, y: v0.p1.y - v0.p0.y };
  let p1 = { x: v1.p1.x - v1.p0.x, y: v1.p1.y - v1.p0.y };
  let p2 = { x: v1.p0.x - v0.p0.x, y: v1.p0.y - v0.p0.y };
  let p3 = { x: v0.p1.x - v1.p0.x, y: v0.p1.y - v1.p0.y };
  let area0 = p1.x * p2.y - p1.y * p2.x
  let area1 = p1.x * p3.y - p1.y * p3.x
  let ratio = area0 / (area0 + area1)
  return { x: v0.p0.x + ratio * p0.x, y: v0.p0.y + ratio * p0.y };
}

/**
 * プロジェクト内の最初の通り芯IDを返す。
 * サーバは複数の通り芯セットを保持できるように構成しているが、UI上はプロジェクトにつき一つの通り芯という前提とする。
 * @param {*} projectDetail プロジェクト詳細情報
 */
function getGuideId(projectDetail) {
  if (!projectDetail) {
    return null;
  }
  if (projectDetail.guides.length > 0) {
    return projectDetail.guides[0].id;
  } else {
    return null;
  }
}

/**
 * 指定したノード以下から指定IDを持つノードを探す
 * @param {object} node ノード
 * @param {number} id ID
 */
export function getNodeById(projectDetail, nodeId) {
  if (!projectDetail || !nodeId) {
    return null;
  }
  for (let tree of projectDetail.mapTrees) {
    if (tree.id === nodeId) {
      return tree;
    } else {
      let node = getNodeByIdStep(tree.nodes, nodeId);
      if (node) {
        return node;
      }
    }
  }
  return null;
}

/**
 * 子ノードの配列を再帰的に走査してnodeIdを持つノードを探す。
 * @param {array} nodes 子ノードの配列
 * @param {number} nodeId ノードID
 */
function getNodeByIdStep(nodes, nodeId) {
  for (let node of nodes) {
    if (node.id === nodeId) {
      return node;
    }
    if (node.nodes) {
      let found = getNodeByIdStep(node.nodes, nodeId);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

/**
 * 指定したIDのマップを探す。
 * @param {*} projectDetail プロジェクト詳細情報
 * @param {number} mapId マップID
 */
export function getMapById(projectDetail, nodeId, mapId) {
  if (!projectDetail  || !nodeId || !mapId) {
    return null;
  }
  for (let tree of projectDetail.mapTrees) {
    let map = getMapByIdFromNode(tree.nodes, nodeId, mapId);
    if (map) {
      return map;
    }
  }
  return null;
}

/**
 * 子ノードの配列を再帰的に走査してmapIdを持つマップを探す。
 * @param {array} nodes 子ノードの配列
 * @param {number} mapId マップID
 * @param {object} logicalRect 論理矩形
 */
function getMapByIdFromNode(nodes, nodeId, mapId) {
  for (let node of nodes) {
    if (node.id === nodeId) {
      let map = node.maps.find((map) => map.id === mapId);
      if (map) {
        return map;
      }
    }
    if (node.nodes) {
      let map = getMapByIdFromNode(node.nodes, nodeId, mapId);
      if (map) {
        return map;
      }
    }
  }
  return null;
}

/**
 * 指定したノードIDからノードが属するツリーIDを取得する。
 * @param {object} projectDetail プロジェクト情報
 * @param {number} nodeId ノードID
 */
export function getTreeByNodeId(projectDetail, nodeId) {
  var node = getNodeById(projectDetail, nodeId);
  while (node) {
    let parentId = node.parentId;
    if (!parentId) {
      return node;
    }
    node = getNodeById(projectDetail, parentId);
  }
  return null;
}

/**
 * 画像座標を論理座標に変換する。
 * @param {object} imagePoint 画像座標の点
 * @param {object} imageRect  画像矩形
 * @param {object} logicalRect 論理矩形
 */
export function imagePointToLogicalPoint(imagePoint, imageRect, logicalRect) {
  let x = (imagePoint.x - imageRect.left) * (logicalRect.right - logicalRect.left) / (imageRect.right - imageRect.left) + logicalRect.left;
  let y = (imagePoint.y - imageRect.top) * (logicalRect.bottom - logicalRect.top) / (imageRect.bottom - imageRect.top) + logicalRect.top;
  return { x: x, y: y };
}

export function filterIconsDependOnAreaWithPart({icons, areaId, partId, inspectionId, state}) {
  let iconsFilter = icons;
  if (areaId === 0) {
    iconsFilter = iconsFilter.filter(icon =>
        icon.inspectionId === inspectionId && icon.state === PIN_STATE.INUSE && icon.areaIcon === false &&
        icon.areaId === undefined,
    )
  } else if (areaId != null && areaId !== -1) {
    iconsFilter = iconsFilter.filter(icon =>
        icon.inspectionId === inspectionId && icon.state === PIN_STATE.INUSE && icon.areaId === areaId,
    )
  }

  if (partId) {
    iconsFilter = iconsFilter.filter(e =>
        e.valueText0 === partId || (e.inspectionId === inspectionId && e.areaIcon === true)
    )
  }

  if (state) {
    iconsFilter = getIconsByCurrentMapId(state.visibleValue, iconsFilter, state.mapId)

    if (state.inspectionType === "gs") {
      iconsFilter = getIconsByGsInspectionFilter(iconsFilter, state.gsInspectionFilter)
    }
    if (state.inspectionType === "finchk") {
      iconsFilter = getIconsByFinchkInspectionFilter(iconsFilter, state.finchkInspectionFilter)
    }
  }

  return iconsFilter
}

// find position name of icon (using)
export function findNameOfIcon (x, y, partsId, props) {
  let minx = [];
  let miny = [];
  let verNearest = [];
  let verNear2nd = [];
  let horNearest = [];
  let horNear2nd = [];

  props.project.projectDetail.guides[0].vertical.forEach((ver) => {
    minx.push(Math.abs(x - ver.x));
  })
  props.project.projectDetail.guides[0].horizontal.forEach((hor) => {
    miny.push(Math.abs(y - hor.y));
  })

  let listMinx = minx.sort(function(a,b) { return a - b;});
  let listMiny = miny.sort(function(a,b) { return a - b;});

  props.project.projectDetail.guides[0].vertical.forEach((ver) => {
    if ( Math.abs(Math.abs(x - ver.x) - listMinx[0]) < 0.001 ) {
      verNearest.push(ver.name);
    }
    if ( Math.abs(Math.abs(x - ver.x) - listMinx[1]) < 0.001 ) {
      verNear2nd.push(ver.name);
    }
  })
  props.project.projectDetail.guides[0].horizontal.forEach((hor) => {
    if ( Math.abs(Math.abs(y - hor.y) - listMiny[0]) < 0.001 ) {
      horNearest.push(hor.name);
    }
    if ( Math.abs(Math.abs(y - hor.y) - listMiny[1]) < 0.001 ) {
      horNear2nd.push(hor.name);
    }
  })

  let parts = getInspectionMaster(props.project.projectDetail.inspections,props.project.inspectionId)
  let choose = parts.parts.find((e) => e.id === partsId);
  let position;
  let vertical = props.project.projectDetail.guides[0].vertical.find((e) => e.name === verNearest[0]);

  let horizontal = props.project.projectDetail.guides[0].horizontal.find((e) => e.name === horNearest[0]);
  let alphab = sortArrayStringHor(horNearest.concat(horNear2nd), props);
  let stt = sortArrayStringVer(verNearest.concat(verNear2nd), props);
  let checkH = props.project.projectDetail.guides[0].horizontal.find((e) => e.name === prevLetterHor(horNearest[0], props));
  let checkV = props.project.projectDetail.guides[0].vertical.find((e) => e.name === prevLetterVer(verNearest[0], props));
  let checkNextH = props.project.projectDetail.guides[0].horizontal.find((e) => e.name === nextLetterHor(horNearest[0], props)); // check exist horizontal line before target line
  let checkNextV = props.project.projectDetail.guides[0].vertical.find((e) => e.name === nextLetterVer(verNearest[0], props)); // check exist vertical line before target line
  let interSect = listMinx[0] < 10 && listMiny[0] < 10;
  let isMiddle = listMinx[0] < 30 || listMiny[0] < 30;
  let isCenter = listMinx[0] > 30 && listMiny[0] > 30;
  if ( !verNearest.length || !horNearest || !choose) {
    return ''
  }

  switch (choose.namingRule) {
    case 1:
      position = verNearest[0] + ',' + horNearest[0];
      break;
    case 2:
      position = pinNamingRuleBeam(interSect, isMiddle, isCenter, position, verNearest, verNear2nd, horNearest, horNear2nd, listMinx, listMiny, checkNextH, checkH, checkNextV, checkV, x, y, vertical, horizontal, alphab, stt);
      break;
    case 3:
      position = pinNamingRuleBed(interSect, isMiddle, isCenter, position, verNearest, verNear2nd, horNearest, horNear2nd, listMinx, listMiny, checkNextH, checkH, checkNextV, checkV, x, y, vertical, horizontal, alphab, stt);
      break;
    default:
      break;
  }
  return position;
}
// get inspection master (using)
export function getInspectionMaster(listDataFiler, idFilter) {
  let inspection = listDataFiler.find((ins) => { return ins.id === idFilter });
  let parsedJSON;
  try {
    parsedJSON = JSON.parse(inspection.master);
    const masterProperty = [
      'floor',
      'parts',
      'crossSection',
      'RFARR_area',
      'chkItem',
      'process',
      'crossSectionGroup',
      'memo'
    ];

    masterProperty.forEach(item => {
      if (!(item in parsedJSON)) {
        parsedJSON[item] = [];
      }
      if ((item === 'parts' || item === 'process' || item === 'chkItem') && parsedJSON[item].length) {
        parsedJSON[item] = parsedJSON[item].filter(item => (!item.deleteflg && !item.hide)).sort((a, b) => a.order - b.order);
      }
      if ((item === 'crossSectionGroup' || item === 'crossSection') && parsedJSON[item].length) {
        parsedJSON[item] = parsedJSON[item].filter(item => (!item.deleteflg && !item.hide));
      }
      if (item === 'memo' && parsedJSON[item].length) {
        parsedJSON[item] = parsedJSON[item].filter(item => item);
      }
    });

    parsedJSON.crossSection.forEach(item => {
      if (item.information && typeof item.information === "string") {
        item.information = JSON.parse(item.information);
      }
    });
  } catch(e) {
    parsedJSON = JSON.parse(`{
        "floor": [],
        "parts": [],
        "crossSection": [],
        "RFARR_area": [],
        "chkItem": [],
        "process": [],
        "crossSectionGroup": [],
        "memo": []
      }`);
  }
  return inspection && parsedJSON;
}

// find postion name of parts (beam) (using)
export function pinNamingRuleBeam(interSect, isMiddle, isCenter, position, verNearest, verNear2nd, horNearest, horNear2nd, listMinx, listMiny, checkNextH, checkH, checkNextV, checkV, x, y, vertical, horizontal, alphab, stt) {
  if ( interSect ) {
    position = pinInInterSectionBeam(position, checkH, verNearest, horNear2nd, horNearest);
  } else if ( isMiddle ) {
    position = pinInMiddleInlineBeam(position, verNearest, horNearest, x ,y, vertical, horizontal, alphab, stt, horNear2nd);
  } else if ( isCenter ) {
    position = pinInCenterBlockBeam(position, verNearest, verNear2nd, horNearest, horNear2nd, listMinx, listMiny, checkNextH, checkH, checkNextV, checkV, x, y, vertical, horizontal, alphab, stt);
  } else {
    position = pinInOtherLocationBeam(position, x, y, vertical, horizontal, verNearest, horNearest, horNear2nd, alphab, stt);
  }
  return position;
}

// find postion name of parts (bed) (using)
export function pinNamingRuleBed(interSect, isMiddle, isCenter, position, verNearest, verNear2nd, horNearest, horNear2nd, listMinx, listMiny, checkNextH, checkH, checkNextV, checkV, x, y, vertical, horizontal, alphab, stt) {
  if ( interSect ) {
    position = pinInInterSectionBed(position, checkH, checkV, verNear2nd, verNearest, horNear2nd, horNearest);
  } else if ( isMiddle ) {
    position = pinInMiddleInlineBed(position, verNearest, verNear2nd, horNearest, horNear2nd, checkH, checkV, x, y, vertical, horizontal, alphab, stt);
  } else if ( isCenter ) {
    position = pinInCenterBlockBed(position, verNearest, verNear2nd, horNearest, horNear2nd, checkH, checkV, checkNextH, checkNextV, x, y, vertical, horizontal, alphab, stt);
  } else {
    position = pinInOtherLocationBed(position, verNearest, horNearest, x, y, vertical, horizontal, alphab, stt);
  }
  return position;
}

// pin in intersection of 2 line (using)
export function pinInInterSectionBeam(position, checkH, verNearest, horNear2nd, horNearest) {
  if ( checkH === undefined ) {
    position = '';
  } else {
    position = verNearest[0] + ',' + horNear2nd[0] + '-' + horNearest[0];
  }
  return position;
}

// pin in between 2 intersection (using)
export function pinInMiddleInlineBeam(position, verNearest, horNearest, x ,y, vertical, horizontal, alphab, stt, horNear2nd) {
  if ( verNearest.length === 2 ) {
    position = verNearest[0] + '-' + verNearest[1] + ',' + horNearest[0];
  } else if ( horNearest.length === 2 ) {
    position = verNearest[0] + ',' + horNearest[0] + '-' + horNearest[1];
  } else {
    if ( Math.abs(x - vertical.x) - Math.abs(y - horizontal.y) < 0 ) {
      position = verNearest[0] + ',' + alphab[0] + '-' + alphab[1];
    } else if ( Math.abs(x - vertical.x) - Math.abs(y - horizontal.y) > 0 ) {
      position = stt[0] + '-' + stt[1] + ',' + horNearest[0];
    } else {
      position = verNearest[0] + ',' + horNear2nd[0] + '-' + horNearest[0];
    }
  }
  return position;
}

// pin in center of square created by 4 intersection (using)
export function pinInCenterBlockBeam(position, verNearest, verNear2nd, horNearest, horNear2nd, listMinx, listMiny, checkNextH, checkH, checkNextV, checkV, x, y, vertical, horizontal, alphab, stt) {
  if ( verNearest.length === 2 && verNear2nd.length === 2 && horNearest.length === 2 && horNear2nd.length === 2 ) {
    if ( listMinx[0] - listMiny[0] > 0 || listMinx[0] - listMiny[0] === 0) {
      position = verNearest[0] + '-' + verNearest[1] + ',' + horNearest[0];
    } else {
      position = verNearest[0] + ',' + horNearest[0] + '-' + horNearest[1];
    }
  } else {
    if ( listMinx[0] - listMiny[0] < 0 || listMinx[0] - listMiny[0] === 0 ) {
      // eslint-disable-next-line no-mixed-operators
      if ( checkNextH === undefined || checkH === undefined && ( y - horizontal.y < 0 ) ) {
        position = '';
      } else {
        position = verNearest[0] + ',' + alphab[0] + '-' + alphab[1];
      }
    }else {
      // eslint-disable-next-line no-mixed-operators
      if ( checkNextV === undefined || checkV === undefined && ( x - vertical.x < 0 ) ) {
        position = '';
      } else {
        position = stt[0] + '-' + stt[1] + ',' + horNearest[0];
      }
    }
  }
  return position;
}

// other location of pin (using)
export function pinInOtherLocationBeam(position, x, y, vertical, horizontal, verNearest, horNearest, horNear2nd, alphab, stt) {
  if ( Math.abs(x - vertical.x) - Math.abs(y - horizontal.y) < 0 ) {
    position = verNearest[0] + ',' + alphab[0] + '-' + alphab[1];
  } else if ( Math.abs(x - vertical.x) - Math.abs(y - horizontal.y) > 0 ) {
    position = stt[0] + '-' + stt[1] + ',' + horNearest[0];
  } else {
    position = verNearest[0] + ',' + horNear2nd[0] + '-' + horNearest[0];
  }
  return position;
}

// pin in intersection of part (beam) (using)
export function pinInInterSectionBed(position, checkH, checkV, verNear2nd, verNearest, horNear2nd, horNearest) {
  if (!checkH || !checkV) {
    position = '';
  } else {
    position = verNear2nd[0] + '-' + verNearest[0] + ',' + horNear2nd[0] + '-' + horNearest[0];
  }
  return position;
}

// pin in between of 2 intersection (using)
export function pinInMiddleInlineBed(position, verNearest, verNear2nd, horNearest, horNear2nd, checkH, checkV, x, y, vertical, horizontal, alphab, stt) {
  // eslint-disable-next-line no-mixed-operators
  if ( verNearest.length === 2 && checkH === undefined || horNearest.length === 2 && checkV === undefined ) {
    position = '';
  } else if ( verNearest.length === 2 ) {
    position = verNearest[0] + '-' + verNearest[1] + ',' + horNear2nd[0] + '-' + horNearest[0];
  } else if ( horNearest.length === 2 ) {
    position = verNear2nd[0] + '-' + verNearest[0] + ',' + horNearest[0] + '-' + horNearest[1];
  } else {
    if ( Math.abs(x - vertical.x) - Math.abs(y - horizontal.y) < 0 ) {
      position = verNearest[0] + ',' + alphab[0] + '-' + alphab[1];
    } else if ( Math.abs(x - vertical.x) - Math.abs(y - horizontal.y) > 0 ) {
      position = stt[0] + '-' + stt[1] + ',' + horNearest[0];
    }
  }
  return position;
}

// pin in center of square created by 4 intersection
export function pinInCenterBlockBed(position, verNearest, verNear2nd, horNearest, horNear2nd, checkH, checkV, checkNextH, checkNextV, x, y, vertical, horizontal, alphab, stt) {
  if ( verNearest.length === 2 && verNear2nd.length === 2 && horNearest.length === 2 && horNear2nd.length === 2 ) {
    position = verNearest[0] + '-' + verNearest[1] + ',' + horNearest[0] + '-' + horNearest[1];
    // eslint-disable-next-line no-mixed-operators
  } else if ( checkH === undefined && ( y - horizontal.y < 0 ) || checkV === undefined && ( x - vertical.x < 0 ) ) {
    position = '';
  } else {
    // eslint-disable-next-line no-mixed-operators
    if ( checkNextH === undefined && ( y - horizontal.y > 0 ) || checkNextV === undefined  && ( x - vertical.x > 0 ) ) {
      position = '';
    } else {
      position = stt[0] + '-' + stt[1] + ',' + alphab[0] + '-' + alphab[1];
    }
  }
  return position
}

// pin in other location of bed (using)
export function pinInOtherLocationBed(position, verNearest, horNearest, x, y, vertical, horizontal, alphab, stt) {
  if ( Math.abs(x - vertical.x) - Math.abs(y - horizontal.y) < 0 ) {
    position = verNearest[0] + ',' + alphab[0] + '-' + alphab[1];
  } else if ( Math.abs(x - vertical.x) - Math.abs(y - horizontal.y) > 0 ) {
    position = stt[0] + '-' + stt[1] + ',' + horNearest[0];
  }
  return position;
}

// sort string with horizontal (using)
export function sortArrayStringHor(c, props) {
  let array = [];
  let result = [];
  c.forEach((e) => {
    array.push(props.project.projectDetail.guides[0].horizontal.find((h) => h.name === e));
  });
  array.sort(function(a,b) { return a.y - b.y; });
  array.forEach((e) => {
    result.push(e.name);
  })
  return result;
}

// sort array string of vertical (using)
export function sortArrayStringVer(c, props)  {
  let array = [];
  let result = [];
  c.forEach((e) => {
    array.push(props.project.projectDetail.guides[0].vertical.find((v) => v.name === e));
  });
  array.sort(function(a,b) { return a.x - b.x; });
  array.forEach((e) => {
    result.push(e.name);
  })
  return result;
}

// find prev character of horizontal (using)
export function prevLetterHor(c, props) {
  let listH = [];
  let thisHor = props.project.projectDetail.guides[0].horizontal.find((v) => v.name === c);
  props.project.projectDetail.guides[0].horizontal.forEach((e) => {
    if (thisHor.y - e.y > 0) {
      listH.push(e);
    }
  });
  if (listH.length === 0) {
    return undefined;
  } else {
    listH.sort(function(a,b) { return b.y - a.y});
    return listH[0].name;
  }
}

// find next character of vertical (using)
export function nextLetterHor(c, props) {
  let listH = [];
  let thisHor = props.project.projectDetail.guides[0].horizontal.find((v) => v.name === c);
  props.project.projectDetail.guides[0].horizontal.forEach((e) => {
    if (thisHor.y - e.y < 0) {
      listH.push(e);
    }
  });
  if (listH.length === 0) {
    return undefined;
  } else {
    listH.sort(function(a,b) { return a.y - b.y});
    return listH[0].name;
  }
}

// find prev charater of vertical (using)
export function prevLetterVer(c, props)  {
  let listV = [];
  let thisVer = props.project.projectDetail.guides[0].vertical.find((v) => v.name === c);
  props.project.projectDetail.guides[0].vertical.forEach((e) => {
    if (thisVer.x- e.x > 0) {
      listV.push(e);
    }
  });
  if (listV.length === 0) {
    return undefined;
  } else {
    listV.sort(function(a,b) { return b.x - a.x});
    return listV[0].name;
  }
}

// find next character of vertical (using)
export function nextLetterVer(c, props) {
  let listV = [];
  let thisVer = props.project.projectDetail.guides[0].vertical.find((v) => v.name === c);
  props.project.projectDetail.guides[0].vertical.forEach((e) => {
    if (thisVer.x- e.x < 0) {
      listV.push(e);
    }
  });
  if (listV.length === 0) {
    return undefined;
  } else {
    listV.sort(function(a,b) { return a.x - b.x});
    return listV[0].name;
  }
}

export function flatNode(nodes) {
  return nodes.reduce((preVal, nextItem) => {
    const {nodes} = nextItem
    if (nodes.length > 0) {
      const listNodeChild = flatNode(nodes)
      return preVal.concat(nextItem, listNodeChild)
    }
    return preVal.concat(nextItem)
  }, [])
}

export function callBackDataInSpecTable (listNode, dataInSpec) {
  for (let i = 0; i < listNode.length; i++) {
    const { id, nodes } = listNode[i]
    const treeIndex = dataInSpec.find(itemIndex => itemIndex.id === id)

    if (treeIndex) {
      listNode[i].inspectable = treeIndex.inspectable
    }
    if (nodes.length > 0) {
      listNode[i].nodes = callBackDataInSpecTable(nodes, dataInSpec)
    }
  }
  return listNode
}

export function searchTreeParent(nodeSearch, listNodes) {
  if (nodeSearch && nodeSearch.hasOwnProperty('parentId')) {
    const parentNode = listNodes.find(item => item.id === nodeSearch.parentId)
    return searchTreeParent(parentNode, listNodes)
  }
  return nodeSearch
}

export function findZMax (trees) {
  let zMax = 0
  if (trees && trees.length > 0) {
    const listTreesFlat = flatNode(trees)
    zMax = listTreesFlat.reduce((preVal, nextItem) => {
      if (nextItem.zmax && nextItem.zmax > preVal) {
        preVal = nextItem.zmax
      }
      return preVal
    }, [])
  }
  return zMax
}

// Filter Finchk Inspection
export function getShowValueFinchkInspection (icon, finchkInspectionFilter) {
  let show = true;
  if (finchkInspectionFilter.kind < 0) {
    show &= true;
  } else {
    show &= icon.valueInt6 === finchkInspectionFilter.kind;
  }
  if (finchkInspectionFilter.work < 0) {
    show &= true;
  } else if (finchkInspectionFilter.work === 0) {
    let results = (!icon.valueText9 || icon.valueText9.length === 0) ? [] : JSON.parse(icon.valueText9);
    show &= (results.length === 0 || results.every((r) => r.valueInt0 === 0));
  } else {
    let results = (!icon.valueText9 || icon.valueText9.length === 0) ? [] : JSON.parse(icon.valueText9);
    show &= (results.length > 0 && results.some((r) => r.valueInt0 === finchkInspectionFilter.work));
  }
  if (finchkInspectionFilter.result < 0) {
    show &= true;
  } else {
    show &= icon.check === finchkInspectionFilter.result;
  }
  return show;
}

// Filter Gs Inspection
export function getShowValueGsInspection(icon, gsInspectionFilterValue) {
  if (gsInspectionFilterValue < 0) {
    return true;
  } else {
    return icon.check === gsInspectionFilterValue;
  }
}


export function getIconsByCurrentMapId(visibleValue, iconsFilter, currentMapId) {
  switch (visibleValue) {
    case 'created':
      return iconsFilter.filter(e => e.mapId === currentMapId);
    case 'notCreated':
      return iconsFilter.filter(e => e.mapId !== currentMapId);
  
    // default visibleValue === 'all'
    default:
      return iconsFilter
  }
}

export function getIconsByFinchkInspectionFilter (iconsFilter, finchkInspectionFilter) {
  let icons = [...iconsFilter];
  icons.forEach(icon =>  {
    icon.show = getShowValueFinchkInspection(icon, finchkInspectionFilter);
  });
  return icons;
}

export function getIconsByGsInspectionFilter (iconsFilter, gsInspectionFilter) {
  let icons = [...iconsFilter];
  icons.forEach(icon =>  {
    icon.show = getShowValueGsInspection(icon, gsInspectionFilter.filter);
  });
  return icons;
}

export function getAllLayerId(props){
  let layers = [GUIDE_LAYER_ID, AREA_LAYER_ID];
  const {inspections, projectAcl} = props.project.projectDetail
  if (!props.project.projectDetail || !inspections.length) {
    return layers
  }
  const listLayerId = inspections.filter((inspection) => {
    return hasInspectionAcl(inspection.id, projectAcl, ["INSPECTION_VIEW", "INSPECTION_CHECK", "INSPECTION_REPORT"])
  }).map(inspection => inspection.id);
  return listLayerId.concat(layers)
}

export function getGsResult (iconResults, chkItemSelection) {
  return iconResults.reduce((preResult, nextItem) => {
    if (nextItem.valueText0 === chkItemSelection.valueText0 && nextItem.state === 1) {
      if (!preResult) {
        preResult = nextItem
      }

      if (preResult && moment(nextItem.updated).valueOf() > moment(preResult.updated).valueOf()) {
        preResult = nextItem
      }
    }
    return preResult
  }, null);
}

export function getListAndSort(listData){
  return listData.filter(item => (!item.deleteflg && !item.hide)).sort((a, b) => a.order - b.order);
}

export function getIconCheckValue(listResult) {
  //検査結果なし
  if (listResult.length === 0) {
    return 0
  } else {
    //検査状況
    let status0Count = 0    //対象外(参考項目)
    let status1Count = 0    //未確認
    let status2Count = 0    //不合格

    //削除(state:9)は対象外
    var filterdListResult = listResult.filter(r => r.state !== 9);
    filterdListResult.forEach( e => {
      if (e.valueInt1 === 0) {
        status0Count = status0Count + 1
      }
      if (e.valueInt1 === 1) {
        status1Count = status1Count + 1
      }
      if (e.valueInt1 === 2) {
        status2Count = status2Count + 1
      }
    });

    if (status2Count > 0) {
      //検査項目に不合格がある：赤ピン
      return 2;
    } else if (filterdListResult.length === (status0Count + status1Count)) {
      //全て未確認：灰色
      return 0
    } else if (status1Count > 0) {
      //未確認が存在する：黄色ピン
      return 1
    } else {
      //完了：緑色
      return 3
    }
  }
}


export const isArray = (arrayCheck) => {
  return (
      arrayCheck &&
      Object.prototype.toString.call(arrayCheck) === "[object Array]" &&
      arrayCheck.length > 0
  )
}

export const typeOfObject = (objectCheck) => {
  return Object.prototype.toString.call(objectCheck) === "[object Object]"
}

export const isObject = (objectCheck) => {
  return (
      objectCheck &&
      Object.prototype.toString.call(objectCheck) === "[object Object]" &&
      Object.keys(objectCheck).length > 0
  )
}

export const compareArray = (array1, array2) => {
  if (!array1.length && !array2.length) {
    return true
  }
  if (array1.length !== array2.length) {
    return false
  }

  let isArrayDifficult = true
  for (let i = 0; i < array1.length; i++) {
    const item = array1[i]

    if (typeOfObject(item) && !compareObject(item, array2[i])) {
      isArrayDifficult = false
      break
    }
    if (!typeOfObject(item) && item !== array2[i]) {
      isArrayDifficult = false
      break
    }
  }
  return isArrayDifficult
}

export const compareObject = (object1, object2) => {
  let isHaveValue = true
  for (let key in object1) {
    if (
        object1[key] !== object2[key]
    ) {
      isHaveValue = false
    }
  }
  return isHaveValue
}
export const handleDataSelectBox = (dataTreeSelect) => {
  const {nodes, inspectable, id} = dataTreeSelect

  let {listIdChecked, listDataTree} = flatNode(nodes).reduce((prevState, nextState) => {
    const idToString = nextState.id.toString()
    if (nextState.inspectable) {
      prevState.listIdChecked = prevState.listIdChecked.concat(idToString)
    }

    prevState.listDataTree = prevState.listDataTree.concat({
      inspectable: nextState.inspectable, id: idToString, parentId: nextState.parentId ? nextState.parentId.toString() : null
    })
    return prevState
  }, {
    listDataTree: [
      {
        inspectable,
        id: id.toString(),
        parentId: null
      }
    ],
    listIdChecked: []
  })
  if (inspectable) {
    listIdChecked = listIdChecked.concat(id.toString())
  }

  return {
    listIdChecked,
    listDataTree
  }
}