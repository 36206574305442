/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: csv.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  data: null,
  result: null
};

/**
 * project reducer
 * @param {*} state 状態
 * @param {*} action アクション
 */
export default function csv(state = initialState, action) {
  switch (action.type) {
    case types.EXPORT:
      return Object.assign({}, state, {
        data: action.data
      });
    case types.IMPORT:
      return Object.assign({}, state, {
        result: action.result
      });
   default:
      return state;
  }
}
