/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: systemSite.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  sites: null,
  selection: null,
  created: null
};

/**
 * device reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function systemSite(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_SYSTEM_SITE:
      return Object.assign({}, state, {
        sites: null,
        selection: null
      });
    case types.RECEIVE_GET_SYSTEM_SITE:
      return Object.assign({}, state, {
        sites: action.sites,
        selection: null
      });
    case types.SET_SELECT_SYSTEM_SITE:
      return Object.assign({}, state, {
        selection: action.site
      });
    case types.RECEIVE_CREATE_SYSTEM_SITE: {
      return Object.assign({}, state, {
        created: action.site
      });
    }
    case types.CLEAN_SYSTEM_SITE:
      return initialState;
    default:
      return state;
  }
}
