/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminTemplate.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  templates: null,
  selection: null,
  dirty: false,
  uploadingProgress: 0
};

/**
 * help reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminTemplate(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_TEMPLATE: {
      return Object.assign({}, state, {
        templates: null,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_GET_ADMIN_TEMPLATE:
      return Object.assign({}, state, {
        templates: action.templates,
        selection: null,
        dirty: false
      });
    case types.CREATING_ADMIN_TEMPLATE:
      return Object.assign({}, state, {
        uploadingProgress: 0
      });
    case types.UPDATING_ADMIN_TEMPLATE:
      return Object.assign({}, state, {
        uploadingProgress: 0
      });
    case types.SET_UPLOAD_ADMIN_TEMPLATE_PROGRESS: {
      return Object.assign({}, state, {
        uploadingProgress: action.progress
      });
    }
    case types.RECEIVE_CREATE_ADMIN_TEMPLATE: {
      let templates = Object.assign([], state.templates);
      templates.push(action.template);
      return Object.assign({}, state, {
        templates: templates,
        selection: null,
        dirty: false,
        uploadingProgress: 0
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_TEMPLATE: {
      let templates = Object.assign([], state.templates);
      templates.splice(templates.map(function (t) { return t.id; }).indexOf(action.template.id), 1, action.template);
      return Object.assign({}, state, {
        templates: templates,
        selection: null,
        dirty: false,
        uploadingProgress: 0
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_TEMPLATES: {
      let templates = Object.assign([], state.templates);
      action.templates.forEach(template => {
        templates.splice(templates.map(t => t.id).indexOf(template.id), 1, template);
      });
      return Object.assign({}, state, {
        templates: templates,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_DELETE_ADMIN_TEMPLATE: {
      var templates = state.templates.filter(function (t) { return t.id !== action.templateId; });
      return Object.assign({}, state, {
        templates: templates,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_ADMIN_TEMPLATE:
      return Object.assign({}, state, {
        selection: action.template,
        dirty: false
      });
    case types.SET_ADMIN_TEMPLATE_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_ADMIN_TEMPLATE:
      return initialState;
    default:
      return state;
  }
}
