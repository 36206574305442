/**
 * index.js
 * Naoaki Suganuma
 * 2017/08/16
 * ver. 1.0.0
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router-dom';
import configureStore, { history } from './store/configureStore';
import App from './app/App';
import 'rc-datetime-picker/dist/picker.min.css';
import 'react-splitter-layout/lib/index.css';
import './utils/react-splitter-layout-custom.css';
import './index.css';

/**
 * storeの作成
 */
const store = configureStore();

/**
 * Appをrootに挿入する。
 */
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history} >
      <Route path="/" component={App} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

