/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminProjectRole.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  projectRoles: null,
  selection: null,
  dirty: false
};

/**
 * adminProjectRole reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminProjectRole(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_PROJECT_ROLE:
      return Object.assign({}, state, {
        projectRoles: null,
        selection: null,
        dirty: false
      });
    case types.RECEIVE_GET_ADMIN_PROJECT_ROLE:
      return Object.assign({}, state, {
        projectRoles: action.projectRoles,
        selection: null,
        dirty: false
      });
    case types.RECEIVE_CREATE_ADMIN_PROJECT_ROLE: {
      let projectRoles = Object.assign([], state.projectRoles);
      projectRoles.push(action.projectRole);
      return Object.assign({}, state, {
        projectRoles: projectRoles,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_PROJECT_ROLE: {
      let projectRoles = Object.assign([], state.projectRoles);
      projectRoles.splice(projectRoles.map(function (r) { return r.id; }).indexOf(action.projectRole.id), 1, action.projectRole);
      return Object.assign({}, state, {
        projectRoles: projectRoles,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_DELETE_ADMIN_PROJECT_ROLE: {
      var projectRoles = state.projectRoles.filter(function (r) { return r.id !== action.rid; });
      return Object.assign({}, state, {
        projectRoles: projectRoles,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_ADMIN_PROJECT_ROLE:
      return Object.assign({}, state, {
        selection: action.projectRole,
        dirty: false
      });
    case types.SET_ADMIN_PROJECT_ROLE_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_ADMIN_PROJECT_ROLE:
      return initialState;
    default:
      return state;
  }
}
