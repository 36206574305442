/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: report.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  templates: null,
  downloads: null,
  works: null,
  icons: null,
  selection: null,
  progress: 0,
  processing: false,
};

/**
 * report reducer
 * @param {*} state 状態
 * @param {*} action アクション
 */
export default function report(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_REPORT_TEMPLATE:
      return Object.assign({}, state, {
        templates: null,
        selection: null
      });
    case types.RECEIVE_GET_REPORT_TEMPLATE:
      return Object.assign({}, state, {
        templates: action.templates
      });
    case types.GETTING_REPORT_DOWNLOAD:
      return Object.assign({}, state, {
        downloads: null
      });
    case types.RECEIVE_GET_REPORT_DOWNLOAD:
      return Object.assign({}, state, {
        downloads: action.downloads
      });
    case types.GETTING_REPORT_WORK: {
      return Object.assign({}, state, {
        works: null
      });
    }
    case types.RECEIVE_GET_REPORT_WORK: {
      return Object.assign({}, state, {
        works: action.works
      });
    }
    case types.GETTING_REPORT_ICON: {
      return Object.assign({}, state, {
        icons: null
      });
    }
    case types.RECEIVE_GET_REPORT_ICON: {
      let icons = action.icons.map((ic) => {
        ic.areaPointList = areaPointList(ic);
        return ic;
      });
      return Object.assign({}, state, {
        icons: icons
      });
    }
    case types.CREATING_REPORT: {
      return Object.assign({}, state, {
        processing: true,
        progress: 0
      });
    }
    case types.RECEIVE_CREATE_REPORT: {
      return Object.assign({}, state, {
        progress: 100,
        processing: false
      });
    }
    case types.SET_CREATE_REPORT_PROGRESS: {
      return Object.assign({}, state, {
        progress: action.progress
      });
    }
    case types.RECEIVE_DELETE_REPORT_DOWNLOAD: {
      var downloads = state.downloads.filter(function (d) { return d.id !== action.downloadId; });
      return Object.assign({}, state, {
        downloads: downloads
      });
    }
    case types.SET_SELECT_REPORT_TEMPLATE:
      return Object.assign({}, state, {
        selection: action.selection
      });
    case types.SET_INSPECTION:
      return Object.assign({}, state, {
        selection: null
      });      
    case types.ERR_API:
    case types.ERR_FINISH:
      return Object.assign({}, state, {
        processing: false,
        progress: 0,
      });
    case types.RECEIVE_LOGOUT:
    case types.INIT_AUTH:
    case types.CLEAN_REPORT:
      return initialState;
    default:
      return state;
  }
}

/**
 * POLYGON形状のアイコンについて頂点リストをJsonから配列に変換する。
 * @param {*} icon 
 */
function areaPointList(icon) {
  if (icon.area) {
    let area = JSON.parse(icon.area);
    return area.shape;
  } else {
    return [];
  }
}