/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: notifications.js
 * Author: Naoaki Suganuma
 * Update: 2020/10/01
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  enable: false,
  open: false,
  progress: null,
};

/**
 * project reducer
 * @param {*} state 状態
 * @param {*} action アクション
 */
export default function notifications(state = initialState, action) {
  switch (action.type) {
    case types.CLOSE_NOTIFICATION:
      return Object.assign({}, state, {
        open: false
      });
    case types.SET_PROJECT:
    case types.GETTING_PROJECT_DETAIL:
      return Object.assign({}, state, {
        enable: true,
        open: false
      });
    case types.RECEIVE_LOGOUT:
    case types.INIT_AUTH:
    case types.CLEAN_PROJECT:
      return Object.assign({}, state, {
        enable: false,
        open: false
      });
    case types.GETTING_PROGRESS:
      return Object.assign({}, state, {
        open: true
      });
    case types.RECEIVE_GET_PROGRESS: {
      let progress = Object.assign([], state.progress ? state.progress.filter((p) => action.progress.some((ap) => ap.key === p.key)) : []);
      action.progress.forEach((ap) => {
        let p = progress.find((sp) => sp.key === ap.key);
        if (p) {
          p = Object.assign(p, ap);
        } else {
          progress.unshift(ap);
        }
      });
      return Object.assign({}, state, {
        progress: progress,
      });
    }
    case types.RECEIVE_CANCEL_PROGRESS: {
      let progress = Object.assign([], state.progress);
      let p = progress.find((p) => p.key === action.progressKey);
      if (p) {
        p.cancel = true;
      }
      return Object.assign({}, state, {
        progress: progress,
      });
    }
    case types.RECEIVE_DELETE_PROGRESS: {
      var progress = state.progress.filter(function (p) { return p.key !== action.progressKey; });
      return Object.assign({}, state, {
        progress: progress,
      });
    }
   default:
      return state;
  }
}
