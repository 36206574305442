/**
* errorReducer.js
* Kazuo Yoshida
* 2017/09/12
* ver. 1.0.0
*/
import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
    error: false,
    statusCode: 0,
    message: null,
    needsLogin: false,
};

/**
 * エラーreducer
 * @param {*} state 状態
 * @param {*} action アクション
 */
export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case types.ERR_API:
      return Object.assign({}, state, {
        error: true,
        statusCode: action.statusCode,
        message: action.message,
        needsLogin: action.statusCode === 401,
      });
    case types.RECEIVE_LOGOUT:
    case types.INIT_AUTH:
    case types.ERR_FINISH:
      return initialState;
    default:
      return state;
  }
}