/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: systemMail.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  preferences: null,
  sendgridDirty: false,
  systemUserDirty: false,
  projectStateDirty: false,
  projectUserDirty: false,
  projectActionDirty: false,
  userDirty: false,
  mailSent: false
};

/**
 * user reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function systemMail(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_SYSTEM_MAIL: {
      return Object.assign({}, state, {
        preferences: null,
        sendgridDirty: false,
        systemUserDirty: false,
        projectStateDirty: false,
        projectUserDirty: false,
        projectActionDirty: false,
        userDirty: false,
      });
    }
    case types.RECEIVE_GET_SYSTEM_MAIL:
      return Object.assign({}, state, {
        preferences: action.preferences,
        sendgridDirty: false,
        systemUserDirty: false,
        projectStateDirty: false,
        projectUserDirty: false,
        projectActionDirty: false,
        userDirty: false,
      });
    case types.RECEIVE_UPDATE_SYSTEM_MAIL: {
      let preferences = Object.assign([], state.preferences);
      action.preferences.forEach(function (p) {
        let found = preferences.find(function (pref) {
          return p.key === pref.key;
        });
        if (found) {
          found.value = p.value;
        }
      });
      if (action.category === "sendgrid") {
        return Object.assign({}, state, {
          preferences: preferences,
          sendgridDirty: false
        });
      } else if (action.category === "systemUser") {
        return Object.assign({}, state, {
          preferences: preferences,
          systemUserDirty: false
        });
      } else if (action.category === "projectState") {
        return Object.assign({}, state, {
          preferences: preferences,
          projectStateDirty: false
        });
      } else if (action.category === "projectUser") {
        return Object.assign({}, state, {
          preferences: preferences,
          projectUserDirty: false
        });
      } else if (action.category === "projectAction") {
        return Object.assign({}, state, {
          preferences: preferences,
          projectActionDirty: false
        });
      } else if (action.category === "user") {
        return Object.assign({}, state, {
          preferences: preferences,
          userDirty: false
        });
      }
    }
    break;
    case types.SET_SYSTEM_MAIL_DIRTY:
      if (action.category === "sendgrid") {
        return Object.assign({}, state, {
          sendgridDirty: true
        });
      } else if (action.category === "systemUser") {
        return Object.assign({}, state, {
          systemUserDirty: true
        });
      } else if (action.category === "projectState") {
        return Object.assign({}, state, {
          projectStateDirty: true
        });
      } else if (action.category === "projectUser") {
        return Object.assign({}, state, {
          projectUserDirty: true
        });
      } else if (action.category === "projectAction") {
        return Object.assign({}, state, {
          projectActionDirty: true
        });
      } else if (action.category === "user") {
        return Object.assign({}, state, {
          userDirty: true
        });
      }
      return initialState;
    case types.CLEAN_SYSTEM_MAIL:
      return initialState;
    case types.SENDING_TEST_MAIL: {
      return Object.assign({}, state, {
        mailSent: false,
      });
    }
    case types.RECEIVE_SEND_TEST_MAIL: {
      return Object.assign({}, state, {
        mailSent: action.mailSent,
      });
    }
    default:
      return state;
  }
}
