/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminGuide.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  guideDetail: null,
  dirty: false
};

/**
 * adminGuide reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminGuide(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVE_GET_ADMIN_GUIDE:
      return Object.assign({}, state, {
        guideDetail: action.guideDetail,
        dirty: false
      });
    case types.RECEIVE_UPDATE_ADMIN_GUIDE:
      return Object.assign({}, state, {
        guideDetail: action.guide,
        dirty: false
      });
    case types.CREATE_HORIZONTAL_ADMIN_GUIDE: {
      let detail = Object.assign({}, state.guideDetail);
      detail.horizontal.push(action.guide);
      return Object.assign({}, state, {
        guideDetail: detail,
        dirty: true
      });
    }
    case types.CREATE_VERTICAL_ADMIN_GUIDE: {
      let detail = Object.assign({}, state.guideDetail);
      detail.vertical.push(action.guide);
      return Object.assign({}, state, {
        guideDetail: detail,
        dirty: true
      });
    }
    case types.CREATE_DIAGONAL_ADMIN_GUIDE: {
      let detail = Object.assign({}, state.guideDetail);
      detail.diagonal.push(action.guide);
      return Object.assign({}, state, {
        guideDetail: detail,
        dirty: true
      });
    }
    case types.UPDATE_HORIZONTAL_ADMIN_GUIDE: {
      let detail = Object.assign({}, state.guideDetail);
      detail.horizontal.splice(detail.horizontal.map(function (g) { return g.id; }).indexOf(action.gid), 1, action.guide);
      return Object.assign({}, state, {
        guideDetail: detail,
        dirty: true
      });
    }
    case types.UPDATE_VERTICAL_ADMIN_GUIDE: {
      let detail = Object.assign({}, state.guideDetail);
      detail.vertical.splice(detail.vertical.map(function (g) { return g.id; }).indexOf(action.gid), 1, action.guide);
      return Object.assign({}, state, {
        guideDetail: detail,
        dirty: true
      });
    }
    case types.UPDATE_DIAGONAL_ADMIN_GUIDE: {
      let detail = Object.assign({}, state.guideDetail);
      detail.diagonal.splice(detail.diagonal.map(function (g) { return g.id; }).indexOf(action.gid), 1, action.guide);
      return Object.assign({}, state, {
        guideDetail: detail,
        dirty: true
      });
    }      
    case types.DELETE_HORIZONTAL_ADMIN_GUIDE: {
      let detail = Object.assign({}, state.guideDetail);
      var newHorizontal = detail.horizontal.filter(function (g) { return g.id !== action.gid; });
      detail.horizontal = newHorizontal;
      return Object.assign({}, state, {
        guideDetail: detail,
        dirty: true
      });
    }
    case types.DELETE_VERTICAL_ADMIN_GUIDE: {
      let detail = Object.assign({}, state.guideDetail);
      var newVertical = detail.vertical.filter(function (g) { return g.id !== action.gid; });
      detail.vertical = newVertical;
      return Object.assign({}, state, {
        guideDetail: detail,
        dirty: true
      });
    }
    case types.DELETE_DIAGONAL_ADMIN_GUIDE: {
      let detail = Object.assign({}, state.guideDetail);
      var newDiagonal = detail.diagonal.filter(function (g) { return g.id !== action.gid; });
      detail.diagonal = newDiagonal;
      return Object.assign({}, state, {
        guideDetail: detail,
        dirty: true
      });
    }
    case types.UPDATE_ADMIN_GUIDE_NAME: {
      let detail = Object.assign({}, state.guideDetail);
      detail.name = action.name;
      return Object.assign({}, state, {
        guideDetail: detail,
        dirty: true
      });
    }
    case types.UPDATE_HORIZONTAL_ADMIN_GUIDE_LIST: {
      let detail = Object.assign({}, state.guideDetail);
      detail.horizontal = action.guides;
      return Object.assign({}, state, {
        floorDetail: detail,
        dirty: true
      });
    }
    case types.UPDATE_VERTICAL_ADMIN_GUIDE_LIST: {
      let detail = Object.assign({}, state.guideDetail);
      detail.vertical = action.guides;
      return Object.assign({}, state, {
        floorDetail: detail,
        dirty: true
      });
    }
    case types.UPDATE_DIAGONAL_ADMIN_GUIDE_LIST: {
      let detail = Object.assign({}, state.guideDetail);
      detail.diagonal = action.guides;
      return Object.assign({}, state, {
        floorDetail: detail,
        dirty: true
      });
    }
    case types.CLEAN_ADMIN_GUIDE:
      return initialState;
    default:
      return state;
  }
}