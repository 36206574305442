/**
 * auth.js
 * Naoaki Suganuma
 * 2017/08/16
 * ver. 1.0.0
 */
import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  from: null,
  isLoggedIn: false,
  username: null,
  user: null,
  token: null,
  systemAcl: null,
  contentsSasToken: null,
  helps: null,
  needsLogin: false,
  signupComplete: false,
  signupError: null,
  registerComplete: false,
  registerationError: null,
  passwordComplete: false,
  passwordError: null,
  setPasswordComplete: false,
  setPasswordError: null
};

/**
 * auth reducer
 * @param {*} state 状態
 * @param {*} action アクション
 */
export default function auth(state = initialState, action) {
  switch (action.type) {
    case types.SET_FROM:
      return Object.assign({}, state, {
        from: action.from
      });
    case types.RECEIVE_LOGIN:
      if (action.token) {
        window.localStorage.setItem("token", action.token);
      }
      return Object.assign({}, state, {
        isLoggedIn: true,
        username: action.username,
        user: action.user,
        token: action.token,
        systemAcl: action.systemAcl,
        contentsSasToken: action.sasToken,
        helps: action.helps,
        authError: false
      });
    case types.RECEIVE_LOGIN_ERROR:
      window.localStorage.removeItem("token");
      return Object.assign({}, state, {
        isLoggedIn: false,
        username: null,
        user: null,
        token: null,
        systemAcl: null,
        authError: true
      });
    case types.RECEIVE_LOGOUT:
    case types.INIT_AUTH:
      window.localStorage.removeItem("token");
      return initialState;
    case types.RECEIVE_SIGNUP:
      return Object.assign({}, state, {
        signupComplete: true,
        signupError: null
      });
    case types.RECEIVE_SIGNUP_ERROR:
      return Object.assign({}, state, {
        signupComplete: false,
        signupError: action.signupError
      });
    case types.CLEAR_SIGN_UP:
      return Object.assign({}, state, {
        signupComplete: false,
        signupError: null
      });  
    case types.RECEIVE_REGISTER:
      return Object.assign({}, state, {
        registerComplete: true,
        registerationError: null
      });
    case types.RECEIVE_REGISTER_ERROR:
      return Object.assign({}, state, {
        registerComplete: false,
        registerationError: action.registerationError
      });
    case types.RECEIVE_PASSWORD:
      return Object.assign({}, state, {
        passwordComplete: true,
        passwordError: null
      });
    case types.RECEIVE_PASSWORD_ERROR:
      return Object.assign({}, state, {
        passwordComplete: false,
        passwordError: action.passwordError
      });
    case types.RECEIVE_SET_PASSWORD:
      return Object.assign({}, state, {
        setPasswordComplete: true,
        setPasswordError: null
      });
    case types.RECEIVE_SET_PASSWORD_ERROR:
      return Object.assign({}, state, {
        setPasswordComplete: false,
        setPasswordError: action.setPasswordError
      });
    default:
      return state;
  }
}