/**
 * adminResFile.js
 * Naoaki Suganuma
 * 2020/9/30
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  resFiles: null,
  selection: null,
  processing: false,
  progressMessage: "",
  progress: 0,
};

/**
 * adminResFile reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminResFile(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_RESFILE:
      return Object.assign({}, state, {
        resFiles: null,
      });
    case types.RECEIVE_GET_ADMIN_RESFILE:
      return Object.assign({}, state, {
        resFiles: action.resFiles,
      });
    case types.CREATING_ADMIN_RESFILE:
      return Object.assign({}, state, {
        processing: true,
        progressMessage: "ファイルをアップロードしています...",
        progress: 0,
      });
    case types.SET_UPLOAD_ADMIN_RESFILE_PROGRESS:
      return Object.assign({}, state, {
        progressMessage: action.filename + "をアップロードしています...",
        progress: 0 + 20 * (action.progress / 100),
      });
    case types.SET_UPLOAD_ADMIN_RESFILE_COMPLETE:
      return Object.assign({}, state, {
        progressMessage: "資料の変換・登録処理を起動しています...",
        progress: 20,
      });
    case types.SET_PROCESS_ADMIN_RESFILE_PROGRESS:
      return Object.assign({}, state, {
        progressMessage: "資料の変換・登録を行っています...",
        progress: 20 + ((100 - 20) * action.progress / 100),
      });
    case types.SET_PROCESS_ADMIN_RESFILE_COMPLETE:
      return Object.assign({}, state, {
        processing: false,
        progressMessage: "",
        progress: 100,
      });
    case types.RECEIVE_CREATE_ADMIN_RESFILE:
    case types.ERR_API:
    case types.ERR_FINISH:
      return Object.assign({}, state, {
        processing: false,
        progressMessage: "",
        progress: 0,
      });
    case types.RECEIVE_DELETE_ADMIN_RESFILE: {
      var resFiles = state.resFiles.filter(function (mf) { return mf.id !== action.mfid; });
      return Object.assign({}, state, {
        resFiles: resFiles,
        selection: null
      });
    }
    case types.UPLOADING_ADMIN_RESFILE: {
      return Object.assign({}, state, {
        uploadingFiles: action.uploadingFiles,
        isUploading: true,
        uploadingProgress: 0
      });
    }
    case types.SET_SELECT_ADMIN_RESFILE:
      return Object.assign({}, state, {
        selection: action.resFile,
      });
    case types.CLEAN_ADMIN_RESFILE:
      return initialState;
    default:
      return state;
  }
}
