/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: TextFieldValidator.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React from 'react';
import TextField from '@material-ui/core/TextField';
import ValidatorComponent from './ValidatorComponent';

/**
 * TextFieldValidatorコンポーネント
 */
export default class TextFieldValidator extends ValidatorComponent {
  /**
   * レンダリング
   */
  messageError = () => {
    const { isValid } = this.state;
    const { errorText, isValidCustom, validCustomMess } = this.props;
    let message = errorText
    if (!isValid) {
        message = this.getErrorMessage()
    }
    if (isValid && isValidCustom) {
        message = validCustomMess
    }
    return message
  }

  render() {
    const { errorMessages, validators, isValidCustom, requiredError, errorText, validatorListener, textFieldClass, ...rest } = this.props;
    const { isValid } = this.state;
    return (
      <TextField
        {...rest}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          classes: {
            root: textFieldClass,
          },
        }}        
        error={!isValid || isValidCustom}
        helperText={this.messageError()}
        // helperText={(!isValid && this.getErrorMessage()) || errorText}
      />
    );
  }
}