/**
 *
 * File: adminInspectionMap.js
 * Author: Toan Nguyen
 * Update: 2020/7/21
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  activeScreen: 1,
  isSelectedHaveTitle: false,
  mapInfo: null,
  mapUrl: null,
  mapImageData: null,
  originalCanvasSize: null,
  selectedAxis: 0,
  detectedLines: null,
  scaledLines: null,
  movedLines: null,
  selectedLines: null,
  titleBorderLines: null,
  currentPosition: null,
  drawingData: null,
  crossSectionList: [],
  density: 0.2
};

/**
 * map reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminInspectionMap(state = initialState, action) {
    switch (action.type) {
      case types.SET_ACTIVE_SCREEN_MAP: {
        return Object.assign({}, state, {
          activeScreen: action.activeScreen
        });
      }
      case types.SET_MAP_BASE_URL: {
        return Object.assign({}, state, {
          mapUrl: action.mapUrl,
          detectedLines: null,
          density: 0.2
        });
      }
      case types.SET_CURRENT_MAP_PATH: {
        return Object.assign({}, state, {
          mapInfo: {...action.mapPath}
        });
      }
      case types.SET_SELECTED_AXIS: {
        return Object.assign({}, state, {
          selectedAxis: action.selectedAxis
        });
      }
      case types.SET_MAP_IMAGE_DATA: {
        return Object.assign({}, state, {
          mapImageData: action.mapImageData,
          originalCanvasSize: action.originalCanvasSize
        });
      }
      case types.SET_DETECTED_LINE_DATA: {
        return Object.assign({}, state, {
          detectedLines: action.detectedLines
        });
      }
      case types.SET_DENSITY_DATA: {
        return Object.assign({}, state, {
          density: action.density
        });
      }
      case types.SET_SCALED_LINE_DATA: {
        return Object.assign({}, state, {
          scaledLines: action.scaledLines
        });
      }
      case types.SET_SELECTED_LINE_DATA: {
        return Object.assign({}, state, {
          selectedLines: action.selectedLines
        });
      }
      case types.SET_SELECTED_HAVE_TITLE: {
        return Object.assign({}, state, {
          isSelectedHaveTitle: action.isSelectedHaveTitle
        });
      }
      case types.SET_MOVED_LINE_DATA: {
        return Object.assign({}, state, {
          movedLines: action.movedLines
        });
      }
      case types.SET_TITLE_BORDERS: {
        return Object.assign({}, state, {
          titleBorderLines: action.titleBorderLines
        });
      }
      case types.SET_CURRENT_CLICKED_POSITION: {
        return Object.assign({}, state, {
          currentPosition: action.currentPosition
        });
      }
      case types.SET_DRAWING_DATA: {
        return Object.assign({}, state, {
          drawingData: action.drawingData
        });
      }
      case types.SAVE_CROSS_SECTION_DATA: {
        return Object.assign({}, state, {
          crossSectionList: action.crossSectionList
        })
      }
      case types.CLEAR_MAP_IMAGE_DATA: {
        return Object.assign({}, state, {
          mapImageData: null,
          originalCanvasSize: null,
        })
      }
      case types.CLEAN_ADMIN_INSPECTION_MAP: {
        return initialState;
      }
      default:
        return state;
    }
}