/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: acl.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

/**
  * いずれかのシステム関連権限を持っているかを取得する。
  * @param {object} systemAcl System ACL
  */
export function hasAnySystemAcl(systemAcl) {
  return systemAcl &&
    systemAcl.some(a =>
    (a.operation === "SYSTEM_USER" ||
      a.operation === "SYSTEM_PROJECT" ||
      a.operation === "SYSTEM_INSPECTION_MODEL" ||
      a.operation === "SYSTEM_ROLE" ||
      a.operation === "SYSTEM_MAIL" ||
      a.operation === "SYSTEM_HELP") && a.permission === 1);
}

/** 
 * 指定したシステム権限を持っているかどうかを取得する。 
  * @param {object} systemAcl System ACL
  * @param {array} aclList 検査するACL一覧
 */
export function hasSystemAcl(systemAcl, aclList) {
  return systemAcl &&
    systemAcl.filter(
      a => aclList.some(acl => acl === a.operation)
    ).some(
      a => a.permission === 1
    );
}

/**
  * いずれかのプロジェクト管理権限を持っているかを取得する。
  * @param {object} projectAcl プロジェクト ACL
  */
export function hasAnyProjectAdminAcl(projectAcl) {
  return projectAcl && projectAcl.project && projectAcl.project.acl &&
    projectAcl.project.acl.some(a =>
    (a.operation === "PROJECT_INFO" ||
      a.operation === "PROJECT_GUIDE" ||
      a.operation === "PROJECT_FLOOR" ||
      a.operation === "PROJECT_LPROPERTY" ||
      a.operation === "PROJECT_MAPFILE" ||
      a.operation === "PROJECT_MAP" ||
      a.operation === "PROJECT_TREE" ||
      a.operation === "PROJECT_TEMPLATE" ||
      a.operation === "PROJECT_INSPECTION" ||
      a.operation === "PROJECT_USER" ||
      a.operation === "PROJECT_ORGANIZATION" ||
      a.operation === "PROJECT_WORK" ||
      a.operation === "PROJECT_WORKGROUP" ||
      a.operation === "PROJECT_ROLE" ||
      a.operation === "PROJECT_INSPECTION_ROLE" ||
      a.operation === "PROJECT_AREA" ||
      a.operation === "PROJECT_DEVICE") && a.permission === 1);
}

/** 
 * 指定したプロジェクト権限を持っているかどうかを取得する。 
  * @param {object} projectAcl プロジェクト ACL
  * @param {array} aclList 検査するACL一覧
 */
export function hasProjectAcl(projectAcl, aclList) {
  return projectAcl && projectAcl.project && projectAcl.project.acl &&
    projectAcl.project.acl.filter(
      a => aclList.some(acl => acl === a.operation)
    ).some(
      a => a.permission === 1
    );
}

/** 
 * 指定した検査権限を持っているかどうかを取得する。 
  * @param {object} projectAcl プロジェクト ACL
  * @param {array} aclList 検査するACL一覧
 */
export function hasInspectionAcl(inspectionId, projectAcl, aclList) {
  if (projectAcl && projectAcl.inspections) {
    let inspectionAcl = projectAcl.inspections.find((ins) => { return ins.inspectionId === inspectionId; });
    if (inspectionAcl && inspectionAcl.acl) {
      return inspectionAcl.acl.filter(
        a => aclList.some(acl => acl === a.operation)
      ).some(
        a => a.permission === 1
      );
    } 
  }
  return false;
}

/** 
 * ドキュメント関連権限を持っているかどうかを取得する。 
  * @param {object} systemAcl System ACL
 */
export function hasDocumentAcl(systemAcl) {
  return hasSystemAcl(systemAcl, 
    [ 
      "SYSTEM_DEVELOPER_VIEW_DOC", 
      "SYSTEM_DEVELOPER_CREATE_DOC", 
      "SYSTEM_DEVELOPER_APPROVE_DOC"
    ]
  );
}

/**
 * 有効な認証トークンを保持しているかどうかを確認する。
 * @param {object} auth 認証情報 
 */
export function hasValidToken(auth) {
  let token = auth.token ? auth.token : window.localStorage.getItem("token");
  if (token) {
    let ary = token.trim().split(".");
    let json = JSON.parse(window.atob(ary[1]));
    let now = new Date();
    if (Math.floor(now.getTime() / 1000) <= json.exp) {
      return true;
    }
    return false;
  }
}
