/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminDevice.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  devices: null,
  selection: null
};

/**
 * device reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminDevice(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_DEVICE:
      return Object.assign({}, state, {
        devices: null,
        selection: null
      });
    case types.RECEIVE_GET_ADMIN_DEVICE:
      return Object.assign({}, state, {
        devices: action.devices,
        selection: null
      });
    case types.RECEIVE_DELETE_ADMIN_DEVICE: {
      var devices = state.devices.filter(function (d) { return d.id !== action.did; });
      return Object.assign({}, state, {
        devices: devices,
        selection: null
      });
    }
    case types.SET_SELECT_ADMIN_DEVICE:
      return Object.assign({}, state, {
        selection: action.device
      });
    case types.CLEAN_ADMIN_DEVICE:
      return initialState;
    default:
      return state;
  }
}
