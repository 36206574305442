/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminOrganization.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  organizations: null,
  workGroups: null,
  selection: null,
  dirty: false
};

/**
 * user reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminOrganization(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_ORGANIZATION: {
      return Object.assign({}, state, {
        organizations: null,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_GET_ADMIN_ORGANIZATION:
      return Object.assign({}, state, {
        organizations: action.organizations,
        selection: null,
        dirty: false
      });
    case types.GETTING_ADMIN_WORKGROUP: {
      return Object.assign({}, state, {
        workGroups: null,
      });
    }
    case types.RECEIVE_GET_ADMIN_WORKGROUP:
      return Object.assign({}, state, {
        workGroups: action.workGroups
      });
    case types.RECEIVE_CREATE_ADMIN_ORGANIZATION: {
      let organizations = Object.assign([], state.organizations);
      organizations.push(action.organization);
      return Object.assign({}, state, {
        organizations: organizations,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_ORGANIZATION: {
      let organizations = Object.assign([], state.organizations);
      organizations.splice(organizations.map(function (o) { return o.id; }).indexOf(action.organization.id), 1, action.organization);
      return Object.assign({}, state, {
        organizations: organizations,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_ORGANIZATIONS: {
      let organizations = Object.assign([], state.organizations);
      action.organizations.forEach(org => {
        organizations.splice(organizations.map(o => o.id).indexOf(org.id), 1, org);
      });
      return Object.assign({}, state, {
        organizations: organizations,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_DELETE_ADMIN_ORGANIZATION: {
      var organizations = state.organizations.filter(function (o) { return o.id !== action.oid; });
      return Object.assign({}, state, {
        organizations: organizations,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_ADMIN_ORGANIZATION:
      return Object.assign({}, state, {
        selection: action.organization,
        dirty: false
      });
    case types.SET_ADMIN_ORGANIZATION_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_ADMIN_ORGANIZATION:
      return initialState;
    default:
      return state;
  }
}