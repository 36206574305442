/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: map.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

/* eslint-disable eqeqeq */

import * as types from '../constants/ActionTypes';
import _ from 'lodash'
import {
  filterIconsDependOnAreaWithPart,
} from '../utils/mapUtils';
/**
 * 初期状態
 */
const initialState = {
  treeId: null,
  nodeId: null,
  mapId: null,
  // filter
  partId: 0,
  areaId: -1,
  visibleValue: 'all',
  inspectionId: null,
  inspectionType: null,
  finchkInspectionFilter: {
    kind: -1,
    work: -1,
    result: -1
  },
  gsInspectionFilter: {
    filter: -1
  },
  // 
  icons: [],
  iconsFilter: [],
  detailIcons: [],
  layers: [],
  iconColor: "inspection",
  iconSize: "m",
  nearestCrossSection: null,
  needsIconReload: false,
  initialHistory: [],
  historyBackup: [],
  backupSelecIcon: [],
  opacityCaption: 0.8,
  overlay: false,
  csvPoint: [],
  csvPointError: { error: false, errorRow: [] },
  tempCreateIcons: [],
  areaTop: false,
  isReady: false,
  isReloadPinArea: false
};

/**
 * project reducer
 * @param {*} state 状態
 * @param {*} action アクション
 */
export default function map(state = initialState, action) {
  switch (action.type) {
    case types.SET_PROJECT:
      return Object.assign({}, state, {
        treeId: null,
        nodeId: null,
        mapId: null,
        areaId: -1,
        icons: [],
        iconsFilter: [],
        detailIcons: [],
        layers: [],
        iconColor: "inspection",
        iconSize: "m",
        nearestCrossSection: null
      });
    case types.SET_INSPECTION:
      return Object.assign({}, state, {
        areaId: -1,
        inspectionId: action.inspectionId,
        areaTop: false
      });
    case types.RECEIVE_LOGOUT:
    case types.INIT_AUTH:
    case types.CLEAN_MAP:
      return initialState;
    case types.SET_TREE:
      return Object.assign({}, state, {
        treeId: action.treeId,
        nodeId: null,
        mapId: null,
        areaId: -1,
        icons: [],
      });
    case types.SET_NODE:
      return Object.assign({}, state, {
        nodeId: action.nodeId,
        mapId: null,
        areaId: -1,
        partId: 0,
        inspectionId: state.inspectionId,
      });
    case types.SET_MAP: {
      let icons = action.icons.map((ic) => {
        ic.areaPointList = areaPointList(ic);
        return ic;
      });

      let updateState = Object.assign({}, state, {
        mapId: action.mapId,
        areaId: -1,
        partId: 0,
        inspectionId: state.inspectionId,
        icons: icons,
        needsIconReload: false,
        initialHistory: _.cloneDeep(icons),
        historyBackup: [],
        backupSelecIcon: []
      })

      updateState.iconsFilter = filterIconsDependOnAreaWithPart(
        {
          icons: icons,
          areaId: state.areaId,
          partId: state.partId,
          inspectionId: state.inspectionId,
          state: {...updateState}
        }
      )

      return updateState;
    }
    case types.RECEIVE_ICON_DETAIL: {
      let iconIndex = state.icons.findIndex((e) => e.id === action.iconId);
      let icons = [...state.icons];
      let newIcon = action.iconDetail;
      newIcon.areaPointList = areaPointList(newIcon);
      icons[iconIndex] = newIcon;

      const iconsFilter = filterIconsDependOnAreaWithPart(
          {
            icons: icons,
            areaId: state.areaId,
            partId: state.partId,
            inspectionId: state.inspectionId,
            state: state
          }
        )
      return Object.assign({}, state, {
        icons,
        iconsFilter: iconsFilter,
        isReloadPinArea: !state.isReloadPinArea
      })
    }
    case types.RECEIVE_ICON_DETAIL_LIST: {
      return Object.assign({}, state, {
        detailIcons: action.iconDetailList
      })
    }
    case types.RECEIVE_CREATE_ICON: {
      action.icon.areaPointList = areaPointList(action.icon);

      let icons = Object.assign([], state.icons);
      icons.push(action.icon);

      let iconsFilter = Object.assign([], state.iconsFilter);
      iconsFilter.push(action.icon);

      return Object.assign({}, state, {
        icons: icons,
        iconsFilter: iconsFilter
      });
    }
    case types.RECEIVE_CREATE_ICONS: {
      let created = action.icons.map((ic) => {
        ic.areaPointList = areaPointList(ic);
        return ic;
      });
      let icons = Object.assign([], state.icons);
      let iconsFilter = Object.assign([], state.iconsFilter);
      created.forEach(ic => {
        icons.push(ic);
      });
      iconsFilter = [...iconsFilter, ...created];
      return Object.assign({}, state, {
        icons: icons,
        iconsFilter: iconsFilter,
        initialHistory: _.cloneDeep(iconsFilter),
        tempCreateIcons: created
      });
    }
    case types.RECEIVE_UPDATE_ICON: {
      if (state.icons.some(ic => ic.id === action.icon.id)) {
        action.icon.areaPointList = areaPointList(action.icon);
        let icons = Object.assign([], state.icons);
        icons.splice(icons.map(function (icon) {
          return icon.id;
        }).indexOf(action.icon.id), 1, action.icon);

        const iconsFilter = filterIconsDependOnAreaWithPart(
            {
              icons: icons,
              areaId: state.areaId,
              partId: state.partId,
              inspectionId: state.inspectionId,
              state: state
            }
          )
        return Object.assign({}, state, {
          icons: icons,
          iconsFilter: iconsFilter,
          tempCreateIcons: [],
          isReloadPinArea: !state.isReloadPinArea
        });
      } else {
        return state;
      }
    }
    case types.RECEIVE_UPDATE_ICONS: {
      let updated = action.icons.map((ic) => {
        ic.areaPointList = areaPointList(ic);
        return ic;
      });

      let icons = Object.assign([], state.icons);
      updated.forEach(ic => {
        icons.splice(icons.map(function (icon) {
          return icon.id;
        }).indexOf(ic.id), 1, ic);
      });

      const iconsFilter = filterIconsDependOnAreaWithPart(
          {
            icons: icons,
            areaId: state.areaId,
            partId: state.partId,
            inspectionId: state.inspectionId,
            state: state
          }
        )
      return {
        ...state,
        icons: icons,
        iconsFilter: iconsFilter,
        backupSelecIcon: action.icons,
        tempCreateIcons: [],
        isReloadPinArea: !state.isReloadPinArea
      };
    }
    case types.SET_LAYER:
      return Object.assign({}, state, {
        layers: action.layers
      });
    case types.SET_AREA:
      return Object.assign({}, state, {
        areaId: action.areaId
      });
    case types.SET_VISIBLE:
      return Object.assign({}, state, {
        visibleValue: action.visibleValue
      });
    case types.SET_ICON_COLOR:
      return Object.assign({}, state, {
        iconColor: action.iconColor
      });
    case types.SET_ICON_SIZE:
      return Object.assign({}, state, {
        iconSize: action.iconSize
      });
    case types.SET_ICONS: {
      let icons = action.icons.map((ic) => {
        ic.areaPointList = areaPointList(ic);
        return ic;
      });

      const iconsFilter = filterIconsDependOnAreaWithPart(
          {
            icons: icons,
            areaId: state.areaId,
            partId: state.partId,
            inspectionId: state.inspectionId,
            state: state
          }
        )

      return Object.assign({}, state, {
        icons: icons,
        iconsFilter: iconsFilter
      });
    }
    case types.FILTER_ICONS_WITH_PARTS: {
      const areaId = action.areaId === null ? state.areaId : action.areaId;
      const partId = action.partId === null ? state.partId : action.partId;
      const inspectionId = action.inspectionId || state.inspectionId || null;

      const iconsFilter = filterIconsDependOnAreaWithPart(
          {
            icons: state.icons,
            areaId: areaId,
            partId: partId,
            inspectionId: inspectionId,
            state: state
          }
        )

      return Object.assign({}, state, {
        ...state,
        iconsFilter: iconsFilter,
        areaId: areaId,
        partId: partId,
        inspectionId: inspectionId,
      });
    }
    case types.RECEIVE_UPPER_LOWER_ICONS: {
      return Object.assign({}, state, {
        nearestCrossSection: action.icons,
      });
    }
    case types.CLEAR_UPPER_LOWER_ICONS: {
      return Object.assign({}, state, {
        nearestCrossSection: null,
      });
    }
    case types.SET_UPPER_LOWER_ICONS: {
      return Object.assign({}, state, {
        nearestCrossSection: action.nearestCrossSection,
      });
    }
    case types.RECEIVE_CREATE_ADMIN_AREA:
    case types.RECEIVE_UPDATE_ADMIN_AREA:
    case types.DELETING_ADMIN_AREA:
    case types.IMPORT:
      return Object.assign({}, state, {
        needsIconReload: true
      });
    case types.RESET_HISTORY:
      return {
        ...state,
        historyBackup: [],
        backupSelecIcon: [],
        isReloadPinArea: !state.isReloadPinArea
      }
    case types.SET_HISTORIES:
      return {
        ...state,
        historyBackup: action.payload
      }
    case types.RESET_BACKUP_SELECT:
      return {
        ...state,
        backupSelecIcon: []
      }
    case types.SET_INITIAL_HISTORY:
      return {
        ...state,
        initialHistory: action.icons
      }              
    case types.SET_OPACITY_CAPTION:
      return {
        ...state,
        opacityCaption: action.percent
      }
    case types.SET_OVERLAY_APP:
      return {
        ...state,
        overlay: action.flag
      }
    case types.SET_CSV_POINT:
      return {
        ...state,
        csvPoint: action.points
      }
    case types.SET_CSV_POINT_ERROR:
      return {
        ...state,
        csvPointError: {  error: action.options.error, errorRow: action.options.errorRow },
      }
    case types.SET_INSPECTION_MAP_TYPE:
      return {
        ...state,
        inspectionType: action.inspectionType,
      }
    case types.SET_FINCHK_INSPECTION_FILTER:
      const finchkInspectionFilter = {}
      if (action.finchkInspectionFilter.kind || action.finchkInspectionFilter.kind === 0) {
        finchkInspectionFilter.kind = action.finchkInspectionFilter.kind
      }
      if (action.finchkInspectionFilter.work || action.finchkInspectionFilter.work === 0) {
        finchkInspectionFilter.work = action.finchkInspectionFilter.work
      }
      if (action.finchkInspectionFilter.result || action.finchkInspectionFilter.result === 0) {
        finchkInspectionFilter.result = action.finchkInspectionFilter.result
      }

      return {
        ...state,
        finchkInspectionFilter: {
          ...state.finchkInspectionFilter,
          ...finchkInspectionFilter,
        },
      }
    case types.SET_GS_INSPECTION_FILTER:
      return {
        ...state,
        gsInspectionFilter: action.gsInspectionFilter,
      }

    case types.RESET_ICONS_FILTER_TO_DEFAULT:
      const iconsFilter = filterIconsDependOnAreaWithPart(
        {
          icons: state.icons,
          areaId: state.areaId,
          partId: state.partId,
          inspectionId: state.inspectionId,
          state: state
        }
      )

      return {
        ...state,
        iconsFilter: iconsFilter,
      }
    case types.SET_POSITION_AREA:
      return {
        ...state,
        areaTop: !state.areaTop
      }
    case types.SET_IS_READY:
      return {
        ...state,
        isReady: action.isReady,
      }

    case types.SET_IS_RELOAD_PIN_AREA:
      return {
        ...state,
        isReloadPinArea: !state.isReloadPinArea,
      }
    default:
      return state;
  }
}

/**
 * POLYGON形状のアイコンについて頂点リストをJsonから配列に変換する。
 * @param {*} icon 
 */
function areaPointList(icon) {
  if (icon.area) {
    let area = JSON.parse(icon.area);
    return area.shape;
  } else {
    return [];
  }
}