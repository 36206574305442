/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: project.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';
import { hasInspectionAcl } from '../utils/acl';

/**
 * 初期状態
 */
const initialState = {
  projectId: null,
  projectDetail: null,
  sasToken: null,
  inspectionId: null,
  needsReload: false,
  isLoading: false
};

/**
 * project reducer
 * @param {*} state 状態
 * @param {*} action アクション
 */
export default function project(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_PROJECT_DETAIL:
      return Object.assign({}, state, {
        isLoading: true
      });
    case types.RECEIVE_GET_PROJECT_DETAIL: {
      let sorted = action.projectDetail.inspections.filter((inspection) => {
        return hasInspectionAcl(inspection.id, action.projectDetail.projectAcl, ["INSPECTION_VIEW", "INSPECTION_CHECK", "INSPECTION_REPORT"])
      }).sort((a, b) => {
        if (a.displayOrder < b.displayOrder) {
          return -1;
        } else if (a.displayOrder > b.displayOrder) {
          return 1;
        } else {
          if (a.id < b.id) {
            return -1;
          } else if (a.id > b.id) {
            return 1;
          }
        }
        return 0;
      });
      return Object.assign({}, state, {
        projectId: action.projectId,
        projectDetail: action.projectDetail,
        sasToken: action.sasToken != null ? action.sasToken : state.sasToken,
        inspectionId: sorted.length > 0 ? sorted[0].id : null,
        needsReload: false,
        isLoading: false
      });
    }
    case types.RECEIVE_GET_PROJECT_ACL: {
      let projectDetail = Object.assign([], state.projectDetail);
      projectDetail.projectAcl = action.projectAcl;
      return Object.assign({}, state, {
        projectDetail: projectDetail,
      });
    }
    case types.SET_PROJECT:
      return Object.assign({}, state, {
        projectId: action.projectId,
        projectDetail: null,
        sasToken: null,
        inspectionId: null,
        needsReload: false
      });
    case types.RECEIVE_LOGOUT:
    case types.INIT_AUTH:
    case types.CLEAN_PROJECT:
      return initialState;
    case types.SET_INSPECTION:
      return Object.assign({}, state, {
        inspectionId: action.inspectionId,
      });
    case types.RECEIVE_UPDATE_ADMIN_FLOOR:
    case types.RECEIVE_UPDATE_ADMIN_GUIDE:
    case types.RECEIVE_CREATE_ADMIN_INSPECTION:
    case types.RECEIVE_UPDATE_ADMIN_INSPECTION:
    case types.RECEIVE_UPDATE_ADMIN_INSPECTIONS:
    case types.RECEIVE_DELETE_ADMIN_INSPECTION:
    case types.RECEIVE_CREATE_ADMIN_INSPECTION_ROLE:
    case types.RECEIVE_UPDATE_ADMIN_INSPECTION_ROLE:
    case types.RECEIVE_DELETE_ADMIN_INSPECTION_ROLE:
    case types.RECEIVE_CREATE_ADMIN_MAP_ASYNC:
    case types.RECEIVE_UPDATE_ADMIN_MAP:
    case types.RECEIVE_UPDATE_ADMIN_MAP_ASYNC:
    case types.RECEIVE_DELETE_ADMIN_MAP:
    case types.RECEIVE_UPDATE_ADMIN_PROJECT:
    case types.RECEIVE_CREATE_ADMIN_TREE:
    case types.RECEIVE_DELETE_ADMIN_TREE:
    case types.RECEIVE_CREATE_ADMIN_TREE_NODE:
    case types.RECEIVE_UPDATE_ADMIN_TREE_NODE:
    case types.RECEIVE_DELETE_ADMIN_TREE_NODE:
    case types.RECEIVE_CREATE_ADMIN_WORK:
    case types.RECEIVE_UPDATE_ADMIN_WORK:
    case types.RECEIVE_DELETE_ADMIN_WORK:
    case types.RECEIVE_CREATE_ADMIN_WORKGROUP:
    case types.RECEIVE_UPDATE_ADMIN_WORKGROUP:
    case types.RECEIVE_DELETE_ADMIN_WORKGROUP:
    case types.RECEIVE_CREATE_ADMIN_PROJECT_ROLE:
    case types.RECEIVE_UPDATE_ADMIN_PROJECT_ROLE:
    case types.RECEIVE_DELETE_ADMIN_PROJECT_ROLE:
    case types.RECEIVE_CREATE_ADMIN_AREA:
    case types.RECEIVE_UPDATE_ADMIN_AREA:
    case types.DELETING_ADMIN_AREA:
    case types.RECEIVE_CREATE_ADMIN_RESFILE:
    case types.RECEIVE_DELETE_ADMIN_RESFILE:
    case types.IMPORT:
      return Object.assign({}, state, {
        needsReload: true
      });
    case types.ERR_API:
    case types.ERR_FINISH:
      return Object.assign({}, state, {
        isLoading: false
      });
    default:
      return state;
  }
}
