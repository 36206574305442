/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: SamlSSO.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'querystring';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '../withTheme';

/**
 * スタイル情報 
 */
const styles = theme => ({
  base: {
    flex: "1 1 auto",
    display: "flex",
    position: "relative",
    overflow: "hidden",
  },
  frame: {
    height: 'auto',
    position: 'absolute',
    top: 50,
    left: 0,
    right: 0,
    margin: 'auto',
    paddingLeft: 20,
    paddingRight: 20,
  },
  paper: {
    padding: 30,
    overflow: 'auto',
  },
  param: {
    fontSize: "0.8em",
    wordWrap: "break-word",
    marginBottom: 20
  }
});

/**
 * SamlSSOコンポーネント
 */
class SamlSSO extends Component {
  /**
    * コンストラクタ
    * @param {*} props 
    */
  constructor(props) {
    super(props);
    this.state = {
      params: queryString.parse(props.location.search)
    }
  }

  /**
   * Loginコンポーネントのレンダリング
   */
  render() {
    return (
      <div className={this.props.classes.base}>
        <div className={this.props.classes.frame}>
          Query:
          <div className={this.props.classes.param}>{this.props.location.search}</div>
          SAMLResponse:
          <div className={this.props.classes.param}>
            {this.state.params["SAMLResponse"] == null ? this.state.params["?SAMLResponse"] : this.state.params["SAMLResponse"]}
          </div>
          SigAlg:
          <div className={this.props.classes.param}>
            {this.state.params["SigAlg"] == null ? this.state.params["?SigAlg"] : this.state.params["SigAlg"]}
          </div>
          Signature:
          <div className={this.props.classes.param}>
            {this.state.params["Signature"] == null ? this.state.params["?Signature"] : this.state.params["Signature"]}
          </div>
        </div>
      </div>
    );
  }
}

/**
 * StoreをPropsにマップする。
 * @param {*} state 
 */
function mapStateToProps(state) {
  return state.auth;
}

/**
 * Dispatcherをマップする。
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch) {
  return {
  }
}

/**
 * reduxとの接続
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(SamlSSO)))