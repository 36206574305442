/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: RestrictedRoute.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { hasAnySystemAcl } from '../utils/acl';

/**
 * RequireNoLoginRouteコンポーネント
 * 未ログインであることを要求するパスへのルーティングを行う。
 * ログイン済みである場合は、指定されたURLかもしくは/にリダイレクトする。
 */
export const RequireNoLoginRoute = ({ component, auth, ...rest }) => (
  <Route {...rest} render={props => (
    auth.isLoggedIn ? (
      auth.from ?
        <Redirect to={{ pathname: auth.from }} /> : <Redirect to={{ pathname: '/' }} />
    ) : (
        React.createElement(component, props)
      )
  )} />
);

/**
 * RequireLoginRouteコンポーネント
 * ログイン済みであることを要求するパスへのルーティングを行う。
 * 未ログインである場合は、ログインURLにリダイレクトする。
 */
export const RequireLoginRoute = ({ component, auth, ...rest }) => (
  <Route {...rest} render={props => (
    auth.isLoggedIn ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    )
  )} />
);

/**
 * RequireSystemRouteコンポーネント
 * ログイン済みでシステム権限を持っていることを要求するパスへのルーティングを行う。
 * 未ログインである場合は、ログインURLにリダイレクトする。
 * システム権限がない場合は権限エラーページにリダイレクトする。
 */
export const RequireSystemRoute = ({ component, auth, ...rest }) => (
  <Route {...rest} render={props => (
    auth.isLoggedIn ? (
      hasAnySystemAcl(auth.systemAcl) ? (
        React.createElement(component, props)
      ) : (
        <Redirect to={{ pathname: '/noprivilege' }} />
      )
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    )
  )} />
);

/**
 * RootRouteコンポーネント
 * ログイン済みで/（ルート）への要求を権限に応じて適切なパスへルーティングする。
 * 未ログインである場合は、ログインURLにリダイレクトする。
 * 前回アクセスしていたプロジェクトがある場合はそのトップページにリダイレクトする。
 * そうでない場合、システム権限がある時はシステム管理ページにリダイレクトする。
 * システム権限がない場合はプロジェクト一覧ページにリダイレクトする。
 */
export const RootRoute = ({ component, auth, ...rest }) => (
  <Route {...rest} render={props => (
    auth.isLoggedIn ? (
      window.localStorage.getItem("project") ? (
        <Redirect to={{ pathname: '/projects/' + window.localStorage.getItem("project") }} />
      ) : (
        hasAnySystemAcl(auth.systemAcl) ? (
          <Redirect to={{ pathname: '/system' }} />
        ) : (
          <Redirect to={{ pathname: '/projects' }} />
        )
      )
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    )
  )} />
);
