/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminUser.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  users: null,
  projectRoles: null,
  inspectionRoles: null,
  userOrganizations: null,
  organizations: null,
  selection: null,
  userOrganizationSelection: null,
  dirty: false,
  userFound: null
};

/**
 * user reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminUser(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_USER: {
      return Object.assign({}, state, {
        users: null,
        projectRoles: null,
        inspectionRoles: null,
        userOrganizations: null,
        selection: null,
        userOrganizationSelection: null,
        dirty: false
      });
    }
    case types.RECEIVE_GET_ADMIN_USER:
      return Object.assign({}, state, {
        users: action.users,
        selection: null,
        dirty: false
      });
    case types.GETTING_PROJECT_ROLE_FOR_ADMIN_USER:
      return Object.assign({}, state, {
        projectRoles: null
      });
    case types.RECEIVE_GET_PROJECT_ROLE_FOR_ADMIN_USER:
      return Object.assign({}, state, {
        projectRoles: action.projectRoles
      });
    case types.GETTING_INSPECTION_ROLE_FOR_ADMIN_USER:
      return Object.assign({}, state, {
        inspectionRoles: null
      });
    case types.RECEIVE_GET_INSPECTION_ROLE_FOR_ADMIN_USER:
      return Object.assign({}, state, {
        inspectionRoles: action.inspectionRoles
      });
    case types.RECEIVE_GET_ADMIN_USER_ORGANIZATION:
      return Object.assign({}, state, {
        userOrganizations: action.userOrganizations
      });
    case types.RECEIVE_GET_ADMIN_ORGANIZATION:
      return Object.assign({}, state, {
        organizations: action.organizations,
      });
    case types.CREATING_ADMIN_USER: {
      return Object.assign({}, state, {
        userFound: null
      });
    }
    case types.RECEIVE_CREATE_ADMIN_USER: {
      if (action.valid) {
        let users = Object.assign([], state.users);
        users.push(action.user);
        let userOrganizations = Object.assign([], state.userOrganizations);
        userOrganizations.push(action.userOrganization);
        return Object.assign({}, state, {
          users: users,
          userOrganizations: userOrganizations,
          selection: null,
          userOrganizationSelection: null,
          dirty: false,
          userFound: true
        });
      } else {
        return Object.assign({}, state, {
          userFound: false
        });
      }
    }
    case types.RECEIVE_UPDATE_ADMIN_USER: {
      let users = Object.assign([], state.users);
      users.splice(users.map(function (u) { return u.id; }).indexOf(action.user.id), 1, action.user);
      let userOrganizations = Object.assign([], state.userOrganizations);
      userOrganizations.splice(userOrganizations.map(function (uo) { return uo.id; }).indexOf(action.userOrganization.id), 1, action.userOrganization);
      return Object.assign({}, state, {
        users: users,
        userOrganizations: userOrganizations,
        selection: null,
        userOrganizationSelection: null,
        dirty: false
      });
    }
    case types.RECEIVE_DELETE_ADMIN_USER: {
      var users = state.users.filter(function (u) { return u.id !== action.uid; });
      var userOrganizations = state.userOrganizations.filter(function (uo) { return uo.id !== action.userOrganizationId; });
      return Object.assign({}, state, {
        users: users,
        userOrganizations: userOrganizations,
        selection: null,
        userOrganizationSelection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_ADMIN_USER:
      return Object.assign({}, state, {
        selection: action.user,
        userOrganizationSelection: action.userOrganization,
        dirty: false
      });
    case types.SET_ADMIN_USER_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_ADMIN_USER:
      return initialState;
    default:
      return state;
  }
}