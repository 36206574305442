/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: Login.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '../withTheme';
import { loginIfNeeded } from '../actions/auth';
import { getSsoSamlUrl } from '../utils/ssoUtils';


/**
 * スタイル情報 
 */
const styles = theme => ({
  base: {
    flex: "1 1 auto",
    display: "flex",
    position: "relative",
    overflow: "hidden",
  },
  frame: {
    minWidth: 320,
    maxWidth: 400,
    height: 'auto',
    position: 'absolute',
    top: '20%',
    left: 0,
    right: 0,
    margin: 'auto',
  },
  paper: {
    padding: 30,
    marginBottom: 50,
    overflow: 'auto',
  },
  login: {
    float: 'right',
    marginTop: 30,
    width: 200,
  },
  password: {
    imeMode: "inactive"
  },
  aux: {
    paddingBottom: 25,
    textAlign: "center"
  },
  error: {
    width: "100%",
    textAlign: "center",
    margin: 0,
    padding: "3px 0",
    border: "solid 3px",
    borderColor: theme.palette.error.light,
    borderRadius: "3px"
  },
  errorText: {
    width: "100%",
    textAlign: "center",
    marginTop: 3
  },
});

/**
 * Loginコンポーネント
 */
class Login extends Component {
  /**
   * コンストラクタ
   */
  constructor(props) {
    super(props);
    this.state = {
      passwordInput: React.createRef(),
      loginId: "",
      password: ""
    };
  }

  /**
   * ログインIDが変更された時に呼び出されるハンドラ
   */
  handleLoginIdChange(event) {
    this.setState({
      loginId: event.target.value
    });
  }

  /**
   * ログインIDでリターンキーが押された場合の処理を行うハンドラ
   */
  handleLoginIdKeyPress(event) {
    if (event.charCode === 10 || event.charCode === 13) {
      this.state.passwordInput.current.focus();
    }
  }

  /**
   * パスワードが変更された時に呼び出されるハンドラ
   */
  handlePasswordChange(event) {
    this.setState({
      password: event.target.value
    });
  }

  /**
   * パスワードでリターンキーが押された場合の処理を行うハンドラ
   */
  handlePasswordKeyPress(event) {
    if (event.charCode === 10 || event.charCode === 13) {
      this.doLogin();
    }
  }

  /**
   * ログインボタンがクリックされた時に呼び出されるハンドラ
   */
  handleLoginButtonClick() {
    this.doLogin();
  }

  /**
   * ログイン処理を実行する。
   */
  doLogin() {
    var now = moment();
    var today2am = moment().startOf('day').add(2, "hours");
    var date = today2am.isAfter(now) ? today2am : today2am.add(1, "days");
    this.props.loginIfNeeded(this.state.loginId, this.state.password, date);
  }

  /**
   * Loginコンポーネントのレンダリング
   */
  render() {
    return (
      <div className={this.props.classes.base}>
        <div className={this.props.classes.frame}>
          <Paper className={this.props.classes.paper}>
            {(() => {
              return this.props.authError ?
                <div className={this.props.classes.error}>
                  <Typography variant="subtitle1" color="error" className={this.props.classes.errorText}>ログイン認証情報が無効です</Typography>
                </div>
                : null
            })()}
            <TextField
              value={this.state.loginId}
              label="メールアドレス"
              autoComplete="email"
              margin="normal"
              fullWidth={true}
              onChange={(event) => this.handleLoginIdChange(event)}
              onKeyPress={(event) => this.handleLoginIdKeyPress(event)}
            />
            <TextField
              value={this.state.password}
              className={this.props.classes.password}
              label="パスワード"
              type="password"
              margin="normal"
              fullWidth={true}
              inputRef={this.state.passwordInput} 
              onChange={(event) => this.handlePasswordChange(event)}
              onKeyPress={(event) => this.handlePasswordKeyPress(event)}
            />
            <Button
              variant="contained"
              color="primary"
              className={this.props.classes.login}
              onClick={(event) => this.handleLoginButtonClick()}
            >
              ログイン
            </Button>
          </Paper>
          <div className={this.props.classes.aux}>
            <Button
              href={getSsoSamlUrl()}
              color="primary"
            >
              イントラSSOはこちらをクリックしてください
            </Button>
          </div>
          <div className={this.props.classes.aux}>
            <Button
              component={Link} to="/signup"
              color="primary"
            >
              ユーザ登録
            </Button>
            <Button
              component={Link} to="/password"
              color="primary"
            >
              パスワードのリセット(社外)
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * StoreをPropsにマップする。
 * @param {*} state 
 */
function mapStateToProps(state) {
  return state.auth;
}

/**
 * Dispatcherをマップする。
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch) {
  return {
    loginIfNeeded: (username, password, expire) => {
      dispatch(loginIfNeeded(username, password, expire))
    }
  }
}

/**
 * reduxとの接続
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(Login)))