/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: ssoUtils.js
 * Author: Naoaki Suganuma
 * Update: 2018/2/14
 * Version: 1.0.0
 */
import moment from 'moment';
import pako from './pako';

/**
 * Single Sign OnのためのURLを取得する。
 */
export function getSsoSamlUrl() {
  var xml0 = "<samlp:AuthnRequest xmlns:samlp='urn:oasis:names:tc:SAML:2.0:protocol' xmlns:saml='urn:oasis:names:tc:SAML:2.0:assertion' ID='";
  var xml1 = "' Version='2.0' IssueInstant='";
  var xml2 = "' AssertionConsumerServiceURL='";
  var xml3 = "' ProtocolBinding='urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST'><saml:Issuer>";
  var xml4 = "</saml:Issuer><samlp:NameIDPolicy Format='urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified'/></samlp:AuthnRequest>";
  var xml = xml0 + generateId() + xml1 + moment().toISOString() + xml2 + window.SSOCallbacckURL + xml3 + window.SSOIdentifier + xml4;
  var parameter = encodeURIComponent(btoa(pako.deflate(xml, { to: 'string', raw: true })));
  return window.SSOEndpoint + parameter;
}

/**
 * IDを生成する。
 */
function generateId() {
  var c = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let chars = "zzzxxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
  for (let i = 0, len = chars.length; i < len; i++) {
    switch (chars[i]) {
      case "x":
        chars[i] = Math.floor(Math.random() * 16).toString(16);
        break;
      case "y":
        chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
        break;
      case "z":
        chars[i] = c[Math.floor(Math.random() * c.length)];
        break;
      default:
        break;
    }
  }
  return chars.join("");
}