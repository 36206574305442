/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminFloor.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  floorDetail: null,
  dirty: false
};

/**
 * floor reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminFloor(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVE_GET_ADMIN_FLOOR:
      return Object.assign({}, state, {
        floorDetail: action.floorDetail,
        dirty: false
      });
    case types.RECEIVE_UPDATE_ADMIN_FLOOR:
      return Object.assign({}, state, {
        floorDetail: action.floor,
        dirty: false
      });
    case types.CREATE_ADMIN_FLOOR_HEIGHT: {
      let detail = Object.assign({}, state.floorDetail);
      detail.heights.push(action.floor);
      return Object.assign({}, state, {
        floorDetail: detail,
        dirty: true
      });
    }
    case types.UPDATE_ADMIN_FLOOR_HEIGHT: {
      let detail = Object.assign({}, state.floorDetail);
      detail.heights.splice(detail.heights.map(function (f) { return f.id; }).indexOf(action.fid), 1, action.floor);
      return Object.assign({}, state, {
        floorDetail: detail,
        dirty: true
      });
    }
    case types.DELETE_ADMIN_FLOOR_HEIGHT: {
      let detail = Object.assign({}, state.floorDetail);
      var newHorizontal = detail.heights.filter(function (f) { return f.id !== action.fid; });
      detail.heights = newHorizontal;
      return Object.assign({}, state, {
        floorDetail: detail,
        dirty: true
      });
    }
    case types.UPDATE_ADMIN_FLOOR_NAME: {
      let detail = Object.assign({}, state.floorDetail);
      detail.name = action.name;
      return Object.assign({}, state, {
        floorDetail: detail,
        dirty: true
      });
    }
    case types.UPDATE_ADMIN_FLOOR_HEIGHT_LIST: {
      let detail = Object.assign({}, state.floorDetail);
      detail.heights = action.floors;
      return Object.assign({}, state, {
        floorDetail: detail,
        dirty: true
      });
    }
    case types.CLEAN_ADMIN_FLOOR:
      return initialState;
    default:
      return state;
  }
}
