/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: index.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import app from './app';
import auth from './auth';
import notifications from './notifications';
import systemSite from './systemSite';
import systemProject from './systemProject';
import systemInspection from './systemInspection';
import systemUser from './systemUser';
import systemRole from './systemRole';
import systemMail from './systemMail';
import systemHelp from './systemHelp';
import systemDangerzone from './systemDangerzone';
import projects from './projects';
import project from './project';
import map from './map';
import progress from './progress';
import report from './report';
import photo from './photo';
import icon from './icon';
import adminProject from './adminProject';
import adminGuide from './adminGuide';
import adminFloor from './adminFloor';
import adminLocation from './adminLocation';
import adminMapFile from './adminMapFile';
import adminResFile from './adminResFile';
import adminMap from './adminMap';
import adminTree from './adminTree';
import adminTemplate from './adminTemplate';
import adminInspection from './adminInspection';
import adminInspectionMap from './adminInspectionMap';
import adminUser from './adminUser';
import adminOrganization from './adminOrganization';
import adminWork from './adminWork';
import adminWorkGroup from './adminWorkGroup';
import adminProjectRole from './adminProjectRole';
import adminInspectionRole from './adminInspectionRole';
import adminArea from './adminArea';
import adminDevice from './adminDevice';
import csv from './csv';
import docViewer from './docViewer';
import errorReducer from './errorReducer';

/**
 * Reducerの結合
 */
const rootReducer = (history) => combineReducers({
  app,
  auth,
  notifications,
  systemSite,
  systemProject,
  systemInspection,
  systemUser,
  systemRole,
  systemMail,
  systemHelp,
  systemDangerzone,
  projects,
  project,
  map,
  progress,
  report,
  photo,
  icon,
  adminProject,
  adminGuide,
  adminFloor,
  adminLocation,
  adminMapFile,
  adminResFile,
  adminMap,
  adminTree,
  adminTemplate,
  adminInspection,
  adminInspectionMap,
  adminUser,
  adminOrganization,
  adminWork,
  adminWorkGroup,
  adminProjectRole,
  adminInspectionRole,
  adminArea,
  adminDevice,
  csv,
  docViewer,
  error: errorReducer,
  router: connectRouter(history)
});

/**
 * rootReducerのエクスポート
 */
export default rootReducer;