/**
 * configureStore.js
 * Naoaki Suganuma
 * 2017/06/20
 * ver. 1.00
 */

import { createBrowserHistory } from 'history';
import { createStore, compose, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import createRootReducer from '../reducers';
import logger from 'redux-logger'

export const history = createBrowserHistory();

/**
 * storeの生成
 * @param {*} history history
 */
export default function configureStore(preloadedState) {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware,
        logger
      ),
    ),
  )
  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(createRootReducer(history));
    });
  }
  return store
}
