/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: Loader.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React, { Component } from 'react';
import Spinner from 'react-spinkit';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '../withTheme';

/**
 * スタイル情報 
 */
const styles = theme => ({
  base: {
    background: 'rgba(0, 0, 0, 0.2)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
    display: 'block'
  },
  spinner: {
    margin: "auto",
    position: 'absolute',
    left: 0,
    right: 0,
    top: '48%',
    width: 54
  },

});

/**
 * Loaderコンポーネント
 */
class Loader extends Component {
  /**
   * Spinnerのレンダリング
   */
  render() {
    return (
      this.props.isActive ?
        <div className={this.props.classes.base}>
          <Spinner name="three-bounce" className={this.props.classes.spinner}/>
        </div>
        : null
    )
  }
}

/**
 * Stykeとthemaの適用
 */
export default withTheme(withStyles(styles)(Loader))