/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: icon.js
 * Author: Naoaki Suganuma
 * Update: 2020/08/10
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  icon: null,
};

/**
 * project reducer
 * @param {*} state 状態
 * @param {*} action アクション
 */
export default function icon(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ICON:
      return Object.assign({}, state, {
        icon: null,
      });
    case types.RECEIVE_GET_ICON: {
      return Object.assign({}, state, {
        icon: action.icon,
      });
    }
   default:
      return state;
  }
}
