/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: PageHeader.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import HomeIcon from '@material-ui/icons/Home';
import withTheme from '../withTheme';
import { hasAnySystemAcl } from '../utils/acl';

/**
 * スタイル情報 
 */
const styles = theme => ({
  base: {
    width: "100%",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  icon: {
    flex: "0 1 50px",
    margin: "8px 8px 0px 18px",
    color: blueGrey[500]
  },
  title: {
    flex: "1 1 auto",
    padding: "3px 0px 0px 8px"
  },
});

/**
 * PageHeaderコンポーネント
 */
class PageHeader extends Component {
  /**
    * ログインユーザの権限に応じて適切なホームリンクを取得する。
    * @return {string} ホームリンク
    */
  getHomeLink() {
    if (this.props.auth.isLoggedIn) {
      if (hasAnySystemAcl(this.props.auth.systemAcl)) {
        return "/system";
      } else {
        return "/projects";
      }
    } else {
      return "/";
    }
  }

  /**
   * Headerのレンダリング
   */
  render() {
    return (
      <div className={this.props.classes.base}>
        <div className={this.props.classes.header}>
          <Tooltip title="ホーム">
            <Link style={{margin: 0}} to={this.getHomeLink()}>
              <HomeIcon className={this.props.classes.icon} />
            </Link>
          </Tooltip>
          <Typography variant="subtitle1" className={this.props.classes.title}>{this.props.title}</Typography>
        </div>
        <Divider />
      </div>
    );
  }
}

/**
 * StoreをPropsにマップする。
 * @param {*} state 
 */
function mapStateToProps(state) {
  return state;
}

/**
 * Dispatcherをマップする。
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch) {
  return {
  }
}

/**
 * reduxとの接続
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(PageHeader)))
