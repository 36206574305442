/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: ValidatorGroup.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * ValidatorGroupコンポーネント
 */
export default class ValidatorGroup extends Component {
  /**
   * コンストラクタ
   */
  constructor(props) {
    super(props);
    this.children = [];
    this.errors = [];
    this.attach = this.attach.bind(this);
    this.detach = this.detach.bind(this);
    this.handleValidationResult = this.handleValidationResult.bind(this);
  }

  /**
   * 子要素と共有するコンテキスト
   */
  getChildContext() {
    return {
      group: {
        attach: this.attach,
        detach: this.detach,
        notifyValidationResult: this.handleValidationResult
      },
    };
  }

  /**
   * コンポーネントのマウント
   */
  componentDidMount() {
  }

  /**
   * 子要素のattach
   * @param {object} component 
   */
  attach(component) {
    if (this.children.map(function (c) { return c.component; }).indexOf(component) === -1) {
      this.children.push({
        component: component,
        isValid: null
      });
    }
  }

  /**
   * 子要素のdetach
   * @param {object} component
   */
  detach(component) {
    const componentPos = this.children.map(function (c) { return c.component; }).indexOf(component)
    if (componentPos !== -1) {
      this.children = this.children.slice(0, componentPos).concat(this.children.slice(componentPos + 1));
      let result = this.children.map(function (c) { return { name: c.component.props.name, valid: c.isValid } });
      if (this.props.validatorGroupListener) {
        this.props.validatorGroupListener(result);
      }
    }
  }

  /**
   * 子要素のValidation結果通知を処理するハンドラ
   * @param {object} component 
   * @param {bool} isValid 
   */
  handleValidationResult(component, isValid) {
    const componentPos = this.children.map(function (c) { return c.component; }).indexOf(component)
    if (componentPos !== -1) {
      this.children[componentPos].isValid = isValid;
      let result = this.children.map(function (c) { return { name: c.component.props.name, valid: c.isValid } });
      if (this.props.validatorGroupListener) {
        this.props.validatorGroupListener(result);
      }
    }
  }

  /**
   * レンダリング
   */
  render() {
    const { validatorGroupListener, ...rest } = this.props;
    return (
      <div {...rest}>
        {this.props.children}
      </div>
    );
  }
}

/**
 * childContextTypes
 */
ValidatorGroup.childContextTypes = {
  group: PropTypes.object,
};

/**
 * プロパティ
 */
ValidatorGroup.propTypes = {
  children: PropTypes.node,
  validatorGroupListener: PropTypes.func
};

/**
 * デフォルトプロパティ
 */
ValidatorGroup.defaultProps = {
  validatorGroupListener: () => { },
};
