/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: systemHelp.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  inspectionModels: null,
  helps: null,
  selection: null,
  dirty: false,
  uploadingProgress: 0
};

/**
 * help reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function systemHelp(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_SYSTEM_INSPECTION_MODEL: {
      return Object.assign({}, state, {
        inspectionModels: null,
        helps: null,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_GET_SYSTEM_INSPECTION_MODEL: {
      let inspectionModels = Object.assign([], action.inspectionModels);
      inspectionModels.unshift({
        id: 0,
        name: "システム・共通基盤"
      });
      return Object.assign({}, state, {
        inspectionModels: inspectionModels
      });
    }
    case types.GETTING_SYSTEM_HELP: {
      return Object.assign({}, state, {
        helps: null,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_GET_SYSTEM_HELP:
      return Object.assign({}, state, {
        helps: action.helps,
        selection: null,
        dirty: false
      });
    case types.CREATING_SYSTEM_HELP:
      return Object.assign({}, state, {
        uploadingProgress: 0
      });
    case types.UPDATING_SYSTEM_HELP:
      return Object.assign({}, state, {
        uploadingProgress: 0
      });
    case types.SET_UPLOAD_SYSTEM_HELP_PROGRESS: {
      return Object.assign({}, state, {
        uploadingProgress: action.progress
      });
    }
    case types.RECEIVE_CREATE_SYSTEM_HELP: {
      let helps = Object.assign([], state.helps);
      helps.push(action.help);
      return Object.assign({}, state, {
        helps: helps,
        selection: null,
        dirty: false,
        uploadingProgress: 0
      });
    }
    case types.RECEIVE_UPDATE_SYSTEM_HELP: {
      let helps = Object.assign([], state.helps);
      helps.splice(helps.map(function (h) { return h.id; }).indexOf(action.help.id), 1, action.help);
      return Object.assign({}, state, {
        helps: helps,
        selection: null,
        dirty: false,
        uploadingProgress: 0
      });
    }
    case types.RECEIVE_DELETE_SYSTEM_HELP: {
      var helps = state.helps.filter(function (h) { return h.id !== action.helpId; });
      return Object.assign({}, state, {
        helps: helps,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_SYSTEM_HELP:
      return Object.assign({}, state, {
        selection: action.help,
        dirty: false
      });
    case types.SET_SYSTEM_HELP_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_SYSTEM_HELP:
      return initialState;
    default:
      return state;
  }
}
