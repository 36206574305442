/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: systemState.js
 * Author: Naoaki Suganuma
 * Update: 2023//12
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';
import { err } from './errorAction';
import { UtilsApi } from '../api/api'
import { getApiEndpoint } from '../constants/ApiEndpoint';

///--------------------------------------------
/// システム状態取得
///--------------------------------------------
/**
 * システム状態取得APIを呼び出す。
 */
export function getSystemStateIfNeeded() {
  return (dispatch) => {
    return dispatch(getSystemState());
  };
}

/**
 * システム状態取得APIを呼び出し、システム状態を取得する。
 */
function getSystemState() {
  return dispatch => {
    dispatch(gettingSystemState());
    var api = new UtilsApi(getApiEndpoint());
    api.getState()
      .then(function (val) {
        dispatch(receiveGetSystemState(val.body));
        return null;
      })
      .catch(function (val) {
        dispatch(err(val));
        return null;
      })
  };
}

/**
 * システム状態取得中に移行する。
 */
function gettingSystemState() {
  return {
    type: types.GETTING_SYSTEM_STATE
  };
}

/**
 * 取得済みシステム状態を設定する。
 * @param {*} state システム状態
 */
function receiveGetSystemState(state) {
  return {
    type: types.RECEIVE_GET_SYSTEM_STATE,
    state: state
  };
}

