/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: systemUser.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  users: null,
  selection: null,
  systemRoles: null,
  dirty: false
};

/**
 * user reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function systemUser(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_SYSTEM_USER: {
      return Object.assign({}, state, {
        users: null,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_GET_SYSTEM_USER:
      return Object.assign({}, state, {
        users: action.users,
        selection: null,
        dirty: false
      });
    case types.GETTING_SYSTEM_ROLE:
      return Object.assign({}, state, {
        systemRoles: null
      });
    case types.RECEIVE_GET_SYSTEM_ROLE:
      return Object.assign({}, state, {
        systemRoles: action.systemRoles
      });
    case types.RECEIVE_UPDATE_SYSTEM_USER: {
      let users = Object.assign([], state.users);
      users.splice(users.map(function (u) { return u.id; }).indexOf(action.user.id), 1, action.user);
      return Object.assign({}, state, {
        users: users,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_DELETE_SYSTEM_USER: {
      var users = state.users.filter(function (u) { return u.id !== action.userId; });
      return Object.assign({}, state, {
        users: users,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_SYSTEM_USER:
      return Object.assign({}, state, {
        selection: action.user,
        dirty: false
      });
    case types.SET_SYSTEM_USER_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_SYSTEM_USER:
      return initialState;
    default:
      return state;
  }
}
