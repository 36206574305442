/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: systemDangerzone.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  createCacheSuccess: false,
};

/**
 * systemDangerzone reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function systemDangerzone(state = initialState, action) {
  switch (action.type) {
    case types.CREATING_CACHE: {
      return Object.assign({}, state, {
        createCacheSuccess: false,
      });
    }
    case types.RECEIVE_CREATE_CACHE:
      return Object.assign({}, state, {
        createCacheSuccess: action.createCacheSuccess,
      });
    default:
      return state;
  }
}
