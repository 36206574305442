/**
 * app.js
 * Naoaki Suganuma
 * 2017/08/16
 * ver. 1.0.0
 */
import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  isConnectiongServer: false,
  state: null
};

/**
 * app reducer
 * @param {*} state 状態
 * @param {*} action アクション
 */
export default function app(state = initialState, action) {
  switch (action.type) {
    case types.LOGGING_IN:
    case types.LOGGING_OUT:
    case types.SIGNING_UP:
    case types.REGISTERING:
    case types.PASSWORD_RESETTING:
    case types.SETTING_PASSWORD:
    case types.AUTHENTICATED_LOGGING_IN:
    case types.GETTING_SYSTEM_SITE:
    case types.CREATING_SYSTEM_SITE:
    case types.GETTING_SYSTEM_PROJECT:
    case types.CREATING_SYSTEM_PROJECT:
    case types.UPDATING_SYSTEM_PROJECT:
    case types.DELETING_SYSTEM_PROJECT:
    case types.GETTING_SYSTEM_USER_BY_EMAIL:
    case types.GETTING_SYSTEM_USER_BY_ID:
    case types.GETTING_SYSTEM_USER:
    case types.GETTING_SYSTEM_ROLE:
    case types.RESET_PASSWORD_SYSTEM_USER:
    case types.UPDATING_SYSTEM_USER:
    case types.DELETING_SYSTEM_USER:
    case types.GETTING_SYSTEM_MAIL:
    case types.UPDATING_SYSTEM_MAIL:
    case types.GETTING_SYSTEM_INSPECTION_MODEL:
    case types.UPDATING_SYSTEM_INSPECTION_MODEL:
    case types.GETTING_SYSTEM_INSPECTION_MASTER:
    case types.CREATING_SYSTEM_INSPECTION_MASTER:
    case types.UPDATING_SYSTEM_INSPECTION_MASTER:
    case types.DELETING_SYSTEM_INSPECTION_MASTER:
    case types.GETTING_SYSTEM_HELP:
    case types.UPLOADING_SYSTE_HELP:
    case types.CREATING_SYSTEM_HELP:
    case types.UPDATING_SYSTEM_HELP:
    case types.DELETING_SYSTEM_HELP:
    case types.CREATING_CACHE:
    case types.GETTING_PROJECTS:
    case types.GETTING_PROJECT_INFO:
    case types.GETTING_PROJECT_DETAIL:
    case types.GETTING_PROJECT_ACL:
    case types.GETTING_MAP_ICON:
    case types.GETTING_ICON_DETAIL:
    case types.GETTING_ICON_DETAIL_LIST:
    case types.CREATING_ICON:
    case types.CREATING_ICONS:
    case types.UPDATING_ICON:
    case types.UPDATING_ICONS:
    case types.GETTING_PROGRESS_ICON:
    case types.GETTING_PROGRESS_WORKGROUP:
    case types.GETTING_PROGRESS_WORK:
    case types.GETTING_PROGRESS_ORGANIZATION:
    case types.GETTING_REPORT_TEMPLATE:
    case types.GETTING_REPORT_DOWNLOAD:
    case types.GETTING_REPORT_WORK:
    case types.DELETING_REPORT_DOWNLOAD:
    case types.GETTING_REPORT_ICON:
    case types.GETTING_PHOTO_COUNT:
    case types.GETTING_PHOTO:
    case types.GETTING_PHOTO_DOWNLOAD:
    case types.DELETING_PHOTO_DOWNLOAD:
    case types.GETTING_ICON:
    case types.GETTING_ADMIN_PROJECT:
    case types.GETTING_ADMIN_PROJECT_USERS:
    case types.UPDATING_ADMIN_PROJECT:
    case types.GETTING_ADMIN_GUIDE:
    case types.UPDATING_ADMIN_GUIDE:
    case types.GETTING_ADMIN_FLOOR:
    case types.UPDATING_ADMIN_FLOOR:
    case types.GETTING_ADMIN_LOCATION:
    case types.CREATING_ADMIN_LOCATION:
    case types.UPDATING_ADMIN_LOCATION:
    case types.DELETING_ADMIN_LOCATION:
    case types.GETTING_ADMIN_INSPECTION:
    case types.GETTING_ADMIN_INSPECTION_MODEL:
    case types.GETTING_ADMIN_INSPECTION_MASTER:
    case types.CREATING_ADMIN_INSPECTION:
    case types.UPDATING_ADMIN_INSPECTION:
    case types.UPDATING_ADMIN_INSPECTIONS:
    case types.DELETING_ADMIN_INSPECTION:
    case types.GETTING_ADMIN_MAPFILE:
    case types.DELETING_ADMIN_MAPFILE:
    case types.GETTING_ADMIN_RESFILE:
    case types.DELETING_ADMIN_RESFILE:
    case types.GETTING_ADMIN_MAP:
    case types.COPYING_ADMIN_MAP:
    case types.UPDATING_ADMIN_MAP:
    case types.DELETING_ADMIN_MAP:
    case types.GETTING_ADMIN_TREE:
    case types.CREATING_ADMIN_TREE:
    case types.UPDATING_ADMIN_TREE_SELECT_BOX:
    case types.DELETING_ADMIN_TREE:
    case types.CREATING_ADMIN_TREE_NODE:
    case types.UPDATING_ADMIN_TREE_NODE:
    case types.DELETING_ADMIN_TREE_NODE:
    case types.GETTING_ADMIN_USER:
    case types.GETTING_ADMIN_TEMPLATE:
    case types.UPLOADING_ADMIN_TEMPLATE:
    case types.CREATING_ADMIN_TEMPLATE:
    case types.UPDATING_ADMIN_TEMPLATE:
    case types.UPDATING_ADMIN_TEMPLATES:
    case types.DELETING_ADMIN_TEMPLATE:
    case types.GETTING_PROJECT_ROLE_FOR_ADMIN_USER:
    case types.GETTING_INSPECTION_ROLE_FOR_ADMIN_USER:
    case types.CREATING_ADMIN_USER:
    case types.UPDATING_ADMIN_USER:
    case types.DELETING_ADMIN_USER:
    case types.RESET_PASSWORD_ADMIN_USER:
    case types.GETTING_ADMIN_ORGANIZATION:
    case types.CREATING_ADMIN_ORGANIZATION:
    case types.UPDATING_ADMIN_ORGANIZATION:
    case types.UPDATING_ADMIN_ORGANIZATIONS:
    case types.DELETING_ADMIN_ORGANIZATION:
    case types.GETTING_ADMIN_WORKGROUP:
    case types.CREATING_ADMIN_WORKGROUP:
    case types.UPDATING_ADMIN_WORKGROUP:
    case types.DELETING_ADMIN_WORKGROUP:
    case types.GETTING_ADMIN_WORK:
    case types.CREATING_ADMIN_WORK:
    case types.UPDATING_ADMIN_WORK:
    case types.UPDATING_ADMIN_WORKS:
    case types.DELETING_ADMIN_WORK:
    case types.GETTING_ADMIN_PROJECT_ROLE:
    case types.CREATING_ADMIN_PROJECT_ROLE:
    case types.UPDATING_ADMIN_PROJECT_ROLE:
    case types.DELETING_ADMIN_PROJECT_ROLE:
    case types.GETTING_ADMIN_INSPECTION_ROLE:
    case types.CREATING_ADMIN_INSPECTION_ROLE:
    case types.UPDATING_ADMIN_INSPECTION_ROLE:
    case types.DELETING_ADMIN_INSPECTION_ROLE:
    case types.GETTING_ADMIN_AREA:
    case types.CREATING_ADMIN_AREA:
    case types.UPDATING_ADMIN_AREA:
    case types.DELETING_ADMIN_AREA:    
    case types.GETTING_ADMIN_DEVICE:
    case types.DELETING_ADMIN_DEVICE:
    case types.EXPORTING:
    case types.IMPORTING:
    case types.GETTING_DOCINDEX:
    case types.GETTING_DOCINDICES:
    case types.GETTING_DOCUMENT:
    case types.APPROVING_DOCUMENT:
    case types.GETTING_DOC_SAS_TOKEN:
      return Object.assign({}, state, {
        isConnectiongServer: true
      });
    case types.RECEIVE_LOGIN:
    case types.RECEIVE_LOGIN_ERROR:
    case types.RECEIVE_LOGOUT:
    case types.RECEIVE_SIGNUP:
    case types.RECEIVE_SIGNUP_ERROR:
    case types.RECEIVE_REGISTER:
    case types.RECEIVE_REGISTER_ERROR:
    case types.RECEIVE_PASSWORD:
    case types.RECEIVE_PASSWORD_ERROR:
    case types.RECEIVE_SET_PASSWORD:
    case types.RECEIVE_SET_PASSWORD_ERROR:
    case types.RECEIVE_GET_SYSTEM_SITE:
    case types.RECEIVE_CREATE_SYSTEM_SITE:
    case types.RECEIVE_GET_SYSTEM_PROJECT:
    case types.RECEIVE_CREATE_SYSTEM_PROJECT:
    case types.RECEIVE_UPDATE_SYSTEM_PROJECT:
    case types.RECEIVE_DELETE_SYSTEM_PROJECT:
    case types.RECEIVE_GET_SYSTEM_USER_BY_EMAIL:
    case types.RECEIVE_GET_SYSTEM_USER_BY_ID:
    case types.RECEIVE_GET_SYSTEM_USER:
    case types.RECEIVE_GET_SYSTEM_ROLE:
    case types.RECEIVE_RESET_PASSWORD_SYSTEM_USER:
    case types.RECEIVE_UPDATE_SYSTEM_USER:
    case types.RECEIVE_DELETE_SYSTEM_USER:
    case types.RECEIVE_GET_SYSTEM_MAIL:
    case types.RECEIVE_UPDATE_SYSTEM_MAIL:
    case types.RECEIVE_GET_SYSTEM_INSPECTION_MODEL:
    case types.RECEIVE_UPDATE_SYSTEM_INSPECTION_MODEL:
    case types.RECEIVE_GET_SYSTEM_INSPECTION_MASTER:
    case types.RECEIVE_CREATE_SYSTEM_INSPECTION_MASTER:
    case types.RECEIVE_UPDATE_SYSTEM_INSPECTION_MASTER:
    case types.RECEIVE_DELETE_SYSTEM_INSPECTION_MASTER:
    case types.RECEIVE_GET_SYSTEM_HELP:
    case types.SET_UPLOAD_SYSTEM_HELP_COMPLETE:
    case types.RECEIVE_CREATE_SYSTEM_HELP:
    case types.RECEIVE_UPDATE_SYSTEM_HELP:
    case types.RECEIVE_DELETE_SYSTEM_HELP:
    case types.RECEIVE_CREATE_CACHE:
    case types.RECEIVE_GET_PROJECTS:
    case types.RECEIVE_GET_PROJECT_INFO:
    case types.RECEIVE_GET_PROJECT_DETAIL:
    case types.RECEIVE_GET_PROJECT_ACL:
    case types.SET_MAP:
    case types.RECEIVE_ICON_DETAIL:
    case types.RECEIVE_ICON_DETAIL_LIST:
    case types.RECEIVE_CREATE_ICON:
    case types.RECEIVE_CREATE_ICONS:
    case types.RECEIVE_UPDATE_ICON:
    case types.RECEIVE_UPDATE_ICONS:
    case types.RECEIVE_GET_PROGRESS_ICON:
    case types.RECEIVE_GET_PROGRESS_WORKGROUP:
    case types.RECEIVE_GET_PROGRESS_WORK:
    case types.RECEIVE_GET_PROGRESS_ORGANIZATION:
    case types.RECEIVE_GET_REPORT_TEMPLATE:
    case types.RECEIVE_GET_REPORT_DOWNLOAD:
    case types.RECEIVE_GET_REPORT_WORK:
    case types.RECEIVE_GET_REPORT_ICON:
    case types.RECEIVE_CREATE_REPORT:
    case types.RECEIVE_DELETE_REPORT_DOWNLOAD:
    case types.RECEIVE_GET_PHOTO_COUNT:
    case types.RECEIVE_GET_PHOTO:
    case types.RECEIVE_GET_PHOTO_DOWNLOAD:
    case types.RECEIVE_DELETE_PHOTO_DOWNLOAD:
    case types.RECEIVE_GET_ICON:
    case types.RECEIVE_GET_ADMIN_PROJECT:
    case types.RECEIVE_GET_ADMIN_PROJECT_USERS:
    case types.RECEIVE_UPDATE_ADMIN_PROJECT:
    case types.RECEIVE_GET_ADMIN_GUIDE:
    case types.RECEIVE_UPDATING_ADMIN_TREE_SELECT_BOX:
    case types.RECEIVE_UPDATE_ADMIN_GUIDE:
    case types.RECEIVE_GET_ADMIN_FLOOR:
    case types.RECEIVE_UPDATE_ADMIN_FLOOR:
    case types.RECEIVE_GET_ADMIN_LOCATION:
    case types.RECEIVE_CREATE_ADMIN_LOCATION:
    case types.RECEIVE_UPDATE_ADMIN_LOCATION:
    case types.RECEIVE_DELETE_ADMIN_LOCATION:
    case types.RECEIVE_GET_ADMIN_INSPECTION:
    case types.RECEIVE_GET_ADMIN_INSPECTION_MODEL:
    case types.RECEIVE_GET_ADMIN_INSPECTION_MASTER:
    case types.RECEIVE_CREATE_ADMIN_INSPECTION:
    case types.RECEIVE_UPDATE_ADMIN_INSPECTION:
    case types.RECEIVE_UPDATE_ADMIN_INSPECTIONS:
    case types.RECEIVE_DELETE_ADMIN_INSPECTION:
    case types.RECEIVE_GET_ADMIN_MAPFILE:
    case types.RECEIVE_DELETE_ADMIN_MAPFILE:
    case types.RECEIVE_GET_ADMIN_RESFILE:
    case types.RECEIVE_DELETE_ADMIN_RESFILE:
    case types.RECEIVE_GET_ADMIN_MAP:
    case types.RECEIVE_COPY_ADMIN_MAP:
    case types.RECEIVE_UPDATE_ADMIN_MAP:
    case types.RECEIVE_DELETE_ADMIN_MAP:
    case types.RECEIVE_GET_ADMIN_TREE:
    case types.RECEIVE_CREATE_ADMIN_TREE:
    case types.RECEIVE_DELETE_ADMIN_TREE:
    case types.RECEIVE_CREATE_ADMIN_TREE_NODE:
    case types.COMPLETE_UPDATE_ADMIN_TREE_NODE:
    case types.RECEIVE_DELETE_ADMIN_TREE_NODE:
    case types.RECEIVE_GET_ADMIN_TEMPLATE:
    case types.SET_UPLOAD_ADMIN_TEMPLATE_COMPLETE:
    case types.RECEIVE_CREATE_ADMIN_TEMPLATE:
    case types.RECEIVE_UPDATE_ADMIN_TEMPLATE:
    case types.RECEIVE_UPDATE_ADMIN_TEMPLATES:
    case types.RECEIVE_DELETE_ADMIN_TEMPLATE:
    case types.RECEIVE_GET_ADMIN_USER:
    case types.RECEIVE_GET_PROJECT_ROLE_FOR_ADMIN_USER:
    case types.RECEIVE_GET_INSPECTION_ROLE_FOR_ADMIN_USER:
    case types.RECEIVE_CREATE_ADMIN_USER:
    case types.RECEIVE_UPDATE_ADMIN_USER:
    case types.RECEIVE_DELETE_ADMIN_USER:
    case types.RECEIVE_RESET_PASSWORD_ADMIN_USER:
    case types.RECEIVE_GET_ADMIN_ORGANIZATION:
    case types.RECEIVE_CREATE_ADMIN_ORGANIZATION:
    case types.RECEIVE_UPDATE_ADMIN_ORGANIZATION:
    case types.RECEIVE_UPDATE_ADMIN_ORGANIZATIONS:
    case types.RECEIVE_DELETE_ADMIN_ORGANIZATION:
    case types.RECEIVE_GET_ADMIN_WORKGROUP:
    case types.RECEIVE_CREATE_ADMIN_WORKGROUP:
    case types.RECEIVE_UPDATE_ADMIN_WORKGROUP:
    case types.RECEIVE_DELETE_ADMIN_WORKGROUP:
    case types.RECEIVE_GET_ADMIN_WORK:
    case types.RECEIVE_CREATE_ADMIN_WORK:
    case types.RECEIVE_UPDATE_ADMIN_WORK:
    case types.RECEIVE_UPDATE_ADMIN_WORKS:
    case types.RECEIVE_DELETE_ADMIN_WORK:
    case types.RECEIVE_GET_ADMIN_PROJECT_ROLE:
    case types.RECEIVE_CREATE_ADMIN_PROJECT_ROLE:
    case types.RECEIVE_UPDATE_ADMIN_PROJECT_ROLE:
    case types.RECEIVE_DELETE_ADMIN_PROJECT_ROLE:
    case types.RECEIVE_GET_ADMIN_INSPECTION_ROLE:
    case types.RECEIVE_CREATE_ADMIN_INSPECTION_ROLE:
    case types.RECEIVE_UPDATE_ADMIN_INSPECTION_ROLE:
    case types.RECEIVE_DELETE_ADMIN_INSPECTION_ROLE:
    case types.RECEIVE_GET_ADMIN_AREA:
    case types.RECEIVE_CREATE_ADMIN_AREA:
    case types.COMPLETE_UPDATE_ADMIN_AREA:
    case types.RECEIVE_DELETE_ADMIN_AREA:    
    case types.RECEIVE_GET_ADMIN_DEVICE:
    case types.RECEIVE_DELETE_ADMIN_DEVICE:
    case types.EXPORT:
    case types.IMPORT:
    case types.RECEIVE_DOCINDEX:
    case types.RECEIVE_DOCINDICES:
    case types.RECEIVE_DOCUMENT:
    case types.RECEIVE_APPROVE_DOCUMENT:
    case types.RECEIVE_GET_DOC_SAS_TOKEN:
    case types.INIT_AUTH:
    case types.ERR_API:
    case types.ERR_FINISH:
      return Object.assign({}, state, {
        isConnectiongServer: false
      });
    case types.RECEIVE_GET_SYSTEM_STATE:
      return Object.assign({}, state, {
        state: action.state
      });
    default:
      return state;
  }
}
