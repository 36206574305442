/**
 * ActionTypes.js
 * Naoaki Suganuma
 * 2017/08/16
 * ver. 1.0.0
 */

//------------------------------------
// エラー関連
//------------------------------------
/** APIエラー */
export const ERR_API = 'ERR_API';

/** エラー処理終了 */
export const ERR_FINISH = 'ERR_FINISH';

//------------------------------------
// 認証関連
//------------------------------------
/** 最初に要求されたURLを設定する */
export const SET_FROM = 'SET_FROM';

/** 状態の初期化 */
export const INIT_AUTH = 'INIT_AUTH';

/** ログイン開始 */
export const LOGGING_IN = 'LOGGING_IN';

/** ログイン受信 */
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';

/** ログインエラー受信 */
export const RECEIVE_LOGIN_ERROR = 'RECEIVE_LOGIN_ERROR';

/** ログアウト開始 */
export const LOGGING_OUT = 'LOGGING_OUT';

/** ログアウト受信 */
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT';

/** 認証トークン情報でログイン開始 */
export const AUTHENTICATED_LOGGING_IN = 'AUTHENTICATED_LOGGING_IN';

/** サインアップ開始 */
export const SIGNING_UP = 'SIGNING_UP';

/** サインアップ受信 */
export const RECEIVE_SIGNUP = 'RECEIVE_SIGNUP';

/** サインアップ完了 */
export const CLEAR_SIGN_UP = 'CLEAR_SIGN_UP';

/** サインアップエラー */
export const RECEIVE_SIGNUP_ERROR = 'RECEIVE_SIGNUP_ERROR';

/** ユーザ登録開始 */
export const REGISTERING = 'REGISTERING';

/** ユーザ登録受信 */
export const RECEIVE_REGISTER = 'RECEIVE_REGISTER';

/** ユーザ登録エラー */
export const RECEIVE_REGISTER_ERROR = 'RECEIVE_REGISTER_ERROR';

/** パスワードリセット開始 */
export const PASSWORD_RESETTING = 'PASSWORD_RESETTING';

/** パスワードリセット受信 */
export const RECEIVE_PASSWORD = 'RECEIVE_PASSWORD';

/** パスワードリセットエラー */
export const RECEIVE_PASSWORD_ERROR = 'RECEIVE_PASSWORD_ERROR';

/** パスワード設定開始 */
export const SETTING_PASSWORD = 'SETTING_PASSWORD';

/** パスワード設定受信 */
export const RECEIVE_SET_PASSWORD = 'RECEIVE_SET_PASSWORD';

/** パスワード設定エラー */
export const RECEIVE_SET_PASSWORD_ERROR = 'RECEIVE_SET_PASSWORD_ERROR';

//------------------------------------
// システム状態
//------------------------------------
/** システム状態取得開始 */
export const GETTING_SYSTEM_STATE = 'GETTING_SYSTEM_STATE';

/** システム状態取得完了 */
export const RECEIVE_GET_SYSTEM_STATE = 'RECEIVE_GET_SYSTEM_STATE';

//------------------------------------
// システム管理・現場関連
//------------------------------------
/** 現場情報の取得開始 */
export const GETTING_SYSTEM_SITE = 'GETTING_SYSTEM_SITE';

/** 現場情報の取得受信 */
export const RECEIVE_GET_SYSTEM_SITE = 'RECEIVE_GET_SYSTEM_SITE';

/** 現場の作成 */
export const CREATING_SYSTEM_SITE = 'CREATING_SYSTEM_SITE';

/** 現場作成の取得受信 */
export const RECEIVE_CREATE_SYSTEM_SITE = 'RECEIVE_CREATE_SYSTEM_SITE';

/** 現場の選択 */
export const SET_SELECT_SYSTEM_SITE = 'SET_SELECT_SYSTEM_SITE';

/** 現場情報の削除 */
export const CLEAN_SYSTEM_SITE = 'CLEAN_SYSTEM_SITE';

//------------------------------------
// システム管理・プロジェクト関連
//------------------------------------
/** プロジェクト情報の取得開始 */
export const GETTING_SYSTEM_PROJECT = 'GETTING_SYSTEM_PROJECT';

/** プロジェクト情報の取得受信 */
export const RECEIVE_GET_SYSTEM_PROJECT = 'RECEIVE_GET_SYSTEM_PROJECT';

/** プロジェクトの作成 */
export const CREATING_SYSTEM_PROJECT = 'CREATING_SYSTEM_PROJECT';

/** プロジェクト作成の取得受信 */
export const RECEIVE_CREATE_SYSTEM_PROJECT = 'RECEIVE_CREATE_SYSTEM_PROJECT';

/** プロジェクトの更新 */
export const UPDATING_SYSTEM_PROJECT = 'UPDATING_SYSTEM_PROJECT';

/** プロジェクト更新の取得受信 */
export const RECEIVE_UPDATE_SYSTEM_PROJECT = 'RECEIVE_UPDATE_SYSTEM_PROJECT';

/** プロジェクトの削除 */
export const DELETING_SYSTEM_PROJECT = 'DELETING_SYSTEM_PROJECT';

/** プロジェクト削除の取得受信 */
export const RECEIVE_DELETE_SYSTEM_PROJECT = 'RECEIVE_DELETE_SYSTEM_PROJECT';

/** プロジェクトの選択 */
export const SET_SELECT_SYSTEM_PROJECT = 'SET_SELECT_SYSTEM_PROJECT';

/** プロジェクトの選択編集 */
export const SET_SYSTEM_PROJECT_DIRTY = 'SET_SYSTEM_PROJECT_DIRTY';

/** プロジェクト情報の削除 */
export const CLEAN_SYSTEM_PROJECT = 'CLEAN_SYSTEM_PROJECT';

/** 管理者メールアドレスの確認 */
export const GETTING_SYSTEM_USER_BY_EMAIL = 'GETTING_SYSTEM_USER_BY_EMAIL';

/** 管理者メールアドレスの確認 */
export const RECEIVE_GET_SYSTEM_USER_BY_EMAIL = 'RECEIVE_GET_SYSTEM_USER_BY_EMAIL';

/** 管理者の確認 */
export const GETTING_SYSTEM_USER_BY_ID = 'GETTING_SYSTEM_USER_BY_ID';

/** 管理者の確認 */
export const RECEIVE_GET_SYSTEM_USER_BY_ID = 'RECEIVE_GET_SYSTEM_USER_BY_ID';

/** 管理者の作成 */
export const CREATING_ADMINISTRATOR = 'CREATING_ADMINISTRATOR';

/** 管理者の作成受信 */
export const RECEIVE_ADMINISTRATOR = 'RECEIVE_ADMINISTRATOR';

//------------------------------------
// システム管理・検査モデル関連
//------------------------------------
/** 検査モデル一覧情報の取得開始 */
export const GETTING_SYSTEM_INSPECTION_MODEL = 'GETTING_SYSTEM_INSPECTION_MODEL';

/** 検査モデル一覧情報の取得受信 */
export const RECEIVE_GET_SYSTEM_INSPECTION_MODEL = 'RECEIVE_GET_SYSTEM_INSPECTION_MODEL';

/** 検査マスター情報の取得開始 */
export const GETTING_SYSTEM_INSPECTION_MASTER = 'GETTING_SYSTEM_INSPECTION_MASTER';

/** 検査マスター情報の取得受信 */
export const RECEIVE_GET_SYSTEM_INSPECTION_MASTER = 'RECEIVE_GET_SYSTEM_INSPECTION_MASTER';

/** 検査モデルの更新 */
export const UPDATING_SYSTEM_INSPECTION_MODEL = 'UPDATING_SYSTEM_INSPECTION_MODEL';

/** 検査モデル更新の取得受信 */
export const RECEIVE_UPDATE_SYSTEM_INSPECTION_MODEL = 'RECEIVE_UPDATE_SYSTEM_INSPECTION_MODEL';

/** 検査マスター情報の作成 */
export const CREATING_SYSTEM_INSPECTION_MASTER = 'CREATING_SYSTEM_INSPECTION_MASTER';

/** 検査マスター情報作成の取得受信 */
export const RECEIVE_CREATE_SYSTEM_INSPECTION_MASTER = 'RECEIVE_CREATE_SYSTEM_INSPECTION_MASTER';

/** 検査マスターの更新 */
export const UPDATING_SYSTEM_INSPECTION_MASTER = 'UPDATING_SYSTEM_INSPECTION_MASTER';

/** 検査マスター更新の取得受信 */
export const RECEIVE_UPDATE_SYSTEM_INSPECTION_MASTER = 'RECEIVE_UPDATE_SYSTEM_INSPECTION_MASTER';

/** 検査マスターの削除 */
export const DELETING_SYSTEM_INSPECTION_MASTER = 'DELETING_SYSTEM_INSPECTION_MASTER';

/** 検査マスター削除の取得受信 */
export const RECEIVE_DELETE_SYSTEM_INSPECTION_MASTER = 'RECEIVE_DELETE_SYSTEM_INSPECTION_MASTER';

/** 検査マスターの選択 */
export const SET_SELECT_SYSTEM_INSPECTION_MODEL = 'SET_SELECT_SYSTEM_INSPECTION_MODEL';

/** 検査マスターの選択編集 */
export const SET_SYSTEM_INSPECTION_MODEL_DIRTY = 'SET_SYSTEM_INSPECTION_MODEL_DIRTY';

/** 検査マスター情報の削除 */
export const CLEAN_SYSTEM_INSPECTION_MODEL = 'CLEAN_SYSTEM_INSPECTION_MODEL';

//------------------------------------
// システム管理・ユーザ関連
//------------------------------------
/** ユーザ情報の取得開始 */
export const GETTING_SYSTEM_USER = 'GETTING_SYSTEM_USER';

/** ユーザ情報の取得受信 */
export const RECEIVE_GET_SYSTEM_USER = 'RECEIVE_GET_SYSTEM_USER';

/** パスワードのリセット */
export const RESET_PASSWORD_SYSTEM_USER = 'RESET_PASSWORD_SYSTEM_USER';

/** パスワードリセットの取得受信 */
export const RECEIVE_RESET_PASSWORD_SYSTEM_USER = 'RECEIVE_RESET_PASSWORD_SYSTEM_USER';

/** ユーザの更新 */
export const UPDATING_SYSTEM_USER = 'UPDATING_SYSTEM_USER';

/** ユーザ更新の取得受信 */
export const RECEIVE_UPDATE_SYSTEM_USER = 'RECEIVE_UPDATE_SYSTEM_USER';

/** ユーザの削除 */
export const DELETING_SYSTEM_USER = 'DELETING_SYSTEM_USER';

/** ユーザ削除の取得受信 */
export const RECEIVE_DELETE_SYSTEM_USER = 'RECEIVE_DELETE_SYSTEM_USER';

/** ユーザの選択 */
export const SET_SELECT_SYSTEM_USER = 'SET_SELECT_SYSTEM_USER';

/** ユーザの選択編集 */
export const SET_SYSTEM_USER_DIRTY = 'SET_SYSTEM_USER_DIRTY';

/** ユーザ情報の削除 */
export const CLEAN_SYSTEM_USER = 'CLEAN_SYSTEM_USER';

/** テストメールの送信 */
export const SENDING_TEST_MAIL = 'SENDING_TEST_MAIL';

/** テストメールの送信結果受信 */
export const RECEIVE_SEND_TEST_MAIL = 'RECEIVE_SEND_TEST_MAIL';

//------------------------------------
// システム管理・システム権限関連
//------------------------------------
/** システム権限の取得開始 */
export const GETTING_SYSTEM_ROLE = 'GETTING_SYSTEM_ROLE';

/** システム権限の取得受信 */
export const RECEIVE_GET_SYSTEM_ROLE = 'RECEIVE_GET_SYSTEM_ROLE';

/** システム権限の作成 */
export const CREATING_SYSTEM_ROLE = 'CREATING_SYSTEM_ROLE';

/** システム権限作成の取得受信 */
export const RECEIVE_CREATE_SYSTEM_ROLE = 'RECEIVE_CREATE_SYSTEM_ROLE';

/** システム権限の更新 */
export const UPDATING_SYSTEM_ROLE = 'UPDATING_SYSTEM_ROLE';

/** システム権限更新の取得受信 */
export const RECEIVE_UPDATE_SYSTEM_ROLE = 'RECEIVE_UPDATE_SYSTEM_ROLE';

/** システム権限の削除 */
export const DELETING_SYSTEM_ROLE = 'DELETING_SYSTEM_ROLE';

/** システム権限削除の取得受信 */
export const RECEIVE_DELETE_SYSTEM_ROLE = 'RECEIVE_DELETE_SYSTEM_ROLE';

/** システム権限の選択 */
export const SET_SELECT_SYSTEM_ROLE = 'SET_SELECT_SYSTEM_ROLE';

/** システム権限の選択編集 */
export const SET_SYSTEM_ROLE_DIRTY = 'SET_SYSTEM_ROLE_DIRTY';

/** システム権限情報の削除 */
export const CLEAN_SYSTEM_ROLE = 'CLEAN_SYSTEM_ROLE';

//------------------------------------
// システム管理・メール設定
//------------------------------------
/** メール設定の取得開始 */
export const GETTING_SYSTEM_MAIL = 'GETTING_SYSTEM_MAIL';

/** メール設定の取得受信 */
export const RECEIVE_GET_SYSTEM_MAIL = 'RECEIVE_GET_SYSTEM_MAIL';

/** メール設定の更新 */
export const UPDATING_SYSTEM_MAIL = 'UPDATING_SYSTEM_MAIL';

/** メール設定更新の取得受信 */
export const RECEIVE_UPDATE_SYSTEM_MAIL = 'RECEIVE_UPDATE_SYSTEM_MAIL';

/** メール設定の選択編集 */
export const SET_SYSTEM_MAIL_DIRTY = 'SET_SYSTEM_MAIL_DIRTY';

/** メール設定の削除 */
export const CLEAN_SYSTEM_MAIL = 'CLEAN_SYSTEM_MAIL';

//------------------------------------
// システム管理・ヘルプ関連
//------------------------------------
/** ヘルプ情報の取得開始 */
export const GETTING_SYSTEM_HELP = 'GETTING_SYSTEM_HELP';

/** ヘルプ情報の取得受信 */
export const RECEIVE_GET_SYSTEM_HELP = 'RECEIVE_GET_SYSTEM_HELP';

/** ヘルプファイルのアップロード開始 */
export const UPLOADING_SYSTE_HELP = 'UPLOADING_SYSTE_HELP';

/** ヘルプファイルのアップロード進捗状況の設定 */
export const SET_UPLOAD_SYSTEM_HELP_PROGRESS = 'SET_UPLOAD_SYSTEM_HELP_PROGRESS';

/** ヘルプファイルのアップロード完了 */
export const SET_UPLOAD_SYSTEM_HELP_COMPLETE = 'SET_UPLOAD_SYSTEM_HELP_COMPLETE';

/** ヘルプ情報の作成 */
export const CREATING_SYSTEM_HELP = 'CREATING_SYSTEM_HELP';

/** ヘルプ情報作成の取得受信 */
export const RECEIVE_CREATE_SYSTEM_HELP = 'RECEIVE_CREATE_SYSTEM_HELP';

/** ヘルプの更新 */
export const UPDATING_SYSTEM_HELP = 'UPDATING_SYSTEM_HELP';

/** ヘルプ更新の取得受信 */
export const RECEIVE_UPDATE_SYSTEM_HELP = 'RECEIVE_UPDATE_SYSTEM_HELP';

/** ヘルプの削除 */
export const DELETING_SYSTEM_HELP = 'DELETING_SYSTEM_HELP';

/** ヘルプ削除の取得受信 */
export const RECEIVE_DELETE_SYSTEM_HELP = 'RECEIVE_DELETE_SYSTEM_HELP';

/** ヘルプの選択 */
export const SET_SELECT_SYSTEM_HELP = 'SET_SELECT_SYSTEM_HELP';

/** ヘルプの選択編集 */
export const SET_SYSTEM_HELP_DIRTY = 'SET_SYSTEM_HELP_DIRTY';

/** ヘルプ情報の削除 */
export const CLEAN_SYSTEM_HELP = 'CLEAN_SYSTEM_HELP';

//------------------------------------
// システム管理・Dangerzone
//------------------------------------
/** キャッシュ再作成の生成開始 */
export const CREATING_CACHE = 'CREATING_CACHE';

/** キャッシュ再作成の生成受信 */
export const RECEIVE_CREATE_CACHE = 'RECEIVE_CREATE_CACHE';

//------------------------------------
// プロジェクト一覧選択
//------------------------------------
/** プロジェクト一覧の取得開始 */
export const GETTING_PROJECTS = 'GETTING_PROJECTS';

/** プロジェクト一覧の取得受信 */
export const RECEIVE_GET_PROJECTS = 'RECEIVE_GET_PROJECTS';

/** プロジェクト詳細の取得開始 */
export const GETTING_PROJECT_INFO = 'GETTING_PROJECT_INFO';

/** プロジェクト詳細の取得受信 */
export const RECEIVE_GET_PROJECT_INFO = 'RECEIVE_GET_PROJECT_INFO';

/** プロジェクト一覧情報の削除 */
export const CLEAN_PROJECTS = 'CLEAN_PROJECTS';

//------------------------------------
// プロジェクト
//------------------------------------
/** プロジェクト詳細の取得開始 */
export const GETTING_PROJECT_DETAIL = 'GETTING_PROJECT_DETAIL';

/** プロジェクト詳細の取得受信 */
export const RECEIVE_GET_PROJECT_DETAIL = 'RECEIVE_GET_PROJECT_DETAIL';

/** プロジェクトの設定 */
export const SET_PROJECT = 'SET_PROJECT';

/** プロジェクト情報の削除 */
export const CLEAN_PROJECT = 'CLEAN_PROJECT';

/** 検査の設定 */
export const SET_INSPECTION = 'SET_INSPECTION';

/** プロジェクトACLの取得開始 */
export const GETTING_PROJECT_ACL = 'GETTING_PROJECT_ACL';

/** プロジェクトACLの取得 */
export const RECEIVE_GET_PROJECT_ACL = 'RECEIVE_GET_PROJECT_ACL';

//------------------------------------
// 通知関連
//------------------------------------
/** 進行状況取得し、通知Popperを表示する */
export const GETTING_PROGRESS = 'GETTING_PROGRESS';

/** 進行状況取得完了 */
export const RECEIVE_GET_PROGRESS = 'RECEIVE_GET_PROGRESS';

/** 進行状況削除開始 */
export const DELETING_PROGRESS = 'DELETING_PROGRESS';

/** 進行状況削除完了 */
export const RECEIVE_DELETE_PROGRESS = 'RECEIVE_DELETE_PROGRESS';

/** 進行キャンセル開始 */
export const CANCELING_PROGRESS = 'CANCELING_PROGRESS';

/** 進行キャンセル完了 */
export const RECEIVE_CANCEL_PROGRESS = 'RECEIVE_CANCEL_PROGRESS';

/** 通知Popperを非表示にする */
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION';

//------------------------------------
// マップ表示
//------------------------------------
/** ツリーの設定 */
export const SET_TREE = 'SET_TREE';

/** ノードの設定 */
export const SET_NODE = 'SET_NODE';

/** マップアイコン取得開始 */
export const GETTING_MAP_ICON = 'GETTING_MAP_ICON';

/** マップアイコン詳細取得開始 */
export const GETTING_ICON_DETAIL = 'GETTING_ICON_DETAIL';

/** マップアイコン詳細一覧取得開始 */
export const GETTING_ICON_DETAIL_LIST = 'GETTING_ICON_DETAIL_LIST';

/** アイコン作成開始 */
export const CREATING_ICON = 'CREATING_ICON';

/** アイコン作成完了 */
export const RECEIVE_CREATE_ICON = 'RECEIVE_CREATE_ICON';

/** アイコン一括作成開始 */
export const CREATING_ICONS = 'CREATING_ICONS';

/** アイコン一括作成完了 */
export const RECEIVE_CREATE_ICONS = 'RECEIVE_CREATE_ICONS';

/** アイコン更新開始 */
export const UPDATING_ICON = 'UPDATING_ICON';

/** アイコン更新完了 */
export const RECEIVE_UPDATE_ICON = 'RECEIVE_UPDATE_ICON';

/** アイコン一括更新開始 */
export const UPDATING_ICONS = 'UPDATING_ICONS';

/** アイコン一括更新完了 */
export const RECEIVE_UPDATE_ICONS = 'RECEIVE_UPDATE_ICONS';

/** マップの設定 */
export const SET_MAP = 'SET_MAP';

/** 表示情報の設定 */
export const SET_LAYER = 'SET_LAYER';

/** 工区の設定 */
export const SET_AREA = 'SET_AREA';

/** アイコン表示フィルターの設定 */
export const SET_VISIBLE = 'SET_VISIBLE';

/** アイコン表示色の設定 */
export const SET_ICON_COLOR = 'SET_ICON_COLOR';

/** アイコンサイズの設定 */
export const SET_ICON_SIZE = 'SET_ICON_SIZE';

/** アイコンの設定 */
export const SET_ICONS = 'SET_ICONS';

/** マップアイコン詳細の設定 */
export const RECEIVE_ICON_DETAIL = 'RECEIVE_ICON_DETAIL';

/** マップアイコン詳細一覧の設定 */
export const RECEIVE_ICON_DETAIL_LIST = 'RECEIVE_ICON_DETAIL_LIST';

/** マップ情報の削除 */
export const CLEAN_MAP = 'CLEAN_MAP';

//------------------------------------
// 進捗表関連Action
//------------------------------------
/** 進捗表ツリーの設定 */
export const SET_PROGRESS_TREE = 'SET_PROGRESS_TREE';

/** 進捗表ノードの設定 */
export const SET_PROGRESS_NODE = 'SET_PROGRESS_NODE';

/** 進捗表マップの設定 */
export const SET_PROGRESS_MAP = 'SET_PROGRESS_MAP';

/** 進捗表種類の設定 */
export const SET_PROGRESS_TYPE = 'SET_PROGRESS_TYPE';

/** アイコン情報の取得開始 */
export const GETTING_PROGRESS_ICON = 'GETTING_PROGRESS_ICON';

/** アイコン情報の取得受信 */
export const RECEIVE_GET_PROGRESS_ICON = 'RECEIVE_GET_PROGRESS_ICON';

/** アイコン情報のクリア */
export const CLEAR_PROGRESS_ICON = 'CLEAR_PROGRESS_ICON';

/** 進捗表情報の削除 */
export const CLEAN_PROGRESS = 'CLEAN_PROGRESS';

/** 工種グループの取得開始 */
export const GETTING_PROGRESS_WORKGROUP = 'GETTING_PROGRESS_WORKGROUP';

/** 工種グループの取得受信 */
export const RECEIVE_GET_PROGRESS_WORKGROUP = 'RECEIVE_GET_PROGRESS_WORKGROUP';

/** 工種の取得開始 */
export const GETTING_PROGRESS_WORK = 'GETTING_PROGRESS_WORK';

/** 工種の取得受信 */
export const RECEIVE_GET_PROGRESS_WORK = 'RECEIVE_GET_PROGRESS_WORK';

/** 所属の取得開始 */
export const GETTING_PROGRESS_ORGANIZATION = 'GETTING_PROGRESS_ORGANIZATION';

/** 所属の取得受信 */
export const RECEIVE_GET_PROGRESS_ORGANIZATION = 'RECEIVE_GET_PROGRESS_ORGANIZATION';

//------------------------------------
// 帳票関連Action
//------------------------------------
/** 帳票テンプレートの取得開始 */
export const GETTING_REPORT_TEMPLATE = 'GETTING_REPORT_TEMPLATE';

/** 帳票テンプレートの取得受信 */
export const RECEIVE_GET_REPORT_TEMPLATE = 'RECEIVE_GET_REPORT_TEMPLATE';

/** 帳票ダウンロードの取得開始 */
export const GETTING_REPORT_DOWNLOAD = 'GETTING_REPORT_DOWNLOAD';

/** 帳票ダウンロードの取得受信 */
export const RECEIVE_GET_REPORT_DOWNLOAD = 'RECEIVE_GET_REPORT_DOWNLOAD';

/** 工種の取得開始 */
export const GETTING_REPORT_WORK = 'GETTING_REPORT_WORK';

/** 工種の取得受信 */
export const RECEIVE_GET_REPORT_WORK = 'RECEIVE_GET_REPORT_WORK';

/** アイコン取得開始 */
export const GETTING_REPORT_ICON = 'GETTING_REPORT_ICON';

/** アイコン取得受信 */
export const RECEIVE_GET_REPORT_ICON = 'RECEIVE_GET_REPORT_ICON';

/** 帳票生成開始 */
export const CREATING_REPORT = 'CREATING_REPORT';

/** 帳票生成進行状況 */
export const SET_CREATE_REPORT_PROGRESS = 'SET_CREATE_REPORT_PROGRESS';

/** 帳票生成完了受信 */
export const RECEIVE_CREATE_REPORT = 'RECEIVE_CREATE_REPORT';

/** 帳票の削除開始 */
export const DELETING_REPORT_DOWNLOAD = 'DELETING_REPORT_DOWNLOAD';

/** 帳票の削除受信 */
export const RECEIVE_DELETE_REPORT_DOWNLOAD = 'RECEIVE_DELETE_REPORT_DOWNLOAD';

/** 帳票テンプレートの選択 */
export const SET_SELECT_REPORT_TEMPLATE = 'SET_SELECT_REPORT_TEMPLATE';

/** 帳票情報の削除 */
export const CLEAN_REPORT = 'CLEAN_REPORT';

//------------------------------------
// 写真関連Action
//------------------------------------
/** 写真件数取得開始 */
export const GETTING_PHOTO_COUNT = 'GETTING_PHOTO_COUNT';

/** 写真件数受信 */
export const RECEIVE_GET_PHOTO_COUNT = 'RECEIVE_GET_PHOTO_COUNT';

/** 写真取得開始 */
export const GETTING_PHOTO = 'GETTING_PHOTO';

/** 写真受信 */
export const RECEIVE_GET_PHOTO = 'RECEIVE_GET_PHOTO';

/** 写真ダウンロードの取得開始 */
export const GETTING_PHOTO_DOWNLOAD = 'GETTING_PHOTO_DOWNLOAD';

/** 写真ダウンロードの取得受信 */
export const RECEIVE_GET_PHOTO_DOWNLOAD = 'RECEIVE_GET_PHOTO_DOWNLOAD';

/** 写真ダウンロード生成開始 */
export const CREATING_PHOTO_DOWNLOAD = 'CREATING_PHOTO_DOWNLOAD';

/** 写真ダウンロード生成進行状況 */
export const SET_CREATE_PHOTO_DOWNLOAD_PROGRESS = 'SET_CREATE_PHOTO_DOWNLOAD_PROGRESS';

/** 写真ダウンロード生成完了受信 */
export const RECEIVE_CREATE_PHOTO_DOWNLOAD = 'RECEIVE_CREATE_PHOTO_DOWNLOAD';

/** 写真ダウンロードの削除開始 */
export const DELETING_PHOTO_DOWNLOAD = 'DELETING_PHOTO_DOWNLOAD';

/** 写真ダウンロードの削除受信 */
export const RECEIVE_DELETE_PHOTO_DOWNLOAD = 'RECEIVE_DELETE_PHOTO_DOWNLOAD';

/** 蔵衛門ボタンの有効設定 */
export const SET_ENABLE_KURAEMON = 'SET_ENABLE_KURAEMON';

/** 写真の削除 */
export const CLEAN_PHOTO = 'CLEAN_PHOTO';

//------------------------------------
// アイコン取得
//------------------------------------
/** アイコン取得開始 */
export const GETTING_ICON = 'GETTING_ICON';

/** アイコン取得完了 */
export const RECEIVE_GET_ICON = 'RECEIVE_GET_ICON';

//------------------------------------
// プロジェクト管理・プロジェクト情報設定
//------------------------------------
/** プロジェクト情報の取得開始 */
export const GETTING_ADMIN_PROJECT = 'GETTING_ADMIN_PROJECT';

/** プロジェクト情報の取得受信 */
export const RECEIVE_GET_ADMIN_PROJECT = 'RECEIVE_GET_ADMIN_PROJECT';

/** プロジェクト編集ユーザ一覧取得開始 */
export const GETTING_ADMIN_PROJECT_USERS = 'GETTING_ADMIN_PROJECT_USERS';

/** プロジェクト編集ユーザ一覧の取得受信 */
export const RECEIVE_GET_ADMIN_PROJECT_USERS = 'RECEIVE_GET_ADMIN_PROJECT_USERS';

/** プロジェクトの更新 */
export const UPDATING_ADMIN_PROJECT = 'UPDATING_ADMIN_PROJECT';

/** プロジェクト情報の取得受信 */
export const RECEIVE_UPDATE_ADMIN_PROJECT = 'RECEIVE_UPDATE_ADMIN_PROJECT';

/** プロジェクト情報の選択編集 */
export const SET_ADMIN_PROJECT_DIRTY = 'SET_ADMIN_PROJECT_DIRTY';

/** プロジェクト情報の削除 */
export const CLEAN_ADMIN_PROJECT = 'CLEAN_ADMIN_PROJECT';

//------------------------------------
// プロジェクト管理・通り芯設定関連
//------------------------------------
/** 通り芯情報の取得開始 */
export const GETTING_ADMIN_GUIDE = 'GETTING_ADMIN_GUIDE';

/** 通り芯情報の取得受信 */
export const RECEIVE_GET_ADMIN_GUIDE = 'RECEIVE_GET_ADMIN_GUIDE';

/** 通り芯情報の送信開始 */
export const UPDATING_ADMIN_GUIDE = 'UPDATING_ADMIN_GUIDE';

/** 通り芯情報の送信結果受信 */
export const RECEIVE_UPDATE_ADMIN_GUIDE = 'RECEIVE_UPDATE_ADMIN_GUIDE';

/** 水平通り芯の作成 */
export const CREATE_HORIZONTAL_ADMIN_GUIDE = 'CREATE_HORIZONTAL_ADMIN_GUIDE';

/** 垂直通り芯の作成 */
export const CREATE_VERTICAL_ADMIN_GUIDE = 'CREATE_VERTICAL_ADMIN_GUIDE';

/** ２点指定通り芯の作成 */
export const CREATE_DIAGONAL_ADMIN_GUIDE = 'CREATE_DIAGONAL_ADMIN_GUIDE';

/** 水平通り芯の更新 */
export const UPDATE_HORIZONTAL_ADMIN_GUIDE = 'UPDATE_HORIZONTAL_ADMIN_GUIDE';

/** 垂直通り芯の更新 */
export const UPDATE_VERTICAL_ADMIN_GUIDE = 'UPDATE_VERTICAL_ADMIN_GUIDE';

/** ２点指定通り芯の更新 */
export const UPDATE_DIAGONAL_ADMIN_GUIDE = 'UPDATE_DIAGONAL_ADMIN_GUIDE';

/** 水平通り芯の削除 */
export const DELETE_HORIZONTAL_ADMIN_GUIDE = 'DELETE_HORIZONTAL_ADMIN_GUIDE';

/** 垂直通り芯の削除 */
export const DELETE_VERTICAL_ADMIN_GUIDE = 'DELETE_VERTICAL_ADMIN_GUIDE';

/** ２点指定通り芯の削除 */
export const DELETE_DIAGONAL_ADMIN_GUIDE = 'DELETE_DIAGONAL_ADMIN_GUIDE';

/** 通り芯名称の更新 */
export const UPDATE_ADMIN_GUIDE_NAME = 'UPDATE_ADMIN_GUIDE_NAME';

/** 水平通り芯の一括更新 */
export const UPDATE_HORIZONTAL_ADMIN_GUIDE_LIST = 'UPDATE_HORIZONTAL_ADMIN_GUIDE_LIST';

/** 垂直通り芯の一括更新 */
export const UPDATE_VERTICAL_ADMIN_GUIDE_LIST = 'UPDATE_VERTICAL_ADMIN_GUIDE_LIST';

/** 2点指定通り芯の一括更新 */
export const UPDATE_DIAGONAL_ADMIN_GUIDE_LIST = 'UPDATE_DIAGONAL_ADMIN_GUIDE_LIST';

/** 通り芯情報の削除 */
export const CLEAN_ADMIN_GUIDE = 'CLEAN_ADMIN_GUIDE';

//------------------------------------
// プロジェクト管理・階関連
//------------------------------------
/** 階情報の取得開始 */
export const GETTING_ADMIN_FLOOR = 'GETTING_ADMIN_FLOOR';

/** 階情報の取得受信 */
export const RECEIVE_GET_ADMIN_FLOOR = 'RECEIVE_GET_ADMIN_FLOOR';

/** 階情報の送信開始 */
export const UPDATING_ADMIN_FLOOR = 'UPDATING_ADMIN_FLOOR';

/** 階情報の送信結果受信 */
export const RECEIVE_UPDATE_ADMIN_FLOOR = 'RECEIVE_UPDATE_ADMIN_FLOOR';

/** 階の作成 */
export const CREATE_ADMIN_FLOOR_HEIGHT = 'CREATE_ADMIN_FLOOR_HEIGHT';

/** 階の更新 */
export const UPDATE_ADMIN_FLOOR_HEIGHT = 'UPDATE_ADMIN_FLOOR_HEIGHT';

/** 階の削除 */
export const DELETE_ADMIN_FLOOR_HEIGHT = 'DELETE_ADMIN_FLOOR_HEIGHT';

/** 階名称の更新 */
export const UPDATE_ADMIN_FLOOR_NAME = 'UPDATE_ADMIN_FLOOR_NAME';

/** 階の一括更新 */
export const UPDATE_ADMIN_FLOOR_HEIGHT_LIST = 'UPDATE_ADMIN_FLOOR_HEIGHT_LIST';

/** 階情報の削除 */
export const CLEAN_ADMIN_FLOOR = 'CLEAN_ADMIN_FLOOR';

//------------------------------------
// プロジェクト管理・検査管理関連
//------------------------------------
/** 検査一覧の取得開始 */
export const GETTING_ADMIN_INSPECTION = 'GETTING_ADMIN_INSPECTION';

/** 検査情報の取得受信 */
export const RECEIVE_GET_ADMIN_INSPECTION = 'RECEIVE_GET_ADMIN_INSPECTION';

/** 検査モデル一覧情報の取得開始 */
export const GETTING_ADMIN_INSPECTION_MODEL = 'GETTING_ADMIN_INSPECTION_MODEL';

/** 検査モデル一覧情報の取得受信 */
export const RECEIVE_GET_ADMIN_INSPECTION_MODEL = 'RECEIVE_GET_ADMIN_INSPECTION_MODEL';

/** 検査マスター一覧情報の取得開始 */
export const GETTING_ADMIN_INSPECTION_MASTER = 'GETTING_ADMIN_INSPECTION_MASTER';

/** 検査マスター一覧情報の取得受信 */
export const RECEIVE_GET_ADMIN_INSPECTION_MASTER = 'RECEIVE_GET_ADMIN_INSPECTION_MASTER';

/** 検査の作成 */
export const CREATING_ADMIN_INSPECTION = 'CREATING_ADMIN_INSPECTION';

/** 検査情報の取得受信 */
export const RECEIVE_CREATE_ADMIN_INSPECTION = 'RECEIVE_CREATE_ADMIN_INSPECTION';

/** 検査の更新 */
export const UPDATING_ADMIN_INSPECTION = 'UPDATING_ADMIN_INSPECTION';

/** 検査情報の取得受信 */
export const RECEIVE_UPDATE_ADMIN_INSPECTION = 'RECEIVE_UPDATE_ADMIN_INSPECTION';

/** 検査の更新 */
export const UPDATING_ADMIN_INSPECTIONS = 'UPDATING_ADMIN_INSPECTIONS';

/** 検査情報の取得受信 */
export const RECEIVE_UPDATE_ADMIN_INSPECTIONS = 'RECEIVE_UPDATE_ADMIN_INSPECTIONS';

/** 検査の削除 */
export const DELETING_ADMIN_INSPECTION = 'DELETING_ADMIN_INSPECTION';

/** 検査情報の取得受信 */
export const RECEIVE_DELETE_ADMIN_INSPECTION = 'RECEIVE_DELETE_ADMIN_INSPECTION';

/** 検査の選択 */
export const SET_SELECT_ADMIN_INSPECTION = 'SET_SELECT_ADMIN_INSPECTION';

/** 検査属性の設定 */
export const SET_ADMIN_INSPECTION_PROPERTY = 'SET_ADMIN_INSPECTION_PROPERTY';

/** マスター属性の設定 */
export const SET_ADMIN_MASTER_PROPERTY = 'SET_ADMIN_MASTER_PROPERTY';

/** 検査の選択編集 */
export const SET_ADMIN_INSPECTION_DIRTY = 'SET_ADMIN_INSPECTION_DIRTY';

/** 検査の有効設定 */
export const SET_ADMIN_INSPECTION_VALID = 'SET_ADMIN_INSPECTION_VALID';

/** 検査情報の削除 */
export const CLEAN_ADMIN_INSPECTION = 'CLEAN_ADMIN_INSPECTION';

//------------------------------------
// プロジェクト管理・場所プロパティ関連
//------------------------------------
/** 場所プロパティ情報の取得開始 */
export const GETTING_ADMIN_LOCATION = 'GETTING_ADMIN_LOCATION';

/** 場所プロパティ情報の取得受信 */
export const RECEIVE_GET_ADMIN_LOCATION = 'RECEIVE_GET_ADMIN_LOCATION';

/** 場所プロパティの作成 */
export const CREATING_ADMIN_LOCATION = 'CREATING_ADMIN_LOCATION';

/** 場所プロパティ情報の取得受信 */
export const RECEIVE_CREATE_ADMIN_LOCATION = 'RECEIVE_CREATE_ADMIN_LOCATION';

/** 場所プロパティの更新 */
export const UPDATING_ADMIN_LOCATION = 'UPDATING_ADMIN_LOCATION';

/** 場所プロパティ情報の取得受信 */
export const RECEIVE_UPDATE_ADMIN_LOCATION = 'RECEIVE_UPDATE_ADMIN_LOCATION';

/** 場所プロパティの削除 */
export const DELETING_ADMIN_LOCATION = 'DELETING_ADMIN_LOCATION';

/** 場所プロパティ情報の取得受信 */
export const RECEIVE_DELETE_ADMIN_LOCATION = 'RECEIVE_DELETE_ADMIN_LOCATION';

/** 場所プロパティの選択 */
export const SET_SELECT_ADMIN_LOCATION = 'SET_SELECT_ADMIN_LOCATION';

/** 場所プロパティの選択編集 */
export const SET_ADMIN_LOCATION_DIRTY = 'SET_ADMIN_LOCATION_DIRTY';

/** 場所プロパティ情報の削除 */
export const CLEAN_ADMIN_LOCATION = 'CLEAN_ADMIN_LOCATION';

//------------------------------------
// プロジェクト管理・図面関連
//------------------------------------
/** 資料情報の取得開始 */
export const GETTING_ADMIN_MAPFILE = 'GETTING_ADMIN_MAPFILE';

/** 図面情報の取得受信 */
export const RECEIVE_GET_ADMIN_MAPFILE = 'RECEIVE_GET_ADMIN_MAPFILE';

/** 図面情報の取得開始 */
export const CREATING_ADMIN_MAPFILE = 'CREATING_ADMIN_MAPFILE';

/** 図面アップロード進捗状況の設定 */
export const SET_UPLOAD_ADMIN_MAPFILE_PROGRESS = 'SET_UPLOAD_ADMIN_MAPFILE_PROGRESS';

/** 図面アップロードの完了 */
export const SET_UPLOAD_ADMIN_MAPFILE_COMPLETE = 'SET_UPLOAD_ADMIN_MAPFILE_COMPLETE';

/** 図面登録処理進捗状況の設定 */
export const SET_PROCESS_ADMIN_MAPFILE_PROGRESS = 'SET_PROCESS_ADMIN_MAPFILE_PROGRESS';

/** 図面登録処理進の完了 */
export const SET_PROCESS_ADMIN_MAPFILE_COMPLETE = 'SET_PROCESS_ADMIN_MAPFILE_COMPLETE';

/** 図面情報の取得受信 */
export const RECEIVE_CREATE_ADMIN_MAPFILE = 'RECEIVE_CREATE_ADMIN_MAPFILE';

/** 図面の削除 */
export const DELETING_ADMIN_MAPFILE = 'DELETING_ADMIN_MAPFILE';

/** 図面情報の取得受信 */
export const RECEIVE_DELETE_ADMIN_MAPFILE = 'RECEIVE_DELETE_ADMIN_MAPFILE';

/** 図面情報のバルクアップロード */
export const UPLOADING_ADMIN_MAPFILE = 'UPLOADING_ADMIN_MAPFILE';

/** 図面のアップロード進捗状況更新 */
export const SET_UPLOAD_PROGRESS_ADMIN_MAPFILE = 'SET_UPLOAD_PROGRESS_ADMIN_MAPFILE';

/** 図面のアップロード進捗完了 */
export const SET_UPLOAD_COMPLETE_ADMIN_MAPFILE = 'SET_UPLOAD_COMPLETE_ADMIN_MAPFILE';

/** 図面の選択 */
export const SET_SELECT_ADMIN_MAPFILE = 'SET_SELECT_ADMIN_MAPFILE';

/** 図面情報の削除 */
export const CLEAN_ADMIN_MAPFILE = 'CLEAN_ADMIN_MAPFILE';

//------------------------------------
// プロジェクト管理・資料関連
//------------------------------------
/** 資料情報の取得開始 */
export const GETTING_ADMIN_RESFILE = 'GETTING_ADMIN_RESFILE';

/** 資料情報の取得受信 */
export const RECEIVE_GET_ADMIN_RESFILE = 'RECEIVE_GET_ADMIN_RESFILE';

/** 資料情報の取得開始 */
export const CREATING_ADMIN_RESFILE = 'CREATING_ADMIN_RESFILE';

/** 資料アップロード進捗状況の設定 */
export const SET_UPLOAD_ADMIN_RESFILE_PROGRESS = 'SET_UPLOAD_ADMIN_RESFILE_PROGRESS';

/** 資料アップロードの完了 */
export const SET_UPLOAD_ADMIN_RESFILE_COMPLETE = 'SET_UPLOAD_ADMIN_RESFILE_COMPLETE';

/** 資料登録処理進捗状況の設定 */
export const SET_PROCESS_ADMIN_RESFILE_PROGRESS = 'SET_PROCESS_ADMIN_RESFILE_PROGRESS';

/** 資料登録処理進の完了 */
export const SET_PROCESS_ADMIN_RESFILE_COMPLETE = 'SET_PROCESS_ADMIN_RESFILE_COMPLETE';

/** 資料情報の取得受信 */
export const RECEIVE_CREATE_ADMIN_RESFILE = 'RECEIVE_CREATE_ADMIN_RESFILE';

/** 資料の削除 */
export const DELETING_ADMIN_RESFILE = 'DELETING_ADMIN_RESFILE';

/** 資料情報の取得受信 */
export const RECEIVE_DELETE_ADMIN_RESFILE = 'RECEIVE_DELETE_ADMIN_RESFILE';

/** 資料情報のバルクアップロード */
export const UPLOADING_ADMIN_RESFILE = 'UPLOADING_ADMIN_RESFILE';

/** 資料のアップロード進捗状況更新 */
export const SET_UPLOAD_PROGRESS_ADMIN_RESFILE = 'SET_UPLOAD_PROGRESS_ADMIN_RESFILE';

/** 資料のアップロード進捗完了 */
export const SET_UPLOAD_COMPLETE_ADMIN_RESFILE = 'SET_UPLOAD_COMPLETE_ADMIN_RESFILE';

/** 資料の選択 */
export const SET_SELECT_ADMIN_RESFILE = 'SET_SELECT_ADMIN_RESFILE';

/** 資料情報の削除 */
export const CLEAN_ADMIN_RESFILE = 'CLEAN_ADMIN_RESFILE';

//------------------------------------
// プロジェクト管理・マップ関連
//------------------------------------
/** マップ情報の取得開始 */
export const GETTING_ADMIN_MAP = 'GETTING_ADMIN_MAP';

/** マップ情報の取得受信 */
export const RECEIVE_GET_ADMIN_MAP = 'RECEIVE_GET_ADMIN_MAP';

/** マップ作成開始 */
export const CREATING_ADMIN_MAP_ASYNC = 'CREATING_ADMIN_MAP_ASYNC';

/** マップ作成完了 */
export const RECEIVE_CREATE_ADMIN_MAP_ASYNC = 'RECEIVE_CREATE_ADMIN_MAP_ASYNC';

/** マップコピー開始 */
export const COPYING_ADMIN_MAP = 'COPYING_ADMIN_MAP';

/** マップコピー完了 */
export const RECEIVE_COPY_ADMIN_MAP = 'RECEIVE_COPY_ADMIN_MAP';

/** マップの更新 */
export const UPDATING_ADMIN_MAP = 'UPDATING_ADMIN_MAP';

/** マップ情報の更新完了 */
export const RECEIVE_UPDATE_ADMIN_MAP = 'RECEIVE_UPDATE_ADMIN_MAP';

/** マップの非同期更新 */
export const UPDATING_ADMIN_MAP_ASYNC = 'UPDATING_ADMIN_MAP_ASYNC';

/** マップの非同期更新完了 */
export const RECEIVE_UPDATE_ADMIN_MAP_ASYNC = 'RECEIVE_UPDATE_ADMIN_MAP_ASYNC';

/** マップの非同期インポート */
export const IMPORTING_ADMIN_MAP_ASYNC = 'IMPORTING_ADMIN_MAP_ASYNC';

/** マップの非同期インポート完了 */
export const RECEIVE_IMPORT_ADMIN_MAP_ASYNC = 'RECEIVE_IMPORT_ADMIN_MAP_ASYNC';

/** マップ処理進行状況の設定 */
export const SET_ADMIN_MAP_PROGRESS = 'SET_ADMIN_MAP_PROGRESS';

/** マップの削除 */
export const DELETING_ADMIN_MAP = 'DELETING_ADMIN_MAP';

/** マップ情報の取得受信 */
export const RECEIVE_DELETE_ADMIN_MAP = 'RECEIVE_DELETE_ADMIN_MAP';

/** マップの選択 */
export const SET_SELECT_ADMIN_MAP = 'SET_SELECT_ADMIN_MAP';

/** クリッピングの設定 */
export const SET_CLIP_ADMIN_MAP = 'SET_CLIP_ADMIN_MAP';

/** 制御点0の設定 */
export const SET_CP0_ADMIN_MAP = 'SET_CP0_ADMIN_MAP';

/** 制御点1の設定 */
export const SET_CP1_ADMIN_MAP = 'SET_CP1_ADMIN_MAP';

/** 回転角の設定 */
export const SET_ROTATION_ADMIN_MAP = 'SET_ROTATION_ADMIN_MAP';

/** 通り芯の選択 */
export const SET_GUIDE_ADMIN_MAP = 'SET_GUIDE_ADMIN_MAP';

/** マップの選択編集 */
export const SET_ADMIN_MAP_DIRTY = 'SET_ADMIN_MAP_DIRTY';

/** マップ情報の削除 */
export const CLEAN_ADMIN_MAP = 'CLEAN_ADMIN_MAP';

/**  */
export const SET_ACTIVE_SCREEN_MAP = 'SET_ACTIVE_SCREEN_MAP';

/** */
export const SET_CURRENT_MAP_PATH = 'SET_CURRENT_MAP_PATH';

/** */
export const SET_MAP_BASE_URL = 'SET_MAP_BASE_URL';

/** */
export const SET_SELECTED_AXIS = 'SET_SELECTED_AXIS';

/** */
export const SET_MAP_IMAGE_DATA = 'SET_MAP_IMAGE_DATA';

/** */
export const SET_DETECTED_LINE_DATA = 'SET_DETECTED_LINE_DATA';

/** */
export const SET_DENSITY_DATA = 'SET_DENSITY_DATA';

/** */
export const SET_SCALED_LINE_DATA = 'SET_SCALED_LINE_DATA';

/** */
export const SET_MOVED_LINE_DATA = 'SET_MOVED_LINE_DATA';

/** */
export const SET_SELECTED_LINE_DATA = 'SET_SELECTED_LINE_DATA';

/** */
export const SET_SELECTED_HAVE_TITLE = 'SET_SELECTED_HAVE_TITLE';

/** */
export const SET_TITLE_BORDERS = 'SET_TITLE_BORDERS';

/** */
export const SET_CURRENT_CLICKED_POSITION = 'SET_CURRENT_CLICKED_POSITION';

/** */
export const SET_DRAWING_DATA = 'SET_DRAWING_DATA';

/** */
export const SAVE_CROSS_SECTION_DATA = 'SAVE_CROSS_SECTION_DATA';

/** */
export const CLEAR_MAP_IMAGE_DATA = 'CLEAR_MAP_IMAGE_DATA';

/** */
export const CLEAN_ADMIN_INSPECTION_MAP = 'CLEAN_ADMIN_INSPECTION_MAP';

//------------------------------------
// プロジェクト管理・ツリー関連
//------------------------------------
/** ツリー情報の取得開始 */
export const GETTING_ADMIN_TREE = 'GETTING_ADMIN_TREE';

/** ツリー情報の取得受信 */
export const RECEIVE_GET_ADMIN_TREE = 'RECEIVE_GET_ADMIN_TREE';

/** ツリー情報の作成開始 */
export const CREATING_ADMIN_TREE = 'CREATING_ADMIN_TREE';

/** ツリー情報の更新開始 */
export const UPDATING_ADMIN_TREE_SELECT_BOX = 'UPDATING_ADMIN_TREE_SELECT_BOX';

/** ツリー情報の取得受信 */
export const RECEIVE_CREATE_ADMIN_TREE = 'RECEIVE_CREATE_ADMIN_TREE';

/** ツリー情報の取得受信 */
export const RECEIVE_UPDATING_ADMIN_TREE_SELECT_BOX = 'RECEIVE_UPDATING_ADMIN_TREE_SELECT_BOX';

/** ツリーの削除 */
export const DELETING_ADMIN_TREE = 'DELETING_ADMIN_TREE';

/** ツリー情報の取得受信 */
export const RECEIVE_DELETE_ADMIN_TREE = 'RECEIVE_DELETE_ADMIN_TREE';

/** ノード情報の作成開始 */
export const CREATING_ADMIN_TREE_NODE = 'CREATING_ADMIN_TREE_NODE';

/** ノード情報の取得受信 */
export const RECEIVE_CREATE_ADMIN_TREE_NODE = 'RECEIVE_CREATE_ADMIN_TREE_NODE';

/** ノードの更新 */
export const UPDATING_ADMIN_TREE_NODE = 'UPDATING_ADMIN_TREE_NODE';

/** ノード情報の取得受信 */
export const RECEIVE_UPDATE_ADMIN_TREE_NODE = 'RECEIVE_UPDATE_ADMIN_TREE_NODE';

/** ノード更新完了 */
export const COMPLETE_UPDATE_ADMIN_TREE_NODE = 'COMPLETE_UPDATE_ADMIN_TREE_NODE';

/** ノードの削除 */
export const DELETING_ADMIN_TREE_NODE = 'DELETING_ADMIN_TREE_NODE';

/** ノード情報の取得受信 */
export const RECEIVE_DELETE_ADMIN_TREE_NODE = 'RECEIVE_DELETE_ADMIN_TREE_NODE';

/** ツリーの選択 */
export const SET_SELECT_ADMIN_TREE = 'SET_SELECT_ADMIN_TREE';

/** ノードの選択 */
export const SET_SELECT_ADMIN_TREE_NODE = 'SET_SELECT_ADMIN_TREE_NODE';

/** ノードの選択編集 */
export const SET_ADMIN_TREE_DIRTY = 'SET_ADMIN_TREE_DIRTY';

/** ノードの選択編集 */
export const SET_ADMIN_TREE_SELECT_BOX_DIRTY = 'SET_ADMIN_TREE_SELECT_BOX_DIRTY';

/** ノードの選択編集 */
export const SET_ADMIN_TREE_NODE_DIRTY = 'SET_ADMIN_TREE_NODE_DIRTY';

/** ツリー情報の削除 */
export const CLEAN_ADMIN_TREE = 'CLEAN_ADMIN_TREE';

//------------------------------------
// プロジェクト管理・帳票テンプレート関連
//------------------------------------
/** 帳票テンプレート情報の取得開始 */
export const GETTING_ADMIN_TEMPLATE = 'GETTING_ADMIN_TEMPLATE';

/** 帳票テンプレート情報の取得受信 */
export const RECEIVE_GET_ADMIN_TEMPLATE = 'RECEIVE_GET_ADMIN_TEMPLATE';

/** 帳票テンプレートファイルのアップロード開始 */
export const UPLOADING_ADMIN_TEMPLATE = 'UPLOADING_ADMIN_TEMPLATE';

/** 帳票テンプレートファイルのアップロード進捗状況の設定 */
export const SET_UPLOAD_ADMIN_TEMPLATE_PROGRESS = 'SET_UPLOAD_ADMIN_TEMPLATE_PROGRESS';

/** 帳票テンプレートファイルのアップロード完了 */
export const SET_UPLOAD_ADMIN_TEMPLATE_COMPLETE = 'SET_UPLOAD_ADMIN_TEMPLATE_COMPLETE';

/** 帳票テンプレート情報の作成 */
export const CREATING_ADMIN_TEMPLATE = 'CREATING_ADMIN_TEMPLATE';

/** 帳票テンプレート情報作成の取得受信 */
export const RECEIVE_CREATE_ADMIN_TEMPLATE = 'RECEIVE_CREATE_ADMIN_TEMPLATE';

/** 帳票テンプレート更新 */
export const UPDATING_ADMIN_TEMPLATE = 'UPDATING_ADMIN_TEMPLATE';

/** 帳票テンプレート更新の取得受信 */
export const RECEIVE_UPDATE_ADMIN_TEMPLATE = 'RECEIVE_UPDATE_ADMIN_TEMPLATE';

/** 帳票テンプレート更新 */
export const UPDATING_ADMIN_TEMPLATES = 'UPDATING_ADMIN_TEMPLATES';

/** 帳票テンプレート更新の取得受信 */
export const RECEIVE_UPDATE_ADMIN_TEMPLATES = 'RECEIVE_UPDATE_ADMIN_TEMPLATES';

/** 帳票テンプレート削除 */
export const DELETING_ADMIN_TEMPLATE = 'DELETING_ADMIN_TEMPLATE';

/** 帳票テンプレート削除の取得受信 */
export const RECEIVE_DELETE_ADMIN_TEMPLATE = 'RECEIVE_DELETE_ADMIN_TEMPLATE';

/** 帳票テンプレート選択 */
export const SET_SELECT_ADMIN_TEMPLATE = 'SET_SELECT_ADMIN_TEMPLATE';

/** 帳票テンプレート選択編集 */
export const SET_ADMIN_TEMPLATE_DIRTY = 'SET_ADMIN_TEMPLATE_DIRTY';

/** 帳票テンプレート情報の削除 */
export const CLEAN_ADMIN_TEMPLATE = 'CLEAN_ADMIN_TEMPLATE';

//------------------------------------
// プロジェクト管理・ユーザ関連
//------------------------------------
/** ユーザ情報の取得開始 */
export const GETTING_ADMIN_USER = 'GETTING_ADMIN_USER';

/** ユーザ情報の取得受信 */
export const RECEIVE_GET_ADMIN_USER = 'RECEIVE_GET_ADMIN_USER';

/** プロジェクト権限一覧の取得開始 */
export const GETTING_PROJECT_ROLE_FOR_ADMIN_USER = 'GETTING_PROJECT_ROLE_FOR_ADMIN_USER';

/** プロジェクト権限一覧の取得受信 */
export const RECEIVE_GET_PROJECT_ROLE_FOR_ADMIN_USER = 'RECEIVE_GET_PROJECT_ROLE_FOR_ADMIN_USER';

/** 検査権限一覧の取得開始 */
export const GETTING_INSPECTION_ROLE_FOR_ADMIN_USER = 'GETTING_INSPECTION_ROLE_FOR_ADMIN_USER';

/** 検査権限一覧の取得受信 */
export const RECEIVE_GET_INSPECTION_ROLE_FOR_ADMIN_USER = 'RECEIVE_GET_INSPECTION_ROLE_FOR_ADMIN_USER';

/** ユーザの作成 */
export const CREATING_ADMIN_USER = 'CREATING_ADMIN_USER';

/** ユーザ情報の取得受信 */
export const RECEIVE_CREATE_ADMIN_USER = 'RECEIVE_CREATE_ADMIN_USER';

/** ユーザの更新 */
export const UPDATING_ADMIN_USER = 'UPDATING_ADMIN_USER';

/** ユーザ情報の取得受信 */
export const RECEIVE_UPDATE_ADMIN_USER = 'RECEIVE_UPDATE_ADMIN_USER';

/** ユーザの削除 */
export const DELETING_ADMIN_USER = 'DELETING_ADMIN_USER';

/** ユーザ情報の取得受信 */
export const RECEIVE_DELETE_ADMIN_USER = 'RECEIVE_DELETE_ADMIN_USER';

/** ユーザパスワードのリセット */
export const RESET_PASSWORD_ADMIN_USER = 'RESET_PASSWORD_ADMIN_USER';

/** ユーザパスワードのリセットの取得受信 */
export const RECEIVE_RESET_PASSWORD_ADMIN_USER = 'RECEIVE_RESET_PASSWORD_ADMIN_USER';

/** ユーザの選択 */
export const SET_SELECT_ADMIN_USER = 'SET_SELECT_ADMIN_USER';

/** ユーザの選択編集 */
export const SET_ADMIN_USER_DIRTY = 'SET_ADMIN_USER_DIRTY';

/** ユーザ情報の削除 */
export const CLEAN_ADMIN_USER = 'CLEAN_ADMIN_USER';

//------------------------------------
// プロジェクト管理・組織関連
//------------------------------------
/** 組織情報の取得開始 */
export const GETTING_ADMIN_ORGANIZATION = 'GETTING_ADMIN_ORGANIZATION';

/** 組織情報の取得受信 */
export const RECEIVE_GET_ADMIN_ORGANIZATION = 'RECEIVE_GET_ADMIN_ORGANIZATION';

/** 組織の作成 */
export const CREATING_ADMIN_ORGANIZATION = 'CREATING_ADMIN_ORGANIZATION';

/** 組織情報の取得受信 */
export const RECEIVE_CREATE_ADMIN_ORGANIZATION = 'RECEIVE_CREATE_ADMIN_ORGANIZATION';

/** 組織の更新 */
export const UPDATING_ADMIN_ORGANIZATION = 'UPDATING_ADMIN_ORGANIZATION';

/** 組織情報の取得受信 */
export const RECEIVE_UPDATE_ADMIN_ORGANIZATION = 'RECEIVE_UPDATE_ADMIN_ORGANIZATION';

/** 組織の一括更新 */
export const UPDATING_ADMIN_ORGANIZATIONS = 'UPDATING_ADMIN_ORGANIZATIONS';

/** 組織一括更新中の情報受信 */
export const RECEIVE_UPDATE_ADMIN_ORGANIZATIONS = 'RECEIVE_UPDATE_ADMIN_ORGANIZATIONS';

/** 組織の一括更新完了 */
export const COMPLETE_UPDATE_ADMIN_ORGANIZATIONS = 'COMPLETE_UPDATE_ADMIN_ORGANIZATIONS';

/** 組織の削除 */
export const DELETING_ADMIN_ORGANIZATION = 'DELETING_ADMIN_ORGANIZATION';

/** 組織情報の取得受信 */
export const RECEIVE_DELETE_ADMIN_ORGANIZATION = 'RECEIVE_DELETE_ADMIN_ORGANIZATION';

/** 組織の選択 */
export const SET_SELECT_ADMIN_ORGANIZATION = 'SET_SELECT_ADMIN_ORGANIZATION';

/** 組織の選択編集 */
export const SET_ADMIN_ORGANIZATION_DIRTY = 'SET_ADMIN_ORGANIZATION_DIRTY';

/** 組織情報の削除 */
export const CLEAN_ADMIN_ORGANIZATION = 'CLEAN_ADMIN_ORGANIZATION';

//------------------------------------
// プロジェクト管理・工種グループ関連
//------------------------------------
/** 工種グループ情報の取得開始 */
export const GETTING_ADMIN_WORKGROUP = 'GETTING_ADMIN_WORKGROUP';

/** 工種グループ情報の取得受信 */
export const RECEIVE_GET_ADMIN_WORKGROUP = 'RECEIVE_GET_ADMIN_WORKGROUP';

/** 工種グループの作成 */
export const CREATING_ADMIN_WORKGROUP = 'CREATING_ADMIN_WORKGROUP';

/** 工種グループ情報の取得受信 */
export const RECEIVE_CREATE_ADMIN_WORKGROUP = 'RECEIVE_CREATE_ADMIN_WORKGROUP';

/** 工種グループの更新 */
export const UPDATING_ADMIN_WORKGROUP = 'UPDATING_ADMIN_WORKGROUP';

/** 工種グループ情報の取得受信 */
export const RECEIVE_UPDATE_ADMIN_WORKGROUP = 'RECEIVE_UPDATE_ADMIN_WORKGROUP';

/** 工種グループの削除 */
export const DELETING_ADMIN_WORKGROUP = 'DELETING_ADMIN_WORKGROUP';

/** 工種グループ情報の取得受信 */
export const RECEIVE_DELETE_ADMIN_WORKGROUP = 'RECEIVE_DELETE_ADMIN_WORKGROUP';

/** 工種グループの選択 */
export const SET_SELECT_ADMIN_WORKGROUP = 'SET_SELECT_ADMIN_WORKGROUP';

/** 工種グループの選択編集 */
export const SET_ADMIN_WORKGROUP_DIRTY = 'SET_ADMIN_WORKGROUP_DIRTY';

/** 工種グループ情報の削除 */
export const CLEAN_ADMIN_WORKGROUP = 'CLEAN_ADMIN_WORKGROUP';

//------------------------------------
// プロジェクト管理・工種関連
//------------------------------------
/** 工種情報の取得開始 */
export const GETTING_ADMIN_WORK = 'GETTING_ADMIN_WORK';

/** 工種情報の取得受信 */
export const RECEIVE_GET_ADMIN_WORK = 'RECEIVE_GET_ADMIN_WORK';

/** 工種の作成 */
export const CREATING_ADMIN_WORK = 'CREATING_ADMIN_WORK';

/** 工種情報の取得受信 */
export const RECEIVE_CREATE_ADMIN_WORK = 'RECEIVE_CREATE_ADMIN_WORK';

/** 工種の更新 */
export const UPDATING_ADMIN_WORK = 'UPDATING_ADMIN_WORK';

/** 工種情報の取得受信 */
export const RECEIVE_UPDATE_ADMIN_WORK = 'RECEIVE_UPDATE_ADMIN_WORK';

/** 工種の一括更新 */
export const UPDATING_ADMIN_WORKS = 'UPDATING_ADMIN_WORKS';

/** 工種情報の取得受信 */
export const RECEIVE_UPDATE_ADMIN_WORKS = 'RECEIVE_UPDATE_ADMIN_WORKS';

/** 工種の削除 */
export const DELETING_ADMIN_WORK = 'DELETING_ADMIN_WORK';

/** 工種情報の取得受信 */
export const RECEIVE_DELETE_ADMIN_WORK = 'RECEIVE_DELETE_ADMIN_WORK';

/** 工種の選択 */
export const SET_SELECT_ADMIN_WORK = 'SET_SELECT_ADMIN_WORK';

/** 工種の選択編集 */
export const SET_ADMIN_WORK_DIRTY = 'SET_ADMIN_WORK_DIRTY';

/** 工種情報の削除 */
export const CLEAN_ADMIN_WORK = 'CLEAN_ADMIN_WORK';

//------------------------------------
// プロジェクト管理・ユーザ-所属関連
//------------------------------------
/** ユーザ-所属情報の取得開始 */
export const GETTING_ADMIN_USER_ORGANIZATION = 'GETTING_ADMIN_USER_ORGANIZATION';

/** ユーザ-所属情報の取得受信 */
export const RECEIVE_GET_ADMIN_USER_ORGANIZATION = 'RECEIVE_GET_ADMIN_USER_ORGANIZATION';

//------------------------------------
// プロジェクト管理・プロジェクト権限関連
//------------------------------------
/** プロジェクト権限一覧の取得開始 */
export const GETTING_ADMIN_PROJECT_ROLE = 'GETTING_ADMIN_PROJECT_ROLE';

/** プロジェクト権限一覧の取得受信 */
export const RECEIVE_GET_ADMIN_PROJECT_ROLE = 'RECEIVE_GET_ADMIN_PROJECT_ROLE';

/** プロジェクト権限の作成 */
export const CREATING_ADMIN_PROJECT_ROLE = 'CREATING_ADMIN_PROJECT_ROLE';

/** プロジェクト権限の取得受信 */
export const RECEIVE_CREATE_ADMIN_PROJECT_ROLE = 'RECEIVE_CREATE_ADMIN_PROJECT_ROLE';

/** プロジェクト権限の更新 */
export const UPDATING_ADMIN_PROJECT_ROLE = 'UPDATING_ADMIN_PROJECT_ROLE';

/** プロジェクト権限の取得受信 */
export const RECEIVE_UPDATE_ADMIN_PROJECT_ROLE = 'RECEIVE_UPDATE_ADMIN_PROJECT_ROLE';

/** プロジェクト権限の削除 */
export const DELETING_ADMIN_PROJECT_ROLE = 'DELETING_ADMIN_PROJECT_ROLE';

/** プロジェクト権限の取得受信 */
export const RECEIVE_DELETE_ADMIN_PROJECT_ROLE = 'RECEIVE_DELETE_ADMIN_PROJECT_ROLE';

/** プロジェクト権限の選択 */
export const SET_SELECT_ADMIN_PROJECT_ROLE = 'SET_SELECT_ADMIN_PROJECT_ROLE';

/** プロジェクト権限ルの選択編集 */
export const SET_ADMIN_PROJECT_ROLE_DIRTY = 'SET_ADMIN_PROJECT_ROLE_DIRTY';

/** プロジェクト権限の削除 */
export const CLEAN_ADMIN_PROJECT_ROLE = 'CLEAN_ADMIN_PROJECT_ROLE';

//------------------------------------
// プロジェクト管理・検査権限関連
//------------------------------------
/** 検査権限一覧の取得開始 */
export const GETTING_ADMIN_INSPECTION_ROLE = 'GETTING_ADMIN_INSPECTION_ROLE';

/** 検査権限一覧の取得受信 */
export const RECEIVE_GET_ADMIN_INSPECTION_ROLE = 'RECEIVE_GET_ADMIN_INSPECTION_ROLE';

/** 検査権限の作成 */
export const CREATING_ADMIN_INSPECTION_ROLE = 'CREATING_ADMIN_INSPECTION_ROLE';

/** 検査権限の取得受信 */
export const RECEIVE_CREATE_ADMIN_INSPECTION_ROLE = 'RECEIVE_CREATE_ADMIN_INSPECTION_ROLE';

/** 検査権限の更新 */
export const UPDATING_ADMIN_INSPECTION_ROLE = 'UPDATING_ADMIN_INSPECTION_ROLE';

/** 検査権限の取得受信 */
export const RECEIVE_UPDATE_ADMIN_INSPECTION_ROLE = 'RECEIVE_UPDATE_ADMIN_INSPECTION_ROLE';

/** 検査権限の削除 */
export const DELETING_ADMIN_INSPECTION_ROLE = 'DELETING_ADMIN_INSPECTION_ROLE';

/** 検査権限の取得受信 */
export const RECEIVE_DELETE_ADMIN_INSPECTION_ROLE = 'RECEIVE_DELETE_ADMIN_INSPECTION_ROLE';

/** 検査権限の選択 */
export const SET_SELECT_ADMIN_INSPECTION_ROLE = 'SET_SELECT_ADMIN_INSPECTION_ROLE';

/** 検査権限の選択編集 */
export const SET_ADMIN_INSPECTION_ROLE_DIRTY = 'SET_ADMIN_INSPECTION_ROLE_DIRTY';

/** 検査権限の削除 */
export const CLEAN_ADMIN_INSPECTION_ROLE = 'CLEAN_ADMIN_INSPECTION_ROLE';

//------------------------------------
// 工区関連
//------------------------------------
/** 工区管理での検査の選択 */
export const SET_SELECT_ADMIN_AREA_INSPECTION = 'SET_SELECT_ADMIN_AREA_INSPECTION';

/** 工区一覧の取得開始 */
export const GETTING_ADMIN_AREA = 'GETTING_ADMIN_AREA';

/** 工区一覧の取得受信 */
export const RECEIVE_GET_ADMIN_AREA = 'RECEIVE_GET_ADMIN_AREA';

/** 工区の作成 */
export const CREATING_ADMIN_AREA = 'CREATING_ADMIN_AREA';

/** 工区の取得受信 */
export const RECEIVE_CREATE_ADMIN_AREA = 'RECEIVE_CREATE_ADMIN_AREA';

/** 工区の更新 */
export const UPDATING_ADMIN_AREA = 'UPDATING_ADMIN_AREA';

/** 工区の取得受信 */
export const RECEIVE_UPDATE_ADMIN_AREA = 'RECEIVE_UPDATE_ADMIN_AREA';

/** 工区の取得完了 */
export const COMPLETE_UPDATE_ADMIN_AREA = 'COMPLETE_UPDATE_ADMIN_AREA';

/** 工区の削除 */
export const DELETING_ADMIN_AREA = 'DELETING_ADMIN_AREA';

/** 工区の取得受信 */
export const RECEIVE_DELETE_ADMIN_AREA = 'RECEIVE_DELETE_ADMIN_AREA';

/** 工区の選択 */
export const SET_SELECT_ADMIN_AREA = 'SET_SELECT_ADMIN_AREA';

/** 工区の選択編集 */
export const SET_ADMIN_AREA_DIRTY = 'SET_ADMIN_AREA_DIRTY';

/** 工区の削除 */
export const CLEAN_ADMIN_AREA = 'CLEAN_ADMIN_AREA';

//------------------------------------
// プロジェクト管理・デバイス関連
//------------------------------------
/** デバイス情報の取得開始 */
export const GETTING_ADMIN_DEVICE = 'GETTING_ADMIN_DEVICE';

/** デバイス情報の取得受信 */
export const RECEIVE_GET_ADMIN_DEVICE = 'RECEIVE_GET_ADMIN_DEVICE';

/** デバイスの削除 */
export const DELETING_ADMIN_DEVICE = 'DELETING_ADMIN_DEVICE';

/** デバイス情報の取得受信 */
export const RECEIVE_DELETE_ADMIN_DEVICE = 'RECEIVE_DELETE_ADMIN_DEVICE';

/** デバイスの選択 */
export const SET_SELECT_ADMIN_DEVICE = 'SET_SELECT_ADMIN_DEVICE';

/** デバイス情報の削除 */
export const CLEAN_ADMIN_DEVICE = 'CLEAN_ADMIN_DEVICE';

//------------------------------------
// CSV関連
//------------------------------------
/** エクスポート実行中 */
export const EXPORTING = 'EXPORTING';

/** エクスポート */
export const EXPORT = 'EXPORT';

/** インポート実行中 */
export const IMPORTING = 'IMPORTING';

/** インポート */
export const IMPORT = 'IMPORT';

//------------------------------------
// その他のAction
//------------------------------------
/** 接続確認 */
export const HELLO = 'HELLO';

/** サーバ時刻の取得 */
export const TIME = 'TIME';

//------------------------------------
// 目次関連Action
//------------------------------------
/** 目次取得開始 */
export const GETTING_DOCINDEX = 'GETTING_DOCINDEX';

/** 目次受信 */
export const RECEIVE_DOCINDEX = 'RECEIVE_DOCINDEX';

/** 目次一覧取得開始 */
export const GETTING_DOCINDICES = 'GETTING_DOCINDICES';

/** 目次一覧受信 */
export const RECEIVE_DOCINDICES = 'RECEIVE_DOCINDICES';

/** 目次選択 */
export const SET_TOC = 'SET_TOC';

//------------------------------------
// ドキュメント関連Action
//------------------------------------
/** ドキュメント取得開始 */
export const GETTING_DOCUMENT = 'GETTING_DOCUMENT';

/** ドキュメント受信 */
export const RECEIVE_DOCUMENT = 'RECEIVE_DOCUMENT';

//------------------------------------
// 承認関連Action
//------------------------------------
/** 承認開始 */
export const APPROVING_DOCUMENT = 'APPROVING_DOCUMENT';

/** 承認受信 */
export const RECEIVE_APPROVE_DOCUMENT = 'RECEIVE_APPROVE_DOCUMENT';

//------------------------------------
// ドキュメントSASトークン関連Action
//------------------------------------
/** SASトークンの取得開始 */
export const GETTING_DOC_SAS_TOKEN = 'GETTING_DOC_SAS_TOKEN';

/** SASトークンの取得受信 */
export const RECEIVE_GET_DOC_SAS_TOKEN = 'RECEIVE_GET_DOC_SAS_TOKEN';

/** Filter icons with parts */
export const FILTER_ICONS_WITH_PARTS = 'FILTER_ICONS_WITH_PARTS';

/** List upper and lower of cross section */
export const UPPER_LOWER_ICONS = 'UPPER_LOWER_ICONS'

/** Receive list upper and lower of cross section */
export const RECEIVE_UPPER_LOWER_ICONS = 'RECEIVE_UPPER_LOWER_ICONS'

/** Clear nearest cross section */
export const CLEAR_UPPER_LOWER_ICONS = 'CLEAR_UPPER_LOWER_ICONS'

/** Set upper cross section */
export const SET_UPPER_LOWER_ICONS = 'SET_UPPER_LOWER_ICONS'

/** reset histories undo/redo */
export const RESET_HISTORY = 'RESET_HISTORY'

/** Set histories undo/redo */
export const SET_HISTORIES = 'SET_HISTORIES'

/** reset icon undo/redo */
export const RESET_BACKUP_SELECT = 'RESET_BACKUP_SELECT'

/** set initial history to check backup data */
export const SET_INITIAL_HISTORY = 'SET_INITIAL_HISTORY'

export const SET_DEFAULT_CROSSECTION = 'SET_DEFAULT_CROSSECTION'
/** Set opacity label caption */
export const SET_OPACITY_CAPTION = 'SET_OPACITY_CAPTION'
// set overlay click map
export const SET_OVERLAY_APP = 'SET_OVERLAY_APP'

export const SET_CSV_POINT = 'SET_CSV_POINT'

export const SET_CSV_POINT_ERROR = 'SET_CSV_POINT_ERROR'

export const SET_POSITION_AREA = 'SET_POSITION_AREA'

export const SET_INSPECTION_MAP_TYPE = "SET_INSPECTION_MAP_TYPE"

export const SET_FINCHK_INSPECTION_FILTER = "SET_FINCHK_INSPECTION_FILTER"

export const SET_GS_INSPECTION_FILTER = "SET_GS_INSPECTION_FILTER"

export const RESET_ICONS_FILTER_TO_DEFAULT = "RESET_ICONS_FILTER_TO_DEFAULT"

export const SET_IS_READY = "SET_IS_READY"

export const SET_IS_RELOAD_PIN_AREA = "SET_IS_RELOAD_PIN_AREA"
