/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: systemProject.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  projects: null,
  selection: null,
  dirty: false,
  administrator: null
};

/**
 * user reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function systemProject(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_SYSTEM_PROJECT: {
      return Object.assign({}, state, {
        projects: null,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_GET_SYSTEM_PROJECT:
      return Object.assign({}, state, {
        projects: action.projects,
        selection: null,
        dirty: false
      });
    case types.RECEIVE_CREATE_SYSTEM_PROJECT: {
      let projects = Object.assign([], state.projects);
      projects.push(action.project);
      return Object.assign({}, state, {
        projects: projects,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_SYSTEM_PROJECT: {
      let projects = Object.assign([], state.projects);
      projects.splice(projects.map(function (p) { return p.id; }).indexOf(action.project.id), 1, action.project);
      return Object.assign({}, state, {
        projects: projects,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_DELETE_SYSTEM_PROJECT: {
      var projects = state.projects.filter(function (p) { return p.id !== action.projectId; });
      return Object.assign({}, state, {
        projects: projects,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_SYSTEM_PROJECT:
      return Object.assign({}, state, {
        selection: action.project,
        dirty: false
      });
    case types.SET_SYSTEM_PROJECT_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_SYSTEM_PROJECT:
      return initialState;
    case types.RECEIVE_GET_SYSTEM_USER_BY_EMAIL:
      return Object.assign({}, state, {
        administrator: action.administrator,
      });
    case types.RECEIVE_GET_SYSTEM_USER_BY_ID:
      return Object.assign({}, state, {
        administrator: action.administrator,
      });
    default:
      return state;
  }
}
