/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminWorkGroup.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  workGroups: null,
  works: null,
  selection: null,
  dirty: false
};

/**
 * work reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminWorkGroup(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_WORKGROUP: {
      return Object.assign({}, state, {
        workGroups: null,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_GET_ADMIN_WORK:
      return Object.assign({}, state, {
        works: action.works
      });
    case types.RECEIVE_GET_ADMIN_WORKGROUP:
      return Object.assign({}, state, {
        workGroups: action.workGroups,
      });
    case types.RECEIVE_CREATE_ADMIN_WORKGROUP: {
      let workGroups = Object.assign([], state.workGroups);
      workGroups.push(action.workGroup);
      return Object.assign({}, state, {
        workGroups: workGroups,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_WORKGROUP: {
      let workGroups = Object.assign([], state.workGroups);
      workGroups.splice(workGroups.map(function (w) { return w.id; }).indexOf(action.workGroup.id), 1, action.workGroup);
      return Object.assign({}, state, {
        workGroups: workGroups,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_DELETE_ADMIN_WORKGROUP: {
      var workGroups = state.workGroups.filter(function (w) { return w.id !== action.workGroupId; });
      return Object.assign({}, state, {
        workGroups: workGroups,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_ADMIN_WORKGROUP:
      return Object.assign({}, state, {
        selection: action.selection,
        dirty: false
      });
    case types.SET_ADMIN_WORKGROUP_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_ADMIN_WORKGROUP:
      return initialState;
    default:
      return state;
  }
}