/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: Home.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React, { Component } from 'react';

/**
 * Homeコンポーネント
 */
export default class Home extends Component {
  /**
   * Homeコンポーネントのレンダリング
   */
  render() {
    return (
      <div/>
    );
  }
}
