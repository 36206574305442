/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: Signup.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import ValidatorGroup from '../validation/ValidatorGroup';
import TextFieldValidator from '../validation/TextFieldValidator';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '../withTheme';
import { passwordIfNeeded } from '../actions/auth';

/**
 * スタイル情報 
 */
const styles = theme => ({
  base: {
    flex: "1 1 auto",
    display: "flex",
    position: "relative",
    overflow: "hidden",
  },
  frame: {
    minWidth: 480,
    maxWidth: 540,
    height: 'auto',
    position: 'absolute',
    top: '20%',
    left: 0,
    right: 0,
    margin: 'auto',
  },
  paper: {
    padding: 30,
    overflow: 'auto',
  },
  textField: {
    marginLeft: 0,
    width: "100%",
  },  
  password: {
    float: 'right',
    marginTop: 30,
    width: 200,
  },
  aux: {
    paddingTop: 50,
    textAlign: "center"
  },
  error: {
    width: "100%",
    textAlign: "center",
    margin: "15px 0px",
    padding: "5px 0",
    border: "solid 3px",
    borderColor: theme.palette.error.light,
    borderRadius: "3px"
  },
  errorText: {
    width: "100%",
    textAlign: "center",
    marginTop: 3
  },});

/**
 * Passwordコンポーネント
 */
class Password extends Component {
  /**
    * コンストラクタ
    */
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailEmpty: false,
    };
  }

  /**
   * メールアドレスが変更された時に呼び出されるハンドラ
   */
  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
      emailEmpty: false
    });
  }

  /**
   * パスワード再設定ボタンがクリックされた時に呼び出されるハンドラ
   */
  handlePasswordButtonClick = (event) => {
    if (this.isEmpty(this.state.email)) {
      this.setState({ emailEmpty: true });
    } else {
      this.props.passwordIfNeeded(this.state.email);
    }
  }

  /*
   * 空文字かどうかの判定
   */
  isEmpty(value) {
    if (value === undefined || value == null) {
      return true;
    }
    if (typeof value === 'string') {
      return value.trim() === '' || value.trim().length === 0;
    }
    return false;
  }

  /**
   * エラーメッセージを取得する。
   * @param {エラー}} error 
   */
  getErrorMessage(error) {
    if (error === "notfound") {
      return "メールアドレスは登録されていません。";
    } else {
      return "エラーが発生しました。再度ユーザ登録を行ってください。";
    }
  }

  /**
   * Signupコンポーネントのレンダリング
   */
  render() {
    return (
      <div className={this.props.classes.base}>
        <div className={this.props.classes.frame}>
          {(() => {
            return this.props.passwordComplete ?
              <Paper className={this.props.classes.paper}>
                <Typography variant="subtitle1">
                  入力したメールアドレスにパスワード再設定用のメールが届きますので、その内容にしたがって再設定手続きを行ってください。
                </Typography>
              </Paper>
              :
              <Paper className={this.props.classes.paper}>
                {(() => {
                  return this.props.passwordError != null ?
                    <div className={this.props.classes.error}>
                      <Typography variant="subtitle1" color="error" className={this.props.classes.errorText}>{this.getErrorMessage(this.props.passwordError)}</Typography>
                    </div>
                    : null
                })()}
                {(() => {
                  return this.state.emailEmpty ?
                    <div className={this.props.classes.error}>
                      <Typography variant="subtitle1" color="error" className={this.props.classes.errorText}>メールアドレスを入力してください</Typography>
                    </div>
                    : null
                })()}
                <Typography variant="subtitle1">
                  パスワードの再設定を行います。登録メールアドレスを入力して送信ボタンをクリックしてください。
                </Typography>
                <ValidatorGroup validatorGroupListener={this.handleGroupValidation}>
                  <TextFieldValidator
                    name="email"
                    label="メールアドレス"
                    value={this.state.email}
                    autoComplete="email"
                    onChange={this.handleEmailChange}
                    className={this.props.classes.textField}
                    fullWidth
                    margin="normal"
                    validators={['isEmail']}
                    errorMessages={['正しいメールアドレスを入力して下さい']}
                  />
                </ValidatorGroup>
                <Button
                  variant="contained"
                  color="primary"
                  className={this.props.classes.password}
                  onClick={this.handlePasswordButtonClick}
                >
                  送信
              </Button>
            </Paper>
          })()}
          <div className={this.props.classes.aux}>
            <Button
              component={Link} to="/"
              color="primary"
            >
              ログイン画面に戻る
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * StoreをPropsにマップする。
 * @param {*} state 
 */
function mapStateToProps(state) {
  return state.auth;
}

/**
 * Dispatcherをマップする。
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch) {
  return {
    passwordIfNeeded: (email) => {
      dispatch(passwordIfNeeded(email))
    }
  }
}

/**
 * reduxとの接続
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(Password)))