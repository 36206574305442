/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminWork.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  works: null,
  selection: null,
  dirty: false
};

/**
 * work reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminWork(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_WORK: {
      return Object.assign({}, state, {
        works: null,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_GET_ADMIN_WORK:
      return Object.assign({}, state, {
        works: action.works
      });
    case types.RECEIVE_CREATE_ADMIN_WORK: {
      let works = Object.assign([], state.works);
      works.push(action.work);
      return Object.assign({}, state, {
        works: works,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_WORK: {
      let works = Object.assign([], state.works);
      works.splice(works.map(function (w) { return w.id; }).indexOf(action.work.id), 1, action.work);
      return Object.assign({}, state, {
        works: works,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_WORKS: {
      let works = Object.assign([], state.works);
      action.works.forEach(work => {
        works.splice(works.map(w => w.id).indexOf(work.id), 1, work);
      });
      return Object.assign({}, state, {
        works: works,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_DELETE_ADMIN_WORK: {
      var works = state.works.filter(function (w) { return w.id !== action.workId; });
      return Object.assign({}, state, {
        works: works,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_ADMIN_WORK:
      return Object.assign({}, state, {
        selection: action.selection,
        dirty: false
      });
    case types.SET_ADMIN_WORK_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_ADMIN_WORK:
      return initialState;
    default:
      return state;
  }
}