/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminArea.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  inspection: null,
  areas: null,
  selection: null,
  dirty: false
};

/**
 * adminArea reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminArea(state = initialState, action) {
  switch (action.type) {
    case types.SET_SELECT_ADMIN_AREA_INSPECTION:
      return Object.assign({}, state, {
        inspection: action.inspection,
        selection: null,
        dirty: false
      });
    case types.GETTING_ADMIN_AREA:
      return Object.assign({}, state, {
        areas: null,
        selection: null,
        dirty: false
      });
    case types.RECEIVE_GET_ADMIN_AREA:
      return Object.assign({}, state, {
        areas: action.areas,
        selection: null,
        dirty: false
      });
    case types.RECEIVE_CREATE_ADMIN_AREA: {
      let areas = Object.assign([], state.areas);
      areas.push(action.area);
      return Object.assign({}, state, {
        areas: areas,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_AREA: {
      let areas = Object.assign([], state.areas);
      areas.splice(areas.map(function (r) { return r.id; }).indexOf(action.area.id), 1, action.area);
      return Object.assign({}, state, {
        areas: areas,
      });
    }
    case types.COMPLETE_UPDATE_ADMIN_AREA: {
      return Object.assign({}, state, {
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_DELETE_ADMIN_AREA: {
      var areas = state.areas.filter(function (a) { return a.id !== action.aid; });
      return Object.assign({}, state, {
        areas: areas,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_ADMIN_AREA:
      return Object.assign({}, state, {
        selection: action.area,
        dirty: false
      });
    case types.SET_ADMIN_AREA_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_ADMIN_AREA:
      return initialState;
    default:
      return state;
  }
}
