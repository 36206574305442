/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: Profile.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '../withTheme';
import PageHeader from '../common/PageHeader';

/**
 * スタイル情報 
 */
const styles = theme => ({
  base: {
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "auto"
  },
  content: {
    width: "100%",
    height: "100%"
  },
  div: {
    minWidth: 320,
    maxWidth: 400,
    height: 'auto',
    position: 'absolute',
    top: '20%',
    left: 0,
    right: 0,
    margin: 'auto',
  },
  paper: {
    padding: 20,
    overflow: 'auto',
  },
});

/**
 * Profileコンポーネント
 */
class Profile extends Component {
  /**
   * Profileコンポーネントのレンダリング
   */
  render() {
    return (
      <div className={this.props.classes.base}>
        <PageHeader title="個人情報設定" />
        <div className={this.props.classes.div}>
          <div className={this.props.classes.content}>
            <Paper className={this.props.classes.paper}>
              個人情報設定
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * Themeの適用
 */
export default withTheme(withStyles(styles)(Profile))