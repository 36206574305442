/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: ValidatorComponent.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import { Component } from 'react';
import PropTypes from 'prop-types';
import Rules from './ValidationRules';

/**
 * ValidatorComponentコンポーネント
 */
export default class ValidatorComponent extends Component {
  /**
   * コンストラクタ
   */
  constructor(props) {
    super(props);
    this.invalid = [];
    this.state = {
      isValid: true,
      errorMessages: this.props.errorMessages,
    };
    this.instantValidate = true;
    this.validate = this.validate.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.makeInvalid = this.makeInvalid.bind(this);
  }

  /**
   * Componentがマウントされる時に呼び出されるハンドラ
   */
  UNSAFE_componentWillMount() {
    this.context.group.attach(this);
    if (!this.props.name) {
      throw new Error('Validatorコンポーネントにはnameプロパティが必要です。');
    }
  }

  /**
   * Componentがマウントされる時に呼び出されるハンドラ
   */
  componentDidMount() {
    this.validate(this.props.value, this.props.validators);
  }

  /**
   * Componentがアンマウントされる時に呼び出されるハンドラ
   */
  componentWillUnmount() {
    this.context.group.detach(this);
  }

  /**
   * Propertyの更新
   * @param {*} nextProps 
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.validators && nextProps.errorMessages &&
      (this.props.validators !== nextProps.validators || this.props.errorMessages !== nextProps.errorMessages)) {
      this.setState({ validators: nextProps.validators, errorMessages: nextProps.errorMessages });
    }
    if ((this.instantValidate && nextProps.value !== this.props.value) ||
        (!this.props.validators.every((v, index, array) => { return v === nextProps.validators[index] }))) {
      this.validate(nextProps.value, nextProps.validators);
    }
  }

  /**
   * コンポーネント更新が必要かどうかを返す。
   * @param {object} nextProps 新しいプロパティ
   * @param {object} nextState 新しいステート
   */
  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState || this.props !== nextProps;
  }

  /**
   * validationが成功していない最初の項目に関するメッセージを取得する。
   */
  getErrorMessage() {
    const type = typeof this.state.errorMessages;
    if (type === 'string') {
      return this.state.errorMessages;
    } else if (type === 'object') {
      if (this.invalid.length > 0) {
        return this.state.errorMessages[this.invalid[0]];
      }
    }
    return true;
  }

  /**
   * Validationを実行する。
   * @param {object} value  値
   */
  validate(value, validator) {
    this.invalid = [];
    const result = [];
    let valid = true;
    validator.map((validator, i) => {
      const obj = {};
      obj[i] = this.getValidator(validator, value);
      return result.push(obj);
    });
    result.map(item =>
      Object.keys(item).map((key) => {
        if (!item[key]) {
          valid = false;
          this.invalid.push(key);
        }
        return key;
      }),
    );
    this.setState({ isValid: valid }, () => {
      if (this.props.validatorListener) {
        this.props.validatorListener(this.state.isValid);
      }
      this.context.group.notifyValidationResult(this, valid);
    });
  }

  /**
   * ValidatorによるValidationを実行する。
   * @param {object} validator Validator
   * @param {object} value 値
   */
  getValidator(validator, value) {
    let result = true;
    let name = validator;
    let extra;
    const splitIdx = validator.indexOf(':');
    if (splitIdx !== -1) {
      name = validator.substring(0, splitIdx);
      extra = validator.substring(splitIdx + 1);
    }
    result = Rules[name](value, extra);
    return result;
  }

  /**
   * Validation結果を取得する。
   */
  isValid() {
    return this.state.isValid;
  }

  /**
   * Validation結果を失敗にする。
   */
  makeInvalid() {
    this.setState({ isValid: false });
  }
}

/**
 * contextTypes
 */
ValidatorComponent.contextTypes = {
  group: PropTypes.object,
};

/**
 * プロパティ
 */
ValidatorComponent.propTypes = {
  errorMessages: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  validators: PropTypes.array,
  value: PropTypes.any,
  validatorListener: PropTypes.func,
};

/**
 * デフォルトプロパティ
 */
ValidatorComponent.defaultProps = {
  errorMessages: 'error',
  validators: [],
  validatorListener: () => { },
};
