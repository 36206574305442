/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: AppConstants.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

//------------------------------------
// ユーザ種別
//------------------------------------
/** Federationユーザ */
export const USER_KIND_FEDERATION = 1;

/** GSCユーザ */
export const USER_KIND_GSC = 2;

//------------------------------------
// コンテナ
//------------------------------------
/** contentsコンテナ */
export const CONTENTS_CONTAINER_ID = -1;

/** systemコンテナ */
export const SYSTEM_CONTAINER_ID = -2;

//------------------------------------
// 検査マスターデータ
//------------------------------------
/** 基本情報 */
export const INSPECTION_MASTER_BASE = "INSPECTION_MASTER_BASE";

//------------------------------------
// レイヤ
//------------------------------------
/** 通り芯レイヤ */
export const GUIDE_LAYER_ID = -1;

/** 水平通り芯レイヤ */
export const HGUIDE_LAYER_ID = -2;

/** 垂直通り芯レイヤ */
export const VGUIDE_LAYER_ID = -3;

/** 2点指定通り芯レイヤ */
export const DGUIDE_LAYER_ID = -4;

/** 工区レイヤ */
export const AREA_LAYER_ID = -10;

/** トリミングレイヤ */
export const TRIM_LAYER_ID = -20;

/** 制御点レイヤ */
export const CONTROL_POINT_LAYER_ID = -30;

/** ピン分散配置レイアウト枠レイヤ */
export const LAYOUT_LAYER_ID = -40;

/** トリミングレイヤ */
export const TRIMMING_LAYER_ID = -50;

export const FRAME_CROSS_SECTION_LAYER_ID = -50;

//------------------------------------
// 進捗表
//------------------------------------
/** 検査進捗 */
export const PROGRESS_INSPECTION_ID = 1;

/** 工事進捗 */
export const PROGRESS_CONSTRUCTION_ID = 2;

//------------------------------------
// 通り芯
//------------------------------------
/** 水平通り芯 */
export const GUIDE_TYPE_HORIZONTAL = "GUIDE_TYPE_HORIZONTAL";

/** 垂直通り芯 */
export const GUIDE_TYPE_VERTICAL = "GUIDE_TYPE_VERTICAL";

/** 2点指定通り芯 */
export const GUIDE_TYPE_DIAGONAL = "GUIDE_TYPE_DIAGONAL";

//------------------------------------
// 描画
//------------------------------------

/** 階 塗り色 */
export const FLOOR_FILL_COLOR = ["#FFCCBC", "#FFECB3", "#DCEDC8"];

/** 階 線色 */
export const FLOOR_STROKE_COLOR = ["#E64A19", "#FFA000", "#689F38"];

/** 階 文字色 */
export const FLOOR_LABEL_COLOR = "#00AA00";

//------------------------------------
// 描画
//------------------------------------
/** トリミング枠 */
export const TRIM_DI = { 
  strokeColor: "#2E7D32",
  strokeWidth: 0.003,
  strokeOpacity: 0.5,
  fillColor: "#2E7D32",
  fillOpacity: 0.2,
};

/** トリミングハンドル */
export const TRIM_HANDLE_DI = {
  fillColor: "#2E7D32",
  fillOpacity: 0.5,
  width: 0.03,
  height: 0.03
};

/** トリミング中心点ハンドル */
export const TRIM_CENTER_HANDLE_DI = {
  fillColor: "#2E7D32",
  fillOpacity: 0.5,
  radius: 0.015
};

/** 制御点ハンドル */
export const CP_HANDLE_DI = {
  fillColor: ["#D32F2F", "#1976D2"],
  fillOpacity: 0.1,
  radius: 0.015
};

/** 射影点ハンドル */
export const PP_HANDLE_DI = {
  fillColor: ["#D32F2F", "#1976D2"],
  fillOpacity: 0.75,
  radius: 0.025
};

/** 通り芯 */
export const GUIDE_DI = {
  horizontalStrokeColor: "#0000FF",
  verticalStrokeColor: "#FF0000",
  diagonalStrokeColor: "#FF00FF",
  mapStrokeWidth: 80,
  strokeWidth: 10,
  strokeOpacity: 0.5
};

/** ピン分散配置レイアウト枠 */
export const LAYOUT_DI = { 
  strokeColor: "#4b0082",
  strokeWidth: 0.003,
  strokeOpacity: 0.5,
  fillColor: "#4b0082",
  fillOpacity: 0,
};

/** ピン分散配置レイアウトハンドル */
export const LAYOUT_HANDLE_DI = {
  fillColor: "#4b0082",
  fillOpacity: 0.5,
  width: 0.03,
  height: 0.03
};

/** ピン分散配置レイアウト中心点ハンドル */
export const LAYOUT_CENTER_HANDLE_DI = {
  fillColor: "#4b0082",
  fillOpacity: 0.5,
  radius: 0.015
};

/** トリミング枠 */
export const TRIMMING_DI = { 
  fillColor: "#000000",
  fillOpacity: 0.3,
};

//------------------------------------
// ピン状態
//------------------------------------
export const PIN_STATE = {
  INUSE: 1,
  DELETE: 9
};

//------------------------------------
// ピン形状
//------------------------------------
export const PIN_SHAPE = {
  PIN: 0,
  RECT: 1,
  ELLIPSE: 2,
  POLYGON: 3,
  HIDDEN: 9
};

//------------------------------------
// アイコン引出線
//------------------------------------
export const LEADER_DI = {
  fillColor: "#ff0000",
  fillOpacity: 0.8,
  width: 0.0008
};

//------------------------------------
// ピン判定
//------------------------------------
export const PIN_CHECK = {
  BLANK: 0,
  INCOMPLETE: 1,
  NG: 2,
  FIXED: 3,
  OK: 4
};

export const PIN_CHECK_INFO = [
  { label: "未設定", color: "#888888" },
  { label: "未完了", color: "#ffd700" },
  { label: "是正前", color: "#dc143c" },
  { label: "是正後", color: "#006400" },
  { label: "採用", color: "#00008b" }
];

export const PIN_OPACITY = 0.65;
export const PIN_SHAPE_FILL_ACTIVE_COLOR = "#ff0000";
export const PIN_SHAPE_FILL_DEACTIVE_COLOR = "#aaaaaa";
export const PIN_SHAPE_FILL_ACTIVE_OPACITY = 0.35;
export const PIN_SHAPE_FILL_DEACTIVE_OPACITY = 0.25;
export const PIN_SHAPE_STROKE_ACTIVE_COLOR = "#ff0000";
export const PIN_SHAPE_STROKE_DEACTIVE_COLOR = "#888888";
export const PIN_SHAPE_STROKE_ACTIVE_OPACITY = 0.8;
export const PIN_SHAPE_STROKE_DEACTIVE_OPACITY = 0.8;
export const PIN_TEXT_COLOR = "#000000";
export const PIN_SUB_TEXT_COLOR = "#D0D0D0";
export const PIN_SUB_COLOR = "#D0D0D0";
export const SELECTION_ANCHOR_COLOR = "#0000FF";
export const SELECTION_ANCHOR_COLOR_AREA = "#0070C0";
export const SELECTION_ANCHOR_OPACITY = 0.75;
export const SELECTION_ANCHOR_OPACITY_AREA = 1;
export const SELECTION_ANCHOR_SIZE = 12;

//------------------------------------
// 写真有無
//------------------------------------
export const PIN_PHOTO = {
  NO_PHOTO: 0,
  HAS_PHOTO: 1
};

export const PIN_PHOTO_INFO = [
  { label: "無", color: "#888888" },
  { label: "有", color: "#00008b" }
];

//------------------------------------
// 写真判定
//------------------------------------
export const PHOTO_CHECK = {
  BLANK: 0,
  NG: 1,
  FIXED: 2,
  TBC: 3
};

export const PHOTO_CHECK_INFO = [
  { label: "未設定", color: "#FFFFFF" },
  { label: "是正前", color: "#dc143c" },
  { label: "是正後", color: "#00008b" },
  { label: "要検討", color: "#ffd700" }
];

//------------------------------------
// 写真採用
//------------------------------------
export const PHOTO_ADOPT = {
  NG: 0,
  OK: 1
};

export const PHOTO_ADOPT_INFO = [
  { label: "非採用", color: "#FFFFFF" },
  { label: "採用", color: "#006400" }
];


//------------------------------------
// 描画
//------------------------------------
export const FRAME_DI = { 
  strokeColor: "#2E7D32",
  strokeWidth: 10,
  strokeOpacity: 0.5,
  fillColor: "#2E7D32",
  fillOpacity: 0,
};

export const FRAME_HANDLE_DI = {
  fillColor: "#2E7D32",
  fillOpacity: 0.5,
  width: 0.03,
  height: 0.03
};


export const RESULT_CHK_ITEM_OUT_SCOPE = 0;
export const RESULT_CHK_ITEM_NOT_CONFIRMED = 1;
export const RESULT_CHK_ITEM_FAIL = 2;
export const RESULT_CHK_ITEM_NOT_APPLICABLE = 3;
export const RESULT_CHK_ITEM_PASS = 4;
export const RESULT_CHK_ITEM_CORRECTED = 5;

export const FILE_STATE = {
  NORMAL: 1,
  DELETE: 9
};

export const LIMIT_BACKUP_HISTORY = 50;