/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminProject.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  project: null,
  users: null,
  dirty: false
};

/**
 * adminProject reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminProject(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_PROJECT:
      return Object.assign({}, state, {
        project: null,
        users: null,
        dirty: false
      });
    case types.RECEIVE_GET_ADMIN_PROJECT:
      return Object.assign({}, state, {
        project: action.project,
        users: null,
        dirty: false
      });
    case types.RECEIVE_GET_ADMIN_PROJECT_USERS:
      return Object.assign({}, state, {
        users: action.users,
      });
    case types.RECEIVE_UPDATE_ADMIN_PROJECT: {
      return Object.assign({}, state, {
        project: action.project,
        dirty: false
      });
    }
    case types.SET_ADMIN_PROJECT_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.CLEAN_ADMIN_PROJECT:
      return initialState;
    default:
      return state;
  }
}
