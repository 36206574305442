/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: Notification.js
 * Author: Naoaki Suganuma
 * Version: 1.0.0
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { grey, red, green } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FinishIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ProcessingIcon from '@material-ui/icons/PlayArrow';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import RefreshIcon from '@material-ui/icons/Refresh';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '../withTheme';
import { 
  openOrRefreshNotification, 
  deleteProgressIfNeeded,
  cancelProgressIfNeeded,
  closeNotification 
} from '../actions/notifications';

/**
 * スタイル情報 
 */
const styles = theme => ({
  base: {
    width: 400,
    height: '100%',
    minHeight: 400,
    padding: "10px 10px 20px 10px",
    border: '1px solid #ccc',
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    backgroundColor: '#fff'
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 10,
    marginRight: 10
  },
  title: {
    fontSize: "1.2em",
    fontWeight: 900,
    color: "#000"
  },
  listItem: {
    width: "100%",
    margin: "0px 5px 0px 0px",
    display: "flex",
    flexDirection: "column",
  },
  listRow: {
    width: "100%",
    margin: "0px 0px 0px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  header: {
    flex: "0 0 30px",
    marginRight: 5,
  },
  name: {
    flex: "1 1 auto",
    fontSize: "0.85em",
    color: grey[800],
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  status: {
    flex: "1 0 auto",
    marginLeft: 10,
    fontSize: "0.8em",
    color: grey[800],
    textAlign: "right",
  },
  action: {
    flex: "0 0 25px",
    marginLeft: 6,
  },
  error: {
    color: red[300],
  },
  message: {
    width: "100%",
    marginLeft: 60,
    fontSize: "0.75em",
    color: grey[800]
  },
  progressName: {
    width: "100%",
    fontSize: "0.9em",
    color: grey[800]
  },
  progressbar: {
    width: "100%",
    margin: "8px 0px 5px 0px"
  },
  divider: {
    marginLeft: 5,
    marginRight: 5
  },
});

/**
 * Notificationコンポーネント
 */
class Notification extends Component {
  /**
   * コンストラクタ
   */
  constructor(props) {
    super(props);
    this.timer = null;
  }

  /**
   * Componentがマウントされた時に呼び出されるハンドラ
   */
  componentDidMount() {
    this.timer = setInterval(() => {
      if (this.props.open) {
        this.props.openOrRefreshNotification(this.props.project.projectId);
      }
    }, 3000);
  }

  /**
   * Componentがアンマウントされる時に呼び出されるハンドラ
   */
  componentWillUnmount() {
    clearInterval(this.timer);
  }

  /**
   * 通知一覧を作成する。
   */
  createList() {
    if (!this.props.notifications.progress) {
      return null;
    }
    let progress = this.props.notifications.progress.sort((a, b) => a.lastUpdate < b.lastUpdate);
    var result = [];
    for (var p of progress) {
      result.push(this.createListItem(p));
      result.push(
        <Divider key={"divider." + p.key} classes={{ root: this.props.classes.divider }} />
      )
    }
    return result;
  }

  /**
   * 通知を作成する
   */
  createListItem(progressItem) {
    if (progressItem.error) {
      return (
        <ListItem
          key={progressItem.key}
          className={this.props.classes.listItem}
        >
          <div className={this.props.classes.listRow} >
            <div className={this.props.classes.header}>
              <ErrorIcon style={{ color: red[400] }} />
            </div>
            <div className={this.props.classes.name}>{progressItem.title}</div>
            <div className={classNames(this.props.classes.status, {[this.props.classes.error]: true})}>エラー</div>
            <div className={this.props.classes.action}>
              <Tooltip title="削除">
                <IconButton aria-label="delete" size="small" onClick={() => {
                  this.props.deleteProgressIfNeeded(this.props.project.projectId, progressItem.key);
                }}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className={this.props.classes.message}>{progressItem.message}</div>
        </ListItem>
      );
    } else if (progressItem.complete || progressItem.progress >= 100) {
      return (
        <ListItem
          key={progressItem.key}
          className={this.props.classes.listItem}
        >
          <div className={this.props.classes.listRow} >
            <div className={this.props.classes.header}>
              {
                progressItem.cancelAccepted ? <CancelIcon style={{ color: grey[400] }} /> : <FinishIcon style={{ color: green[400] }} />
              }
            </div>
            <div className={this.props.classes.name}>
              {progressItem.title}
            </div>
            <div className={this.props.classes.status}>
              {progressItem.cancelAccepted ? "キャンセル済" : "完了"}
            </div>
            <div className={this.props.classes.action}>
              <Tooltip title="削除">
                <IconButton aria-label="delete" size="small" onClick={() => {
                  this.props.deleteProgressIfNeeded(this.props.project.projectId, progressItem.key);
                }}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </ListItem>
      );
    } else {
     return (
        <ListItem
          key={progressItem.key}
          className={this.props.classes.listItem}
        >
          <div className={this.props.classes.listRow}>
            <div className={this.props.classes.header}>
              {
                progressItem.cancel ? <CancelIcon style={{ color: grey[400] }} /> : <ProcessingIcon color="primary" />
              }
            </div>
            <div className={this.props.classes.name}>
              {progressItem.title}
            </div>
            <div className={this.props.classes.status}>
              {progressItem.cancelAccepted ? "キャンセル中" : (progressItem.cancel ? "キャンセル送信済" : "実行中")}
            </div>
            <div className={this.props.classes.action}>
              {
                progressItem.cancel || progressItem.cancelAccepted ?
                  null : 
                  <Tooltip title="キャンセル">
                    <IconButton aria-label="cancel" size="small" onClick={() => {
                      this.props.cancelProgressIfNeeded(this.props.project.projectId, progressItem.key);
                    }}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
              }
            </div>
          </div>
          <LinearProgress
            className={this.props.classes.progressbar}
            variant="determinate"
            value={progressItem.progress}
          />
        </ListItem>
      );
    }
  }

  /**
   * PhotoListのレンダリング
   */
  render() {
    return (
      this.props.open ? 
      <div className={this.props.classes.base}>
        <div className={this.props.classes.buttons}>
          <IconButton 
            aria-label="close" 
            size="small"
            onClick={() => {
              this.props.closeNotification();
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <div className={this.props.classes.title}>進行状況</div>
          <IconButton 
            aria-label="refresh" 
            size="small"
            onClick={() => {
              this.props.openOrRefreshNotification(this.props.project.projectId);
            }}
          >
            <RefreshIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />
        <List className={this.props.classes.list}>
          {this.createList()}
        </List>
      </div> : null
    )
  }
}

/**
 * StoreをPropsにマップする。
 * @param {*} state 
 */
function mapStateToProps(state) {
  return {
    project: state.project,
    notifications: state.notifications,
  }
}

/**
 * Dispatcherをマップする。
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch) {
  return {
    openOrRefreshNotification: (projectId) => {
      dispatch(openOrRefreshNotification(projectId))
    },
    deleteProgressIfNeeded: (projectId, progressKey) => {
      dispatch(deleteProgressIfNeeded(projectId, progressKey))
    },
    cancelProgressIfNeeded: (projectId, progressKey) => {
      dispatch(cancelProgressIfNeeded(projectId, progressKey))
    },
    closeNotification: () => {
      dispatch(closeNotification())
    }
  }
}

/**
 * reduxとの接続とThemeの適用
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(Notification)))
