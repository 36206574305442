/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: adminInspection.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

 import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  inspectionModels: null,
  masters: null,
  trees: null,
  inspections: null,
  selection: null,
  master: null,
  dirty: false,
  valid: [],
  isDefaultCrossection: false
};

/**
 * adminInspection reducer
 * @param {object} state 
 * @param {string} action 
 */
export default function adminInspection(state = initialState, action) {
  switch (action.type) {
    case types.GETTING_ADMIN_INSPECTION:
      return Object.assign({}, state, {
        inspections: null,
        selection: null,
        dirty: false
      });
    case types.RECEIVE_GET_ADMIN_INSPECTION:
      return Object.assign({}, state, {
        inspections: action.inspections,
        selection: null,
        dirty: false
      });
    case types.GETTING_ADMIN_INSPECTION_MODEL:
      return Object.assign({}, state, {
        inspectionModels: null
      });
    case types.RECEIVE_GET_ADMIN_INSPECTION_MODEL:
      return Object.assign({}, state, {
        inspectionModels: action.inspectionModels
      });
    case types.GETTING_ADMIN_INSPECTION_MASTER:
      return Object.assign({}, state, {
        masters: null
      });
    case types.RECEIVE_GET_ADMIN_INSPECTION_MASTER:
      return Object.assign({}, state, {
        masters: action.masters
      });
    case types.GETTING_ADMIN_TREE:
      return Object.assign({}, state, {
        trees: null
      });
    case types.RECEIVE_GET_ADMIN_TREE:
      return Object.assign({}, state, {
        trees: action.trees
      });
    case types.RECEIVE_CREATE_ADMIN_INSPECTION: {
      let inspections = Object.assign([], state.inspections);
      inspections.push(action.inspection);
      return Object.assign({}, state, {
        inspections: inspections,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_INSPECTION: {
      let inspections = Object.assign([], state.inspections);
      inspections.splice(inspections.map(function (inspection) { return inspection.id; }).indexOf(action.inspection.id), 1, action.inspection);
      return Object.assign({}, state, {
        inspections: inspections,
        master: JSON.parse(action.inspection.master),
        dirty: false
      });
    }
    case types.RECEIVE_UPDATE_ADMIN_INSPECTIONS: {
      let inspections = Object.assign([], state.inspections);
      action.inspections.forEach((ins) => {
        inspections.splice(inspections.map(i => i.id).indexOf(ins.id), 1, ins);
      });
      return Object.assign({}, state, {
        inspections: inspections,
        selection: null,
        dirty: false
      });
    }
    case types.RECEIVE_DELETE_ADMIN_INSPECTION: {
      var inspections = state.inspections.filter(function (inspection) { return inspection.id !== action.inspectionId; });
      return Object.assign({}, state, {
        inspections: inspections,
        selection: null,
        dirty: false
      });
    }
    case types.SET_SELECT_ADMIN_INSPECTION:
      let parsedJson = null;
      try {
        parsedJson = JSON.parse(action.inspection.master);
      } catch(e) {
      }

      return Object.assign({}, state, {
        selection: action.inspection,
        master: parsedJson,
        dirty: false
      });
    case types.SET_ADMIN_INSPECTION_PROPERTY:
      let inspection = state.selection;
      inspection[action.key] = action.value;
      return Object.assign({}, state, {
        selection: inspection
      });
    case types.SET_ADMIN_MASTER_PROPERTY:
      let master = state.master;
      master[action.key] = action.value;
      return Object.assign({}, state, {
        master: master
      });
    case types.SET_ADMIN_INSPECTION_DIRTY:
      return Object.assign({}, state, {
        dirty: true
      });
    case types.SET_ADMIN_INSPECTION_VALID:
      let valid = action.reset ? [] : Object.assign([], state.valid);
      action.valid.forEach(function (v) {
        let index = valid.findIndex(function (c) { return c.key === v.key; });
        if (index >= 0) {
          valid.splice(index, 1, v);
        } else {
          valid.push(v);
        }
      });
      return Object.assign({}, state, {
        valid: valid
      });
    case types.CLEAN_ADMIN_INSPECTION:
      return initialState;
    case types.SET_DEFAULT_CROSSECTION:
      return {
        ...state,
        isDefaultCrossection: action.status
      }
    default:
      return state;
  }
}
