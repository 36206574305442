/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: AsyncContainer.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React from 'react';

export default (loader, collection) => (
  /**
   * AsyncContainerコンポーネント
   */
  class AsyncContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = { Container: AsyncContainer.Container };
    }

    /**
     * コンポーネントがマウントされる時に呼び出されるハンドラ
     */
    componentDidMount() {
      if (!this.state.Container) {
        loader().then((Container) => {
          this.setState({ Container });
        });
      }
    }

    /**
     * レンダリング
     */
    render() {
      if (this.state.Container) {
        return (
          <this.state.Container { ...this.props } { ...collection } />
        )
      }
      return null;
    }
  }
);