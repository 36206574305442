/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/**
 * Copyright (C) 2017-2018 System Clinic Inc. All rights reserved.
 * This file is the property of System Clinic Inc.
 *
 * File: Header.js
 * Author: Naoaki Suganuma
 * Update: 2018/3/12
 * Version: 1.0.0
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DocIcon from '@material-ui/icons/Description';
import SystemIcon from '@material-ui/icons/Settings';
import ProjectListIcon from '@material-ui/icons/ViewList';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HelpIcon from '@material-ui/icons/Help';
import LanguageIcon from '@material-ui/icons/Language';
import AccountIcon from '@material-ui/icons/AccountCircle';
import ExitIcon from '@material-ui/icons/ExitToApp';
import blueGrey from '@material-ui/core/colors/blueGrey';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '../withTheme';
import Notification from './Notification';
import GSCIcon from '../assets/icon.svg';
import LogoIcon from '../assets/logo.svg';
import PowerIcon from '../assets/power.svg';
import LogoDocIcon from '../assets/logo_doc.png';
import { logoutIfNeeded } from '../actions/auth';
import { openOrRefreshNotification, closeNotification } from '../actions/notifications';
import { hasAnySystemAcl } from '../utils/acl';
import { getApiEndpoint } from '../constants/ApiEndpoint';

/**
 * スタイル情報 
 */
const styles = theme => ({
  bar: {
    backgroundColor: blueGrey[50]
  },
  title: {
    padding: "8px 10px 5px"
  },
  icon: {
    height: 34,
    paddingLeft: 10
  },
  logo: {
    height: 22,
    paddingLeft: 10,
    paddingBottom: 5
  },
  power: {
    height: 20,
    marginLeft: -46,
    marginBottom: 12
  },
  doc: {
    paddingLeft: 10,
    paddingBottom: 5
  },
  menu: {
    padding: "10px 5px 0px 0px",
    textAlign: "right"
  },
  spacer: {
    width: 60
  },
  button: {
    padding: 0,
    marginTop: 10,
    marginRight: 25,
    width: 25,
    height: 25
  },
  badge: {
    margin: `10 ${theme.spacing(2)}px`,
  },
  fixed: {
    position: "fixed",
    top: 0,
    right: 0,
    padding: "2px 6px 2px 6px",
    backgroundColor: "#dc143c",
    fontSize: "0.75em",
    color: "#ffffff"
  }
});

/**
 * Headerコンポーネント
 */
class Header extends Component {
  /**
   * コンストラクタ
   */
  constructor(props) {
    super(props);
    this.state = {
      notificationAnchor: null,
      helpAnchor: null
    };
    this.notificationButton = React.createRef();
  }

  /**
   * コンポーネントの状態が更新される時に呼び出される。
   * @param {*} nextProps 次の状態
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.notifications.open != this.props.notifications.open) {
      this.setState({
        notificationAnchor: nextProps.notifications.open ? this.notificationButton.current : null
      });
    }
  }

  /**
   * ログインユーザの権限に応じて適切なホームリンクを取得する。
   * @return {string} ホームリンク
   */
  getHomeLink() {
    if (this.props.auth.isLoggedIn) {
      if (hasAnySystemAcl(this.props.auth.systemAcl)) {
        return "/system";
      } else {
        return "/projects";
      }
    } else {
      return "/";
    }
  }

  /**
   * ヘルプメニューを作成する。
   */
  createHelp() {
    if (!this.props.auth.helps) {
      return null;
    }
    return this.props.auth.helps
      .sort((a, b) => {
        return a.displayOrder - b.displayOrder;
      }).map((help) => {
      return (
        <MenuItem key={"help." + help.id} onClick={this.handleHelpSelect(help)}>{help.name}</MenuItem>
      );
    });
  }

  /**
   * 通知ボタンがクリックされた時に呼び出されるハンドラ
   */
  handleClickNotificationButton = (event) => {
    if (this.props.notifications.open) {
      this.props.closeNotification();
    } else {
      this.props.openOrRefreshNotification(this.props.project.projectId);
    }
  }

  /**
   * ヘルプボタンがクリックされた時に呼び出されるハンドラ
   */
  handleClickHelpButton = (event) => {
    this.setState({ helpAnchor: event.currentTarget });
  }

  /**
   * ヘルプが選択された時に呼び出されるハンドラ
   */
  handleHelpSelect = (help) => (event) => {
    var win = window.open(help.url + this.props.auth.contentsSasToken, '_blank');
    win.focus();
    this.setState({ helpAnchor: null });
  }

  /**
   * ヘルプがクローズされた時に呼び出されるハンドラ
   */
  handleCloseHelp = () => {
    this.setState({ helpAnchor: null });
  }

  /**
   * ログアウトボタンがクリックされた時に呼び出されるハンドラ
   */
  handleClickLogoutButton = () => {
    this.props.logoutIfNeeded();
  }

  /**
   * データベースの状態を示す文字列を分解する
   * @param {*} dbState データベースの状態
   * @returns 動作状態、エディション、レベル
   */
  parseDBState = (dbState) => {
    var result = dbState.split(/:|\s/);
    return {
      state: result[0],
      edition: result[1],
      level: result[2]
    };
  }

  /**
   * データベースが最大状態で稼働しているかどうかを取得する
   * @returns データベースが最大状態の場合はtrue
   */
  isDBRunningMax = () => {
    try {
      if (this.props.app.state) {
        var result = this.parseDBState(this.props.app.state.dbState);
        return result.state === "RunningMax";
      }
    } catch(e) {
    }
    return false;
  }

  /**
   * データベースがスケール中かどうかを取得する
   * @returns データベースがスケール中の場合はtrue
   */
  isDBScaling = () => {
    try {
      if (this.props.app.state) {
        var result = this.parseDBState(this.props.app.state.dbState);
        return result.state === "Scaling";
      }
    } catch(e) {
    }
    return false;
  }

  /**
   * データベースがスケール準備中かどうかを取得する
   * @returns データベースがスケール準備中の場合はtrue
   */
  isDBPrepareing = () => {
    try {
      if (this.props.app.state) {
        var result = this.parseDBState(this.props.app.state.dbState);
        return result.state === "Preparing";
      }
    } catch(e) {
    }
    return false;
  }

  /**
   * ステージングサーバに接続中かどうかを取得する。
   * @returns ステージングサーバに接続中の場合はtrue
   */
  isConnectingStagingServer = () => {
    return getApiEndpoint() === "https://gsc-webapiapp-staging.azurewebsites.net/v1"
  }

  /**
   * Headerのレンダリング
   */
  render() {
    return (
      <div>
        <Grid container spacing={0} className={this.props.classes.bar}>
          <Grid item xs={6} className={this.props.classes.title}>
            <Tooltip title="ホーム">
              <Link to={this.getHomeLink()}>
                <img src={GSCIcon} className={this.props.classes.icon} alt="GLYPHSHOT" />
                <img src={LogoIcon} className={this.props.classes.logo} alt="GLYPHSHOT" />
                { 
                  this.isDBRunningMax() && <img src={PowerIcon} className={this.props.classes.power} alt="GLYPHSHOT" />
                }
              </Link>
            </Tooltip>
          </Grid>
          <Grid item container xs={6} justify="flex-end" className={this.props.classes.menu}>
            {(() => {
              return this.props.auth.isLoggedIn ? 
                <div>
                  <Tooltip title="通知">
                    <span>
                      <IconButton 
                        ref={this.notificationButton}
                        className={this.props.classes.button}
                        onClick={this.handleClickNotificationButton}
                        disabled={!this.props.notifications.enable}
                      >
                        <NotificationsIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Popper 
                    id="notification-popper"
                    open={Boolean(this.state.notificationAnchor)}
                    anchorEl={this.state.notificationAnchor} 
                    modifiers={{
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'scrollParent',
                      },
                      arrow: {
                        enabled: false,
                        element: this.state.notificationAnchor,
                      },
                    }}
                  >
                    <Notification open={Boolean(this.state.notificationAnchor)} />
                  </Popper>
                </div>
                 : null;
            })()}
            <Tooltip title="ヘルプ">
              <span>
                <IconButton
                  className={this.props.classes.button}
                  onClick={this.handleClickHelpButton}
                  disabled={!this.props.auth.helps}
                >
                  <HelpIcon />
                </IconButton>
              </span>
            </Tooltip>
            {
              this.props.auth.helps ? (
                <Menu
                  id="help-menu"
                  anchorEl={this.state.helpAnchor}
                  open={Boolean(this.state.helpAnchor)}
                  onClose={this.handleCloseHelp}
                >
                  {this.createHelp()}
                </Menu>              
              ) : (
                null
              )
            }
            {/*
            <Tooltip title="言語">
              <IconButton
                className={this.props.classes.button}
                component={Link} to="/language"
              >
                <LanguageIcon />
              </IconButton>
            </Tooltip>
            */}
            {(() => {
              return this.props.auth.isLoggedIn ?
                <Tooltip title={this.props.auth.username ? this.props.auth.username : ""}>
                  <IconButton
                    className={this.props.classes.button}
                    component={Link} to="/profile"
                  >
                    <AccountIcon />
                  </IconButton>
                </Tooltip> : null;
            })()}
            {(() => {
              return this.props.auth.isLoggedIn ?
                <Tooltip title="ログアウト" >
                  <IconButton
                    className={this.props.classes.button}
                    onClick={this.handleClickLogoutButton}
                  >
                    <ExitIcon />
                  </IconButton>
                </Tooltip> : null;
            })()}
          </Grid>
        </Grid>
        <Divider />
        { 
          (this.isConnectingStagingServer() || this.isDBScaling() || this.isDBPrepareing()) && 
          <div className={this.props.classes.fixed}>
            { this.isConnectingStagingServer() && <span>ステージングサーバ接続中...</span> }
            { this.isDBScaling() && <span>DBスケール中...</span> }
            { this.isDBPrepareing() && <span>DBスケール準備中...</span> }
          </div>
        }
      </div>
    );
  }
}

/**
 * StoreをPropsにマップする。
 * @param {*} state 
 */
function mapStateToProps(state) {
  return {
    app: state.app,
    auth: state.auth,
    project: state.project,
    notifications: state.notifications,
  };
}

/**
 * Dispatcherをマップする。
 * @param {*} dispatch 
 */
function mapDispatchToProps(dispatch) {
  return {
    logoutIfNeeded: () => {
      dispatch(logoutIfNeeded())
    },
    openOrRefreshNotification: (projectId) => {
      dispatch(openOrRefreshNotification(projectId))
    },
    closeNotification: () => {
      dispatch(closeNotification())
    }
	}
}

/**
 * reduxとの接続
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(Header)))
