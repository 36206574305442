/**
 * projects.js
 * Naoaki Suganuma
 * 2017/08/16
 * ver. 1.0.0
 */

import * as types from '../constants/ActionTypes';

/**
 * 初期状態
 */
const initialState = {
  projects: null,
  project: null
};

/**
 * projects reducer
 * @param {*} state 状態
 * @param {*} action アクション
 */
export default function projects(state = initialState, action) {
  switch (action.type) {
    case types.RECEIVE_GET_PROJECTS:
      return Object.assign({}, state, {
        projects: action.projects
      });
    case types.RECEIVE_GET_PROJECT_INFO:
      return Object.assign({}, state, {
        project: action.project
      });
    case types.RECEIVE_LOGOUT:
    case types.INIT_AUTH:
    case types.CLEAN_PROJECTS:
      return initialState;
    default:
      return state;
  }
}
